import { useState } from 'react';
import {
  Grid,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import { IModule } from './modulesAvailable';
import { useTranslation } from 'react-i18next';
import ShoppingCartCheckoutRoundedIcon from '@mui/icons-material/ShoppingCartCheckoutRounded';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import { useNavigate } from 'react-router-dom';

type ModuleListItemProps = {
  module: IModule;
  selectedModule: IModule | null;
  setSelectedModule: (module: IModule) => void;
};

export const ModuleListItem = ({
  module,
  selectedModule,
  setSelectedModule,
}: ModuleListItemProps) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { t } = useTranslation();
  let navigate = useNavigate();

  const toggleDescription = () => {
    setShowFullDescription((prev) => !prev);
  };

  const handleSelectedModule = (module: IModule) => {
    switch (module.id){
      case 1:
        navigate('/cloudFactory');
        break;
      case 2:
        navigate('/modules2');
        break;
      default:
        console.warn(`No route found for module ID ${module.id}`);
        break;
    }   
  };

  return (
    <Grid
      container
      sx={{
        m: 1,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <Grid item xs={8}>
        <ListItem
          button
          selected={selectedModule?.id === module.id}
          onClick={() => setSelectedModule(module)}
        >
          <ListItemText
            primary={module.name}
            secondary={
              <Box component='span' sx={{ color: 'white' }}>
                <Typography
                  variant='body2'
                  component='span' // Bruker span for å unngå nested <p>
                  sx={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: showFullDescription ? 'none' : 2,
                    whiteSpace: showFullDescription ? 'normal' : 'nowrap',
                  }}
                >
                  {module.description}
                </Typography>
                <Button
                  onClick={toggleDescription}
                  sx={{
                    color: 'lightblue',
                    textTransform: 'none',
                    display: 'inline',
                    padding: 0,
                    minWidth: 'auto',
                    ml: 1, // Margin left to add space between text and button
                  }}
                >
                  {showFullDescription ? t('showLess') : t('showMore')}
                </Button>
              </Box>
            }
          />
        </ListItem>
      </Grid>
      <Grid item xs={1}>
        <ListItem>
          <ListItemText
            primary={t('modules.expires')}
            secondary={module.expires}
            sx={{ color: 'white' }}
          />
        </ListItem>
      </Grid>
      <Grid item xs={2}>
        <ListItem>
          <ListItemText
            primary={t('modules.price')}
            secondary={module.price.toFixed(2) + ' NOK'}
            sx={{ color: 'white' }}
          />
        </ListItem>
      </Grid>
      <Grid item xs={1} sx={{ mt: 2 }}>
        {/* if we own thes show enter button, if not show buy button */}
        {module.isOwned ? (
          <IconButton
            color='success'
            onClick={() => {handleSelectedModule(module);
            }}
          >
            <LaunchRoundedIcon sx={{ fontSize: 64 }} />
          </IconButton>
        ) : (
          <IconButton
            color='secondary'
            onClick={() => setSelectedModule(module)}
          >
            <ShoppingCartCheckoutRoundedIcon sx={{ fontSize: 64 }} />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};
