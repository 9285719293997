import React, { useEffect, useReducer } from 'react';
import { usePost, useGet } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import useWindowsDimension from 'functions/useWindowsDimension';
import {
  Grid,
  Box,
  List,
  ListItem,
  Button,
  Typography,
  Checkbox,
  ListItemText,
  Select,
  MenuItem,
} from '@mui/material';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';
import { useTranslation } from 'react-i18next';
import { SearchFilter } from 'components/ui/SearchFilter';

import _ from 'lodash';

import {
  ICompanyViewModel,
  IDepartmentViewModel,
} from 'Interfaces/ICompaniesConfig';
import {
  IImportEmployeeAddViewModel,
  IImportResourceAddListViewModel,
  IImportResourceAddViewModel,
} from './Converts/ICloudFactoryInterfaces';
import { IResourcesViewModel } from 'Interfaces/IResourcesConfig';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';

export interface IImportResourcesInterface {
  employees: IImportEmployeeAddViewModel[];
  selectedResources: string[]; // Using email as identifier
  resourceTypes: IResourcesViewModel[];
  resourceTypeMapping: { [email: string]: string }; // Mapping of email to resourceTypeId
}

const initialState: IImportResourcesInterface = {
  employees: [],
  selectedResources: [],
  resourceTypes: [],
  resourceTypeMapping: {}, // Initialize as empty object
};

type Action =
  | { type: 'RESET_FORM' }
  | { type: 'SET_EMPLOYEES'; payload: IImportEmployeeAddViewModel[] }
  | { type: 'TOGGLE_EMPLOYEE_SELECTION'; payload: string }
  | { type: 'SET_RESOURCE_TYPE'; payload: IResourcesViewModel[] }
  | {
      type: 'UPDATE_RESOURCE_TYPE';
      payload: { email: string; resourceTypeId: string };
    };

const reducer = (
  state: IImportResourcesInterface,
  action: Action
): IImportResourcesInterface => {
  switch (action.type) {
    case 'RESET_FORM':
      return { ...initialState };
    case 'SET_EMPLOYEES':
      const defaultResourceTypeId = state.resourceTypes[3]?.id || ''; // Default to third resource type
      const updatedResourceTypeMapping = { ...state.resourceTypeMapping };
      action.payload.forEach((employee) => {
        if (!updatedResourceTypeMapping[employee.email]) {
          updatedResourceTypeMapping[employee.email] = defaultResourceTypeId;
        }
      });
      return {
        ...state,
        employees: action.payload,
        resourceTypeMapping: updatedResourceTypeMapping,
      };
    case 'TOGGLE_EMPLOYEE_SELECTION':
      return {
        ...state,
        selectedResources: state.selectedResources.includes(action.payload)
          ? state.selectedResources.filter((email) => email !== action.payload)
          : [...state.selectedResources, action.payload],
      };
    case 'SET_RESOURCE_TYPE':
      return { ...state, resourceTypes: action.payload };
    case 'UPDATE_RESOURCE_TYPE':
      return {
        ...state,
        resourceTypeMapping: {
          ...state.resourceTypeMapping,
          [action.payload.email]: action.payload.resourceTypeId,
        },
      };
    default:
      return state;
  }
};

type Props = {
  expanded: boolean;
  employees: IImportEmployeeAddViewModel[];
  company: ICompanyViewModel | null;
  departments: IDepartmentViewModel[];
  handleRemoveResources: (resource: IResourcesViewModel[]) => void;
};

export const ImportResourcesList = ({
  expanded,
  employees,
  departments,
  company,
  handleRemoveResources,
}: Props) => {
  const { t } = useTranslation();
  const { height } = useWindowsDimension();
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const [user] = useAtom(userAtom);
  const listHeight = Math.ceil(height - 272 - (expanded ? 386 : 0));

  // API CALLS
  const postResource = usePost(
    rqPost.importResources(company?.id || '', true),
    true
  );
  let isEnabled = company?.id ? true : false;
  const { data: resourceTypesData } = useGet(rq.resourceTypes(isEnabled), true);
  useEffect(() => {
    if (resourceTypesData) {
      let tmp = _.cloneDeep(resourceTypesData || []);
      console.log('x:: resourceTypesData', tmp);
      dispatch({ type: 'SET_RESOURCE_TYPE', payload: tmp.resourceTypes });
    }
  }, [resourceTypesData]);

  useEffect(() => {
    if (employees.length > 0) {
      let tmp = _.cloneDeep(employees || []);
      dispatch({ type: 'SET_EMPLOYEES', payload: tmp });
    }
  }, [employees]);

  // ON SUBMIT
  const onSubmit = (e: any) => {
    e.preventDefault();
    if (!company?.id) return;
    let resourceList: IImportResourceAddListViewModel = {
      resources: [] as IImportResourceAddViewModel[],
    };
    console.log('x:: user', user);
    console.log('x:: departments', departments);
    state.selectedResources.forEach((email) => {
      let resource = state.employees.find((emp) => emp.email === email);
      if (resource) {
        resourceList.resources.push({
          name: resource.email.split('@')[0],
          email: resource.email,
          resourceTypeId: state.resourceTypeMapping[email] || '',
          companyId: company.id,
          departmentId: departments[0].id,
          creatorId: user.id,
          contactId: null,
          legacyId: resource.legacyId,
          thirdPartyId: resource.thirdPartyId,
          thirdParty: resource.thirdParty,
        } as IImportResourceAddViewModel);
      }
    });

    postResource.mutate(resourceList, {
      onSuccess: (res: any) => {
        if (res) {
          console.log('x:: res', res);
          let tmp = _.cloneDeep(res.resources || []);
          handleRemoveResources(tmp);
        }
      },
      onError: (error: any) => {},
    });
  };

  //console.log('x:: state', state);

  return (
    <Grid
      container
      sx={{
        m: 0,
        p: 0,
      }}
    >
      {/*   HEADER */}
      <Grid item xs={12}>
        <SearchFilter
          Icon={FeedRoundedIcon}
          Header={t('company.Resources.detailsHeader')}
          filterValue={null}
          handleFilterChange={null}
          handleSearch={null}
          handleNew={null}
          selectList={null}
        />
      </Grid>

      <List
        sx={{
          width: '100%',
          height: listHeight,
          p: 1,
          borderRadius: 0,
          overflow: 'auto',
          backgroundColor: 'primary.main',
        }}
      >
        {state.employees?.length > 0 &&
          state.employees.map((employee) => (
            <ListItem
              key={employee.email}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box sx={{ flex: 1 }}>
                <ListItemText
                  primary={employee.name}
                  secondary={employee.email}
                  primaryTypographyProps={{ color: 'white' }}
                  secondaryTypographyProps={{ color: 'white' }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mx: 2,
                }}
              >
                <Typography
                  sx={{
                    m: 0,
                    p: 0,
                    pr: 2,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    fontSize: 14,
                    color: 'primary.text',
                  }}
                >
                  {t('resources.resourceType').toUpperCase()}
                </Typography>
                <Select
                  size='small'
                  variant='standard'
                  value={state.resourceTypeMapping[employee.email] || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_RESOURCE_TYPE',
                      payload: {
                        email: employee.email,
                        resourceTypeId: e.target.value as string,
                      },
                    })
                  }
                  displayEmpty
                  sx={{ minWidth: 170, color: 'white' }}
                >
                  {state.resourceTypes.map((resourceType) => (
                    <MenuItem key={resourceType.id} value={resourceType.id}>
                      {resourceType.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Checkbox
                checked={state.selectedResources.includes(employee.email)}
                color={
                  state.selectedResources.includes(employee.email)
                    ? 'secondary'
                    : 'primary'
                }
                onChange={() =>
                  dispatch({
                    type: 'TOGGLE_EMPLOYEE_SELECTION',
                    payload: employee.email,
                  })
                }
              />
            </ListItem>
          ))}
      </List>
      {/*   Submit Button */}
      <Grid
        item
        xs={12}
        sx={{
          m: 0,
          p: 1,
          height: 64,
          display: 'flex',
          flexDirection: 'row-reverse',
          backgroundColor: 'primary.main',
          borderRadius: 2,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Button
          onClick={(e) => {
            onSubmit(e);
          }}
          variant='contained'
        >
          {postResource.isLoading ? t('button.creating') : t('button.create')}
        </Button>
      </Grid>
    </Grid>
  );
};
