import { Link, Outlet } from 'react-router-dom';
import useWindowsDimension from 'functions/useWindowsDimension';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';

//import { useGet } from "http/useInnovit";
//import { rq } from "http/apiRoutes";

export const Setup = () => {
  const { t } = useTranslation();

  const { width } = useWindowsDimension();
  const drawerWidth = 250;
  let teller = -1;

  //let isEmployee = true;
  //const { data: company } = useGet(rq.appOwner(isEmployee), isEmployee);

  return (
    <Grid container display={'flex'} direction={'row'} spacing={0}>
      <Grid item xs={2} sx={{ width: '100%', pr: 0.5 }}>
        <Paper
          variant='elevation3'
          sx={{
            // flexGrow: 1,
            //width: '100%',
            /* height: "calc(100% - 30px)", */
            borderRadius: 3,
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
          }}
        >
          <List sx={{ color: 'primary.text' }}>
            <ListItem
              button
              key={teller++}
              component={Link}
              to={`/Setup/mainCompany`}
            >
              <ListItemText primary={t("Settings.SetupNav.CompanyDetails")} />
            </ListItem>
            <ListItem
              button
              key={teller++}
              component={Link}
              to={`/Setup/employees`}
            >
              <ListItemText primary={t("Settings.SetupNav.EmployeeRoles")} />
            </ListItem>
            <ListItem
              button
              key={teller++}
              component={Link}
              to={`/Setup/productData`}
            >
              <ListItemText primary={t("Settings.SetupNav.ProductDataSetup")} />
            </ListItem>
            <ListItem
              button
              key={teller++}
              component={Link}
              to={`/Setup/workRoles`}
            >
              <ListItemText primary={t("Settings.SetupNav.EconomySetup")} />
            </ListItem>
            <ListItem
              button
              key={teller++}
              component={Link}
              to={`/Setup/autoAnswers`}
            >
              <ListItemText primary={t("Settings.SetupNav.AutoAnswers")} />
            </ListItem>
            <ListItem
              button
              key={teller++}
              component={Link}
              to={`/Setup/brand`}
            >
              <ListItemText primary={t("Settings.SetupNav.Brand")} />
            </ListItem>
            <ListItem
              button
              key={teller++}
              component={Link}
              to={`/Setup/defaultSettings`}
            >
              <ListItemText primary={t("Settings.SetupNav.DefaultValuesSetup")} />
            </ListItem>
           
            <ListItem
              button
              key={teller++}
              component={Link}
              to={`/Setup/budget`}
            >
              <ListItemText primary={t("Settings.SetupNav.Budget")} />
            </ListItem>
          </List>
        </Paper>
      </Grid>

      <Grid item xs={10} sx={{ width: '100%', pl: 0.5 }}>
        <Grid sx={{}}>
          {/* {!!company && <CompanyHeader company={company} />} */}
        </Grid>
        <Grid sx={{}}>
          <Outlet />
        </Grid>
      </Grid>
    </Grid>
  );
};
