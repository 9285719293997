import * as React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import GetColorByName from 'functions/GetColorByName';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import { useTranslation } from 'react-i18next';

export const SingleProductView = (props: any) => {
  const { t } = useTranslation();

  const product = props.product;
//console.log('SingleProductView',product)
  return (
    <Grid
      container
      flexGrow={1}
      direction={'row'}
      sx={{
        mb: 1,
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        borderColor: GetColorByName(product?.productType?.name),
        backgroundColor: 'primary.main',
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Grid
        item
        sx={{
          p: 0.3,
          mt: 0,
          width: 88,
          height: 88,
          borderTopLeftRadius: '3px',
          borderBottomLeftRadius: '3px',
          backgroundColor: 'primary.light',
          display: 'flex',
        }}
      >
        <img
          src={product?.url ? product.url : MissingPhoto.PRODUCT_URL}
          style={{
            width: '100%',
            height: '100%',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            objectFit: 'scale-down',
          }}
          alt={'logo'}
        />
      </Grid>

      <Grid
        item
        xs={8}
        sx={{
          pl: 1,
          height: 88,
        }}
      >
        <Stack direction={'column'}>
          <Typography
            sx={{
              letterSpacing: 1,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 18,
            }}
          >
            {product?.name}
          </Typography>

          <Grid
            container
            direction={'row'}
            sx={{
              height: 56,
              display: 'flex',
              overflow: 'hidden',
            }}
          >
            {product?.features &&
              product?.features.map((_features: any, index: number) => {
                return (
                  <Grid item key={index} xs={6}>
                    <Grid container direction={'row'}>
                      <Grid item xs={6}>
                        <Typography
                          noWrap
                          sx={{
                            width: 110,
                            fontSize: 12,
                            letterSpacing: 1,
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: 'primary.text',
                          }}
                        >
                          {_features.featureName}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          noWrap
                          sx={{
                            fontSize: 12,
                            letterSpacing: 1,
                            fontWeight: 'normal',
                            textAlign: 'left',
                            color: 'primary.text',
                          }}
                        >
                          {_features.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={2} sx={{}}>
        <Stack direction={'column'}>
          <Typography
            noWrap
            sx={{
              pr: 1,
              letterSpacing: 0,
              textAlign: 'right',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'normal',
              textTransform: 'capitalize',
              fontSize: 14,
            }}
          >
            ( {product?.brand?.brandName ||`${t("Products.SingleProductView.brand")}`
} )
          </Typography>
          <Typography
            sx={{
              height: 64,
              pr: 1,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              alignContent: 'stretch',
              fontWeight: 'bold',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              textTransform: 'capitalize',
              color: 'secondary.text',
            }}
          >
            {(product?.retailPrice ?? 0).toFixed(2)}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
