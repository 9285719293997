import { Grid, Typography } from "@mui/material";
import { ITicketPostBillingViewModel } from "Interfaces/ITicketConfig";
import { TextStyle } from "data/FontStyle";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface EconomyViewProps {
  ticketPostBilling: ITicketPostBillingViewModel | null;
  }
  export const EconomyView: React.FC<EconomyViewProps> = ({ ticketPostBilling }) => {
    const { t } = useTranslation();
  
    if (ticketPostBilling == null) {
      return null;
    }
  
    // Calculate the total work value and hours
    const workHours = ticketPostBilling.workHours || 0;
    const workBillableValue = ticketPostBilling.workBillableValue || 0;
  
    // Calculate free work value and hours
    const freeWorkHours = ticketPostBilling.notBillableHours || 0;
    const freeWorkValue = ticketPostBilling.notBillableBillingValue || 0;
  
    const totalOvertimeHours = ticketPostBilling.nightHours + ticketPostBilling.midDayHours + ticketPostBilling.eveningHours;
    const totalOvertimeBillableValue = ticketPostBilling.nightBillableValue + ticketPostBilling.midDayBillableValue + ticketPostBilling.eveningBillableValue;

    console.log('ticketPostBilling.x',totalOvertimeHours);
  
  
    return (
      <Grid
        container
        spacing={2}
        sx={{
          width: '100%',
          flexDirection: 'column',
          backgroundColor: 'primary.back',
          borderRadius: 2,
          ml: 0,
          mt: 3,
          position: 'relative',
          zIndex: 1000,
        }}
      >
        {/* Arbeidstid og verdi */}
        <Grid item xs={12}>
          <Typography
            sx={[TextStyle.label, { width: '100%' }]}
          >
            {t('Ticket.TicketCommunications.normalWorkTime')}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
             sx={[TextStyle.label, { width: '100%' }]}
            >
              {t('Ticket.TicketCommunications.time')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={[TextStyle.label, { width: '100%' }]}
            >
              {t('Ticket.TicketCommunications.value')}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ mt: -2 }}>
            <Typography
             sx={[TextStyle.value, { width: '100%',textAlign: 'center',
             alignItems: 'center', }]}
            >
              {workHours.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ mt: -2 }}>
            <Typography
             sx={[TextStyle.value, { width: '100%',textAlign: 'center',
             alignItems: 'center', }]}
            >
              {workBillableValue.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
  
        {/* Gratis arbeidstid og verdi */}
        <Grid item xs={12}>
          <Typography
            sx={[TextStyle.label, { width: '100%' }]}
          >
            {t('Ticket.TicketCommunications.freeWorkTime')}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
             sx={[TextStyle.label, { width: '100%' }]}
            >
              {t('Ticket.TicketCommunications.time')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={[TextStyle.label, { width: '100%' }]}
            >
              {t('Ticket.TicketCommunications.value')}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ mt: -2 }}>
            <Typography
              sx={[TextStyle.value, { width: '100%',textAlign: 'center',
              alignItems: 'center', }]}
            >
              {freeWorkHours.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ mt: -2 }}>
            <Typography
              sx={[TextStyle.value, { width: '100%',textAlign: 'center',
              alignItems: 'center', }]}
            >
              {freeWorkValue.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
  
        {/* Overtid og verdi */}
        <Grid item xs={12} sx={{ mt: -1 }}>
          <Typography
           sx={[TextStyle.label, { width: '100%' }]}
          >
            {t('Ticket.TicketCommunications.overTime')}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              sx={[TextStyle.label, { width: '100%' }]}
            >
              {t('Ticket.TicketCommunications.time')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
             sx={[TextStyle.label, { width: '100%' }]}
            >
              {t('Ticket.TicketCommunications.value')}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ mt: -2 }}>
            <Typography
              sx={[TextStyle.value, { width: '100%',textAlign: 'center',
              alignItems: 'center', }]}
            >
              {totalOvertimeHours.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ mt: -2 }}>
            <Typography
              sx={[TextStyle.value, { width: '100%',textAlign: 'center', }]}
            >
              {totalOvertimeBillableValue.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  