import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  usePostProductCategory,
  useProductCategory,
  usePostProductType,
  usePostProductTypeFeature,
  usePostProductFeatureData,
  useGet,
} from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { FormText } from 'components/form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { SubHeading } from 'components/ui';
import { ModalProductData } from 'components/ui/ModalProductData';
import CategoryIcon from '@mui/icons-material/Category';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import DnsIcon from '@mui/icons-material/Dns';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import useWindowsDimension from 'functions/useWindowsDimension';
import {
  Divider,
  Stack,
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const schemaCategory = yup
  .object()
  .shape({
    name: yup.string().min(1).max(50).required(),
  })
  .required();

const schemaType = yup
  .object()
  .shape({
    addType: yup.string().min(1).max(50).required(),
  })
  .required();

const schemaFeature = yup
  .object()
  .shape({
    name: yup.string().min(1).max(50).required(),
  })
  .required();

const schemaFeatureData = yup
  .object()
  .shape({
    description: yup.string().min(1).max(50).required(),
  })
  .required();

export const ProductData = () => {
  const { t } = useTranslation();

  /* INITIALIZATIONS */
  const { height, width } = useWindowsDimension();

  const {
    handleSubmit: handleSubmitCategory,
    control: controlCategory,
    reset: resetCategory,
    formState: { isDirty: isDirtyCategory },
  } = useForm({
    mode: 'onChange',
    //defaultValues: company,
    resolver: yupResolver(schemaCategory),
  });
  const {
    handleSubmit: handleSubmitType,
    control: controlType,
    reset: resetType,
    formState: { isDirty: isDirtyType, errors: errorsType },
  } = useForm({
    mode: 'onChange',
    //defaultValues: company,
    resolver: yupResolver(schemaType),
  });
  const {
    handleSubmit: handleSubmitFeature,
    control: controlFeature,
    reset: resetFeature,
    formState: { isDirty: isDirtyFeature, errors: errorsFeature },
  } = useForm({
    mode: 'onChange',
    //defaultValues: company,
    resolver: yupResolver(schemaFeature),
  });
  const {
    handleSubmit: handleSubmitFeatureData,
    control: controlFeatureData,
    reset: resetFeatureData,
    formState: { isDirty: isDirtyFeatureData, errors: errorsFeatureData },
  } = useForm({
    mode: 'onChange',
    //defaultValues: company,
    resolver: yupResolver(schemaFeatureData),
  });

  // states
  const [category, setCategory] = useState(null);
  const [type, setType] = useState(null);
  const [feature, setFeature] = useState(null);
  const [open, setOpen] = useState(false);
  const [modalData, setApiLocation] = useState(null);

  // api
  const { data } = useProductCategory(); // xxx replace this with rq
  const postCategory = usePostProductCategory(); // xxx replace this with rq
  const postType = usePostProductType(); // xxx replace this with rq
  // const { data: dProductType } = useProductTypeFeatures(type?.id);
  const { data: dProductType } = useGet(rq.productTypeFeatures(type?.id));
  const postFeature = usePostProductTypeFeature(type?.id); // xxx replace this with rq
  const postFeatureData = usePostProductFeatureData(feature?.id, type?.id); // xxx replace this with rq

  /* FUNCTIONS */

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (isDirtyCategory) {
      postCategory.mutate(data, {
        onSuccess: (res) => {
          resetCategory();
          setCategory(res);
          setType(null);
          setFeature(null);
        },
      });
    }
  };

  const onSubmitType = (data, e) => {
    e.preventDefault();
    const typeObj = {
      name: data.addType,
      productCategoryId: category.id,
      description: '', //not necessary, ask innovit to delete this field
    };
    let x;
    if (isDirtyType) {
      postType.mutate(typeObj, {
        onSuccess: (res) => {
          // res contains name and id of the type
          resetType();
          setType(res);
          setFeature(null);
        },
      });
    }
  };

  const onSubmitFeature = (data, e) => {
    e.preventDefault();
    let x;

    if (isDirtyFeature && !postFeature.isLoading) {
      postFeature.mutate(data, {
        onSuccess: (res) => {
          resetFeature();
          setFeature(res);
        },
      });
    }
  };

  const onSubmitFeatureData = (data, e) => {
    e.preventDefault();

    const fDataObj = {
      productFeatureId: feature.id,
      name: data.description,
    };
    if (isDirtyFeatureData && !postFeatureData.isLoading) {
      postFeatureData.mutate(fDataObj, {
        onSuccess: (res) => {
          resetFeatureData();
        },
      });
    }
  };

  // handles
  const handleListCategory = (e, cat) => {
    e.preventDefault();
    setType(null);
    setFeature(null);
    errorsType.addType = '';
    errorsFeature.name = '';
    errorsFeatureData.description = '';
    setCategory(cat);
  };

  const handleListType = (e, type) => {
    e.preventDefault();
    setFeature(null);
    errorsFeature.name = '';
    errorsFeatureData.description = '';
    setType(type);
  };

  const handleListFeature = (e, feature) => {
    e.preventDefault();

    errorsFeatureData.description = '';

    for (let i = 0; i < dProductType.productFeatures.length; i++) {
      if (dProductType.productFeatures[i].id === feature.id) {
        setFeature(feature);
      }
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditCategory = (e, cat) => {
    e.preventDefault();

    setApiLocation({
      title: 'Category',
      url: '/ProductCategory',
      rqCache: 'productCategories',
      delUrl: `/ProductCategory/${cat.id}`,
      data: cat,
      value: cat.category,
    });
    setCategory(cat);
    setType(null);
    setFeature(null);
    handleOpen();
  };

  const handleEditType = (e, type) => {
    e.preventDefault();

    setApiLocation({
      title: 'Type',
      url: '/ProductType',
      rqCache: 'productCategories',
      delUrl: `/ProductType/${type.id}`,
      data: type,
      value: type.name,
    });
    setType(type);
    setFeature(null);
    handleOpen();
  };

  const handleEditFeature = (e, feature) => {
    e.preventDefault();

    setApiLocation({
      title: 'Feature',
      url: `/ProductType/${type.id}/ProductFeature`,
      rqCache: 'productType',
      delUrl: `/ProductType/${type.id}/ProductFeature/${feature.id}`,
      data: feature,
      value: feature.name,
    });
    handleOpen();
  };

  const handleEditFeatureData = (e, featureData) => {
    e.preventDefault();

    setApiLocation({
      title: 'Feature Data',
      url: '/ProductFeatureData',
      rqCache: 'productType',
      delUrl: `/ProductFeatureData/${featureData.id}`,
      data: featureData,
      value: featureData.description,
    });
    handleOpen();
  };

  useEffect(() => {
    // update the type list when type is added
    if (category) {
      for (let i = 0; i < data.productCategories.length; i++) {
        if (data.productCategories[i].id === category.id) {
          setCategory(data.productCategories[i]);
        }
      }
    }   
  }, [category?.id, type?.id, feature?.id, data, dProductType]);

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={3}>
          <SubHeading icon={CategoryIcon} title={t("Settings.ProductData.category")} />
          <form onSubmit={handleSubmitCategory(onSubmit)} name='addCategory'>
            <Paper
              variant='elevation3'
              sx={{
                p: 2,
                borderRadius: 3,
                height: Math.ceil(height - 220),
                backgroundColor: 'primary.main',
                color: 'primary.text',
              }}
            >
              <Divider sx={{ backgroundColor: 'primary.lighter' }} />
              <Typography
                sx={{
                  width: '100%',
                  Height: 24,
                  fontSize: 16,
                  letterSpacing: 3,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: 'transparent',
                  textTransform: 'uppercase',
                }}
              >
                {t("Settings.ProductData.select")}
              </Typography>
              <Divider sx={{ backgroundColor: 'primary.lighter' }} />
              <Stack direction='row' spacing={2} sx={{ p: 2 }}>
                <FormText
                  name='name'
                  control={controlCategory}
                  label={t("Settings.ProductData.AddCategory")}
                />
                <Button
                  type='submit'
                  variant='contained'
                  disabled={postCategory.isLoading}
                >
                  {t("Settings.ProductData.add")}
                </Button>
              </Stack>
              <Divider sx={{ backgroundColor: 'primary.lighter' }} />
              <List
                sx={{
                  height: Math.ceil(height - 350),
                  overflow: 'auto',
                  textTransform: 'capitalize',
                }}
              >
                {data &&
                  data.productCategories.map((cat) => (
                    <ListItem
                      disablePadding
                      key={cat.id}
                      secondaryAction={
                        <IconButton
                          edge='end'
                          aria-label='delete'
                          onClick={(e) => handleEditCategory(e, cat)}
                        >
                          <EditRoundedIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                      }
                    >
                      <ListItemButton
                        onClick={(e) => handleListCategory(e, cat)}
                      >
                        <ListItemText primary={cat.category} />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </Paper>
          </form>
        </Grid>
        <Grid item xs={3}>
          <>
            <SubHeading icon={DevicesOtherIcon} title={t("Settings.ProductData.type")} />
            <form onSubmit={handleSubmitType(onSubmitType)} name='addType'>
              <Paper
                variant='elevation3'
                sx={{
                  p: 2,
                  borderRadius: 3,
                  height: Math.ceil(height - 220),
                  backgroundColor: 'primary.main',
                  color: 'primary.text',
                }}
              >
                <Divider sx={{ backgroundColor: 'primary.lighter' }} />
                <Typography
                  sx={{
                    width: '100%',
                    fontSize: 16,
                    minHeight: 24,
                    letterSpacing: 3,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: 'secondary.text',
                    textTransform: 'uppercase',
                  }}
                >
                  {category?.category}
                </Typography>
                {/*  {category.category} */}
                <Divider sx={{ backgroundColor: 'primary.lighter' }} />
                <Stack direction='row' spacing={2} sx={{ p: 2 }}>
                  <FormText
                    name='addType'
                    control={controlType}
                    label={t("Settings.ProductData.AddType")}
                  />
                  <Button
                    type='submit'
                    variant='contained'
                    disabled={postType.isLoading || !category}
                  >
                   {t("Settings.ProductData.add")}
                  </Button>
                </Stack>
                <Divider sx={{ backgroundColor: 'primary.lighter' }} />
                <List
                  sx={{
                    height: Math.ceil(height - 650),
                    overflow: 'auto',
                    textTransform: 'capitalize',
                    backgroundColor: 'primary.back',
                    borderRadius: 3,
                  }}
                >
                  {category &&
                    category.productTypes?.map((type) => (
                      <ListItem
                        disablePadding
                        key={type.id}
                        sx={{}}
                        secondaryAction={
                          <IconButton
                            edge='end'
                            aria-label='delete'
                            onClick={(e) => handleEditType(e, type)}
                          >
                            <EditRoundedIcon sx={{ fontSize: 16 }} />
                          </IconButton>
                        }
                      >
                        <ListItemButton
                          onClick={(e) => handleListType(e, type)}
                        >
                          <ListItemText primary={type.name} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              </Paper>
            </form>
          </>
        </Grid>
        <Grid item xs={3}>
          <>
            <SubHeading icon={DnsIcon} title={t("Settings.ProductData.feature")} />
            <form
              onSubmit={handleSubmitFeature(onSubmitFeature)}
              name='addFeature'
            >
              <Paper
                variant='elevation3'
                sx={{
                  p: 2,
                  borderRadius: 3,
                  height: Math.ceil(height - 220),
                  backgroundColor: 'primary.main',
                  color: 'primary.text',
                }}
              >
                <Divider sx={{ backgroundColor: 'primary.lighter' }} />
                <Typography
                  sx={{
                    width: '100%',
                    fontSize: 16,
                    minHeight: 24,
                    letterSpacing: 3,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: 'secondary.text',
                    textTransform: 'uppercase',
                  }}
                >
                  {type?.name}
                </Typography>
                {/*  {category.category} */}
                <Divider sx={{ backgroundColor: 'primary.lighter' }} />
                <Stack direction='row' spacing={2} sx={{ p: 2 }}>
                  <FormText
                    name='name'
                    control={controlFeature}
                    label={t("Settings.ProductData.AddFeature")}
                  />
                  <Button
                    type='submit'
                    variant='contained'
                    disabled={postFeature.isLoading || !category || !type}
                  >
                    {t("Settings.ProductData.add")}
                  </Button>
                </Stack>
                <Divider sx={{ backgroundColor: 'primary.lighter' }} />
                <List
                  sx={{
                    height: Math.ceil(height - 350),
                    overflow: 'auto',
                  }}
                >
                  {dProductType &&
                    dProductType.productFeatures
                      ?.sort((a, b) =>
                        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                      )
                      .map((feature) => (
                        <ListItem
                          disablePadding
                          key={feature.id}
                          secondaryAction={
                            <IconButton
                              edge='end'
                              aria-label='delete'
                              onClick={(e) => handleEditFeature(e, feature)}
                            >
                              <EditRoundedIcon sx={{ fontSize: 16 }} />
                            </IconButton>
                          }
                        >
                          <ListItemButton
                            onClick={(e) => handleListFeature(e, feature)}
                          >
                            <ListItemText primary={feature.name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                </List>
              </Paper>
            </form>
          </>
        </Grid>
        <Grid item xs={3}>
          <>
            <SubHeading icon={DynamicFeedIcon} title={t("Settings.ProductData.featureData")} />
            <form
              onSubmit={handleSubmitFeatureData(onSubmitFeatureData)}
              name='addFeature'
            >
              <Paper
                variant='elevation3'
                sx={{
                  p: 2,
                  borderRadius: 3,
                  height: Math.ceil(height - 220),
                  backgroundColor: 'primary.main',
                  color: 'primary.text',
                }}
              >
                <Divider sx={{ backgroundColor: 'primary.lighter' }} />
                <Typography
                  sx={{
                    width: '100%',
                    height: 24,
                    fontSize: 16,
                    letterSpacing: 3,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: 'secondary.text',
                    textTransform: 'uppercase',
                  }}
                >
                  {feature?.name}
                </Typography>
                {/*  {category.category} */}
                <Divider sx={{ backgroundColor: 'primary.lighter' }} />
                <Stack direction='row' spacing={2} sx={{ p: 2 }}>
                  <FormText
                    name='description'
                    control={controlFeatureData}
                    label={t("Settings.ProductData.AddFeatureData")}
                  />
                  <Button
                    type='submit'
                    variant='contained'
                    disabled={
                      postFeatureData.isLoading ||
                      !category ||
                      !type ||
                      !feature
                    }
                  >
                    {t("Settings.ProductData.add")}
                  </Button>
                </Stack>
                <Divider sx={{ backgroundColor: 'primary.lighter' }} />

                <List
                  sx={{
                    height: Math.ceil(height - 350),
                    overflow: 'auto',
                  }}
                >
                  {!!feature &&
                    feature.productFeatureData?.map((fData) => (
                      <ListItem
                        disablePadding
                        key={fData.id}
                        secondaryAction={
                          <IconButton
                            edge='end'
                            aria-label='delete'
                            onClick={(e) => handleEditFeatureData(e, fData)}
                          >
                            <EditRoundedIcon sx={{ fontSize: 16 }} />
                          </IconButton>
                        }
                      >
                        <ListItemButton
                          onClick={(e) => handleListFeature(e, fData)}
                        >
                          <ListItemText primary={fData.description} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              </Paper>
            </form>
          </>
        </Grid>
      </Grid>

      <ModalProductData
        open={open}
        handleClose={handleClose}
        category={category}
        setCategory={setCategory}
        type={type}
        setType={setType}
        setFeature={setFeature}
        modalData={modalData}
      />
    </>
  );
};
