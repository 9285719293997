export const ConvertToAttachment = (selectedDocuments:any) => {
  // console.log("base64String", base64String);
  let attachments = [] as any;

  selectedDocuments.forEach((document:any) => {
    const encodedBase64String = document.base64.split(',')[1];
    attachments.push({
      fileName: document.file.name,
      content: encodedBase64String,
    });
  });

  return attachments;
};

