export function formatNumber(num: number,decimals:number): string {
    if (num < 10000) {
        return new Intl.NumberFormat('no-NB').format(num);
    } else if (num < 1000000) {
        // Konverter tilbake til tall etter å ha brukt toFixed for å unngå feil
        const formattedNumber = parseFloat((num / 1000).toFixed(decimals));
        return new Intl.NumberFormat('no-NB').format(formattedNumber) + 'k';
    } else {
        // Samme her for millioner
        const formattedNumber = parseFloat((num / 1000000).toFixed(decimals));
        return new Intl.NumberFormat('no-NB').format(formattedNumber) + 'm';
    }
}

