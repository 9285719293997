import {
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';
import { EnumOrderStatus, MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import { IOrderViewModel } from 'Interfaces/IOrderConfig';
import dayjs from 'dayjs';
import GetChartColor from 'functions/GetChartColor';
import { useTranslation } from 'react-i18next';

type Props = {
  order: IOrderViewModel;
  isDisabled: boolean;
  handleBlur: {
    (
      event: React.FocusEvent<HTMLInputElement>,
      obj: IOrderViewModel,
      name: string,
      value: number
    ): void;
  } | null;
};

type Action = { type: 'RESET_FORM' };

const initialState: IOrderViewModel = {
  id: null,
  orderNo: null,
  orderStatus: EnumOrderStatus.Ordered,
  orderDate: dayjs(new Date()),
  trackingNo: null,
  orderUnitCost: 0,
  orderRetailValue: 0,
  deliveryDate: null,
  estimatedDeliveryDate: null,
  vendorInvoiceNumber: null,
  vendorId: '',
  vendor: null,
  vendorContactId: '',
  vendorContact: null,
  shippingCost: 0,
  deliveryStreet: '',
  deliveryPostalCode: '',
  deliveryArea: '',
  deliveryCity: '',
  deliveryCountry: 'string',
  attention: null,
  orderPosts: null,
  responsibleId: '',
};

function stateReducer(state: IOrderViewModel, action: Action): IOrderViewModel {
  switch (action.type) {
    case 'RESET_FORM':
      return initialState;

    default:
      return state;
  }
}

export const SingleOrderView = ({ order, isDisabled, handleBlur }: Props) => {
const { t } = useTranslation();
  const [state, dispatch] = useReducer(stateReducer, order);

  // INITIALIZATION

  // API

  // HANDLES
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  // EFFECTS
  useEffect(() => {
    // dispatch({ type: 'SET_FORM', payload: order });
  }, [order]);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        mb: 2,
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        zIndex: 1,
        backgroundColor: 'primary.main',
        borderColor: `${GetChartColor(Number(state.orderStatus))}.lighter`,
        boxShadow:
          '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Grid
        item
        xs={1.5}
        sx={{
          p: 0.3,
          width: 128,
          height: 64,
          borderRadius: 2,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: 'primary.light',
        }}
      >
        <img
          src={state.vendor?.url || MissingPhoto.COMPANY_URL}
          style={{
            width: '100%',
            height: '100%',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            objectFit: 'scale-down',
          }}
          alt={'logo'}
        />
      </Grid>

      <Grid
        item
        xs={10.5}
        sx={{
          pl: 1,
        }}
      >
        <Stack direction={'row'}>
          <Typography
            sx={{
              width: '50%',
              letterSpacing: 1,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 18,
            }}
          >
            {state.vendor?.name}
          </Typography>
          <Typography
            sx={{
              width: '50%',
              letterSpacing: 2,
              pr: 2,
              textAlign: 'right',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 16,
            }}
          >
            {state.vendorInvoiceNumber || `${t("Orders.SingleOrderView.noInvoiceNumber")}`}
          </Typography>
        </Stack>

        <Stack direction={'row'}>
          <Typography
            sx={{
              width: '15%',
              letterSpacing: 1,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'normal',
              fontSize: 14,
            }}
          >
            {t("Orders.SingleOrderView.orderDate")}
          </Typography>
          <Typography
            sx={{
              width: '35%',
              letterSpacing: 2,
              pr: 2,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'normal',
              fontSize: 14,
            }}
          >
            {dayjs(state.orderDate).format('DD-MM-YYYY HH:mm')}
          </Typography>
          <Typography
            sx={{
              width: '25%',
              letterSpacing: 1,
              textAlign: 'right',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'normal',
              fontSize: 14,
            }}
          >
            {t("Orders.SingleOrderView.shippingCost")}
          </Typography>
          <Typography
            sx={{
              width: '25%',
              letterSpacing: 2,
              pr: 2,
              textAlign: 'right',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
              color: 'secondary.text',
            }}
          >
            {state.shippingCost.toFixed(2) || 0}
          </Typography>
        </Stack>
        <Stack direction={'row'}>
          <Typography
            sx={{
              width: '15%',
              letterSpacing: 1,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'normal',
              fontSize: 14,
            }}
          >
            {t("Orders.SingleOrderView.trackingNo")}
          </Typography>
          <Typography
            sx={{
              width: '35%',
              letterSpacing: 2,
              pr: 2,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'normal',
              fontSize: 14,
            }}
          >
            {state.trackingNo ||`${t("Orders.SingleOrderView.trackingNoNotAvaible")}`}
          </Typography>
          <Typography
            sx={{
              width: '25%',
              letterSpacing: 1,
              textAlign: 'right',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'normal',
              fontSize: 14,
            }}
          >
            {t("Orders.SingleOrderView.unitPrice")}
          </Typography>
          <Typography
            sx={{
              width: '25%',
              letterSpacing: 2,
              pr: 2,
              textAlign: 'right',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
              color: 'secondary.text',
            }}
          >
            {state.orderUnitCost.toFixed(2) || 0}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
