import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { styled, alpha } from '@mui/material/styles';
import {
  MenuItem,
  Select,
  InputBase,
  Typography,
  Button,
  Paper,
  Grid,
  IconButton,
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SearchIcon from '@mui/icons-material/Search';
import { SearchFilterStyle } from 'data/FontStyle';
import { useTranslation } from 'react-i18next';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  marginTop: 3,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const SearchFilter = (props: any) => {
  const {
    Icon,
    Header,
    filterValue,
    handleFilterChange,
    handleSearch,
    handleNew,
    selectList,
    gfx,
    hideAll,
    hasSelectAll,
  } = props;
  const { t } = useTranslation();

  return (
    <Paper
      variant='elevation3'
      sx={{
        //height: 50,
        borderRadius: 2,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: 'primary.main',
        pl: 1,
        pt: 0.5,
      }}
    >
      <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
        <Grid item sx={{}}>
          {!!Icon && <Icon sx={SearchFilterStyle.icon} />}
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Typography sx={[SearchFilterStyle.subHeader, { mt: 1.5 }]}>
            {Header}
          </Typography>
        </Grid>
        <Grid item sx={{ maxWidth: '30%' }}>
          {handleSearch && (
            <Search sx={{ m: 0.5, display: 'flex', width: '30%', height: 32 }}>
              <Box>
                <SearchIcon sx={{ m: 0.5, fontSize: 26 }} />
              </Box>
              <Box>
                <InputBase
                  sx={[SearchFilterStyle.text, { mt: 0 }]}
                  placeholder={t('searchFilter.placeholder')}
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={handleSearch}
                />
              </Box>
            </Search>
          )}
        </Grid>
        <Grid item sx={{ m: 0.5, maxWidth: '30%' }}>
          {!!selectList && (
            <Select
              variant='standard'
              sx={[SearchFilterStyle.text, { mr: 2, width: '100%' }]}
              labelId='select-type-label'
              id='select-type'
              value={
                filterValue !== undefined &&
                filterValue !== null &&
                filterValue !== ''
                  ? filterValue.toString().toLowerCase() === 'all'
                    ? 'All'
                    : filterValue
                  : 'All'
              }
              onChange={(e) => {
                // console.log('Filter', e.target);
                handleFilterChange(e.target.value);
              }}
            >
              {/*  {Header !== 'Companies' && (
                <MenuItem key={'x'} value={'x'}>
                  Alle
                </MenuItem>
              )}  */}
              {hasSelectAll && (
                <MenuItem sx={SearchFilterStyle.text} value='All'>
                  All
                </MenuItem>
              )}
              {selectList.map((item: any, index: number) => (
                <MenuItem
                  sx={SearchFilterStyle.textDropDown}
                  key={index}
                  value={item.id}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
        <Grid item sx={{ m: 0.5 }}>
          {!!handleNew && (
            <Box sx={SearchFilterStyle.buttonBox}>
              <IconButton
                sx={{
                  p: 0,
                  m: 0,
                  width: 18,
                  height: 18,
                }}
                onClick={(e) => {
                  handleNew(e);
                }}
              >
                <AddRoundedIcon sx={{ fontSize: 26 }} />
              </IconButton>
            </Box>
          )}
          {!!gfx && gfx}
        </Grid>
      </Grid>
    </Paper>
  );
};
