import React, { useEffect, useState } from 'react';
import useWindowsDimension from 'functions/useWindowsDimension';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { SearchFilter } from 'components/ui/SearchFilter';
import { Button, List, TextField } from '@mui/material';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { IPackageViewModel } from 'Interfaces/IPackageConfig';
import { PackageForm } from 'pages/Companies/PackageForm';
import ownerAtom from 'data/atoms/ownerAtom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const DefaultSettings = () => {
  const { t } = useTranslation();
  const { height } = useWindowsDimension();
  const [owner, setOwner] = useAtom(ownerAtom);
  const [TicketNO, setTicketNO] = useState(0);
  const [OrderNO, setOrderNO] = useState(0);
  const [InvoiceNO, setInvoiceNO] = useState(0);
  const [WeekendFee, setWeekendFee] = useState(0);
  const [TollFee, setTollFee] = useState(0);
  const [defaultPackageName, setDefaultPackageName] =
    useState('Default package');
  const [defaultDepartmentName, setDefaultDepartmentName] =
    useState('Default department');

  let packageID = '601f90d5-6ab0-4fbc-8d0c-c1cc00000001';
  const [defaultPackage, setDefaultPackage] = useState<IPackageViewModel>(
    {} as IPackageViewModel
  );
  // api

  const { data: packageData } = useGet(
    rq.package(owner.id, packageID, true),
    true
  );

  const { data: settingData } = useGet(rq.settings(true), true);

  useEffect(() => {
    if (packageData) {
      setDefaultPackage(packageData as IPackageViewModel);
    }
  }, [packageData]);

  useEffect(() => {
    console.log('settingData', settingData);
    if (settingData) {
      setTicketNO(settingData.startNoTicket);
      setOrderNO(settingData.startNoOrder);
      setInvoiceNO(settingData.startNoInvoice);
      setDefaultPackageName(settingData.defaultPackageName);
      setDefaultDepartmentName(settingData.defaultDepartmentName);
      setWeekendFee(settingData.weekendFee);
      setTollFee(settingData.tollFee);
    }
  }, [settingData]);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <Grid
        item
        xs={6}
        sx={{
          p: 0,
          m: 0,
          pr: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SearchFilter Header={t('Settings.DefaultValuesSetup.header')} />
        <List
          sx={{
            height: Math.ceil(height - 148),
            backgroundColor: 'primary.back',
            p: 1,
            overflow: 'auto',
          }}
        >
          <Grid
            sx={{
              p: 0,
              m: 0,
              pr: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid
              sx={{
                p: 0,
                m: 1,
                pr: 1,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <TextField
                disabled={true}
                sx={{ width: '65%', m: 1 }}
                label={t('Settings.DefaultValuesSetup.ticketNo')}
                value={TicketNO}
              ></TextField>
              <Button disabled={true} variant='contained' sx={{ m: 1 }}>
                {t('Settings.DefaultValuesSetup.set')}
              </Button>
              <Button
                disabled={true}
                variant='contained'
                sx={{ m: 1 }}
                color='secondary'
              >
                {t('Settings.DefaultValuesSetup.confirm')}
              </Button>
            </Grid>

            <Grid
              sx={{
                p: 0,
                m: 1,

                pr: 1,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <TextField
                disabled={true}
                sx={{ width: '65%', m: 1 }}
                label={t('Settings.DefaultValuesSetup.orderNo')}
                value={OrderNO}
              ></TextField>
              <Button disabled={true} variant='contained' sx={{ m: 1 }}>
                {t('Settings.DefaultValuesSetup.set')}
              </Button>
              <Button
                disabled={true}
                variant='contained'
                sx={{ m: 1 }}
                color='secondary'
              >
                {t('Settings.DefaultValuesSetup.confirm')}
              </Button>
            </Grid>

            <Grid
              sx={{
                p: 0,
                m: 1,

                pr: 1,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <TextField
                disabled={true}
                sx={{ width: '65%', m: 1 }}
                label={t('Settings.DefaultValuesSetup.invoiceNo')}
                value={InvoiceNO}
              ></TextField>
              <Button disabled={true} variant='contained' sx={{ m: 1 }}>
                {t('Settings.DefaultValuesSetup.set')}
              </Button>
              <Button
                disabled={true}
                variant='contained'
                sx={{ m: 1 }}
                color='secondary'
              >
                {t('Settings.DefaultValuesSetup.confirm')}
              </Button>
            </Grid>

            <Grid
              sx={{
                p: 0,
                m: 1,

                pr: 1,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <TextField
                disabled={true}
                sx={{ width: '65%', m: 1 }}
                label={t('Settings.DefaultValuesSetup.defaultPackageName')}
                value={defaultPackageName}
              ></TextField>
              <Button disabled={true} variant='contained' sx={{ m: 1 }}>
                {t('Settings.DefaultValuesSetup.set')}
              </Button>
              <Button
                disabled={true}
                variant='contained'
                sx={{ m: 1 }}
                color='secondary'
              >
                {t('Settings.DefaultValuesSetup.confirm')}
              </Button>
            </Grid>

            <Grid
              sx={{
                p: 0,
                m: 1,

                pr: 1,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <TextField
                disabled={true}
                sx={{ width: '65%', m: 1 }}
                label={t('Settings.DefaultValuesSetup.defaultDepartmentName')}
                value={defaultDepartmentName}
              ></TextField>
              <Button disabled={true} variant='contained' sx={{ m: 1 }}>
                {t('Settings.DefaultValuesSetup.set')}
              </Button>
              <Button
                disabled={true}
                variant='contained'
                sx={{ m: 1 }}
                color='secondary'
              >
                {t('Settings.DefaultValuesSetup.confirm')}
              </Button>
            </Grid>
          </Grid>
        </List>
        {/*  footer */}
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          p: 0,
          m: 0,
          pr: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SearchFilter
          Header={t('Settings.DefaultValuesSetup.editDefaultPackage')}
        />
        <Paper>
          <PackageForm packageData={defaultPackage} isDefaultPackage={true} />
        </Paper>
      </Grid>
    </Grid>
  );
};
