import { useEffect, useReducer, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  ListItemText,
  ListItemIcon,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Pagination,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import { SearchFilter } from 'components/ui/SearchFilter';
import useWindowsDimension from 'functions/useWindowsDimension';
import { useAtom } from 'jotai';
import accessTokenAtom from 'data/atoms/accessTokenAtom';
import CloudRoundedIcon from '@mui/icons-material/CloudRounded';
import ApiIcon from '@mui/icons-material/Api';
import { SingleCompanyView } from 'components/ui/SingleCompanyView';
import { Business } from '@mui/icons-material';
import { t } from 'i18next';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { ImportCompanyForm } from './ImportCompanyForm';
import {
  ICompanyAddViewModel,
  ICompanyViewModel,
  IDepartmentViewModel,
} from 'Interfaces/ICompaniesConfig';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormBox } from 'components/form';
import { ImportEmployeeForm } from './ImportEmployeeForm';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';
import { ImportResourceForm } from './ImportResourceForm';
import { IResourcesViewModel } from 'Interfaces/IResourcesConfig';
import { ToResource } from './Converts/ToResource';
import { ToCompanies } from './Converts/ToCompanies';
import { ToEmployees } from './Converts/ToEmployees';
import { rq } from 'http/apiRoutes';
import { useGet } from 'http/useInnovit';
import RecentActorsRoundedIcon from '@mui/icons-material/RecentActorsRounded';
import WebStoriesRoundedIcon from '@mui/icons-material/WebStoriesRounded';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { ImportEmployeesList } from './ImportEmployeesList';
import { ImportResourcesList } from './ImportResourcesList';
import {
  IProductAddViewModel,
  IProductViewModel,
} from 'Interfaces/IProductConfig';
import {
  IImportEmployeeAddViewModel,
  IImportProductAddViewModel,
  removeLastColonPart,
} from './Converts/ICloudFactoryInterfaces';
import { ToProduct } from './Converts/ToProduct';
import { IBrandViewModel } from 'pages/Setup/setupConfig';
import { ImportProductList } from './ImportProductList';
import CloudFactoryProductView from './Converts/CloudFactoryProductView';
import userAtom from 'data/atoms/userAtom';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import { ToAddEmployees } from './Converts/ToAddEmployee';
import _ from 'lodash';
import { truncateText } from 'functions/truncateText';
const moduleOptions = [
  {
    id: 1,
    name: 'Get Companies',
    description: 'Import companies from Cloud Factory',
  },
  {
    id: 2,
    name: 'Get Users',
    description: 'Import Users from Company: ',
  },
  {
    id: 3,
    name: 'Get Products',
    description: 'Import Products from Cloud Factory',
  },
  {
    id: 4,
    name: 'API Documentation',
    description: 'Access API usage and examples',
  },
];

export const enum ShowRightSideEnum {
  companies = 'companies',
  employee = 'employee',
  resource = 'resource',
  employees = 'employees',
  resources = 'resources',
  products = 'products',
}

export interface ICloudFactoryData {
  id: string | null;
  companies: ICompanyAddViewModel[];
  localCompany: ICompanyAddViewModel | ICompanyViewModel;
  companyEmployees: IImportEmployeeAddViewModel[];
  products: IImportProductAddViewModel[];
  selectedCompany: ICompanyViewModel;
  selectedEmployee: IImportEmployeeAddViewModel | null;
  selectedResource: IResourcesViewModel | null;
  selectedAddCompany: ICompanyAddViewModel | null;
  departments: IDepartmentViewModel[];
  brands: IBrandViewModel[];
  rightSideView: ShowRightSideEnum;
  nextCompany: boolean;
  page: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  currentPage: number;
}

const initialState: ICloudFactoryData = {
  id: null,
  companies: [],
  localCompany: {} as unknown as ICompanyAddViewModel,
  companyEmployees: [],
  products: [],
  selectedCompany: {} as unknown as ICompanyViewModel,
  selectedEmployee: null,
  selectedResource: null,
  selectedAddCompany: null,
  departments: [],
  brands: [],
  rightSideView: ShowRightSideEnum.companies,
  nextCompany: false,
  page: 1,
  pageSize: 10,
  totalPages: 1,
  totalRecords: 0,
  currentPage: 1,
};

type Action =
  | { type: 'SET_ID'; payload: string }
  | { type: 'SET_COMPANIES'; payload: ICompanyAddViewModel[] }
  | {
      type: 'SET_LOCAL_COMPANY';
      payload: ICompanyViewModel | ICompanyAddViewModel;
    }
  | { type: 'SET_COMPANY_EMPLOYEES'; payload: IImportEmployeeAddViewModel[] }
  | { type: 'SET_PRODUCTS'; payload: IImportProductAddViewModel[] }
  | { type: 'ADD_PRODUCT'; payload: IImportProductAddViewModel }
  | { type: 'SET_SELECTED_COMPANY'; payload: ICompanyViewModel }
  | {
      type: 'SET_SELECTED_EMPLOYEE';
      payload: IImportEmployeeAddViewModel | null;
    }
  | { type: 'SET_SELECTED_RESOURCE'; payload: IResourcesViewModel | null }
  | { type: 'SET_SELECTED_ADD_COMPANY'; payload: ICompanyAddViewModel | null }
  | { type: 'SET_DEPARTMENTS'; payload: IDepartmentViewModel[] }
  | { type: 'SET_BRANDS'; payload: IBrandViewModel[] }
  | { type: 'SET_RIGHT_SIDE_VIEW'; payload: ShowRightSideEnum }
  | { type: 'REMOVE_COMPANY_PRODUCTS'; payload: string[] }
  | { type: 'REMOVE_COMPANY_EMPLOYEES'; payload: string[] }
  | { type: 'REMOVE_SELECTED_COMPANY'; payload: string }
  | { type: 'SET_NEXT_COMPANY'; payload: boolean }
  | {
      type: 'SET_PAGINATION';
      payload: {
        page: number;
        pageSize: number;
        totalPages: number;
        totalRecords: number;
        currentPage: number;
      };
    }
  | { type: 'SET_CURRENT_PAGE'; payload: number };

const reducer = (
  state: ICloudFactoryData,
  action: Action
): ICloudFactoryData => {
  switch (action.type) {
    case 'SET_ID':
      return { ...state, id: action.payload };
    case 'SET_COMPANIES':
      return { ...state, companies: action.payload };
    case 'SET_LOCAL_COMPANY':
      return { ...state, localCompany: action.payload };
    case 'SET_PRODUCTS':
      return { ...state, products: action.payload };
    case 'ADD_PRODUCT':
      return { ...state, products: [...state.products, action.payload] };
    case 'SET_COMPANY_EMPLOYEES':
      console.log('NEW : SET_COMPANY_EMPLOYEES', action.payload);
      return { ...state, companyEmployees: action.payload };
    case 'SET_SELECTED_COMPANY':
      return { ...state, selectedCompany: action.payload };
    case 'SET_SELECTED_EMPLOYEE':
      return { ...state, selectedEmployee: action.payload };
    case 'SET_SELECTED_RESOURCE':
      return { ...state, selectedResource: action.payload };
    case 'SET_SELECTED_ADD_COMPANY':
      return { ...state, selectedAddCompany: action.payload };
    case 'SET_DEPARTMENTS':
      return { ...state, departments: action.payload };
    case 'SET_BRANDS':
      return { ...state, brands: action.payload };
    case 'SET_RIGHT_SIDE_VIEW':
      return { ...state, rightSideView: action.payload };
    case 'REMOVE_COMPANY_PRODUCTS':
      return {
        ...state,
        products: state.products.filter(
          (product) => !action.payload.includes(product.thirdPartyId.toString())
        ),
      };
    case 'REMOVE_COMPANY_EMPLOYEES':
      return {
        ...state,
        companyEmployees: state.companyEmployees.filter(
          (employee) => !action.payload.includes(employee.email)
        ),
      };
    case 'REMOVE_SELECTED_COMPANY':
      return {
        ...state,
        companies: state.companies.filter(
          (company) => !action.payload.includes(company.email)
        ),
      };
    case 'SET_NEXT_COMPANY':
      return { ...state, nextCompany: action.payload };
    case 'SET_PAGINATION':
      return {
        ...state,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalRecords,
        currentPage: action.payload.currentPage,
      };
    case 'SET_CURRENT_PAGE':
      return { ...state, currentPage: action.payload };
    default:
      return state;
  }
};
export const CloudFactory = () => {
  /* INITIALIZATIONS */
  const [state, dispatch] = useReducer(reducer, initialState);
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);
  const [user] = useAtom(userAtom);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('Loading...');
  const [selectedModule, setSelectedModule] = useState<number>(0);
  const { height } = useWindowsDimension();
  const [mouseOver, setMouseOver] = useState<number>(0);
  const [expanded, setExpanded] = useState<boolean>(true);

  // PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);
  const [isFirstEmployeeSaved, setIsFirstEmployeeSaved] = useState(false);

  // const ref = useRef<number>(2);

  // api
  // get users from local company
  const { data: localCompanyData } = useGet(rq.companies('2', true));
  console.log('localcompany data: ', localCompanyData);

  // API CALLS MED USE EFFECT

  let isEnabled = state.selectedCompany?.id ? true : false;
  const { data: departmentsData } = useGet(
    rq.departments(state.selectedCompany?.id || 'new', isEnabled),
    isEnabled
  );
  useEffect(() => {
    if (departmentsData) {
      dispatch({
        type: 'SET_DEPARTMENTS',
        payload: (departmentsData?.departments as IDepartmentViewModel[]) || [],
      });
    }
  }, [departmentsData]);

  const { data: brandData } = useGet(rq.getAllBrands(0, isEnabled), isEnabled);
  useEffect(() => {
    if (brandData) {
      let brands = brandData.map((item: IBrandViewModel) => ({
        id: item.id,
        brandName: item.brandName,
        isActive: item.isActive,
      }));
      dispatch({
        type: 'SET_BRANDS',
        payload: brands,
      });
    }
  }, [brandData]);

  // API CALLS MOT CLOUD FACTORY
  const [refreshToken] = useState(
    'ozAPKTeYhy7KW1JJnZbFh0W90glf1mV1i-L-xjSjtBgg4'
  );

  const fetchAccessToken = async (refreshToken: string) => {
    const url = `https://portal.api.cloudfactory.dk/Authenticate/ExchangeRefreshToken/${refreshToken}`;

    setLoading(true);
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        setAccessToken(data.access_token);
        console.log('Access Token:', data.access_token);
      } else {
        throw new Error('Failed to fetch access token');
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompaniesData = async (pageIndex: number, pageSize: number) => {
    if (!refreshToken) return;
    console.log('Fetching module data');

    const url = `https://portal.api.cloudfactory.dk/v2/customers/Customers?PageIndex=${pageIndex}&PageSize=${pageSize}`;

    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        dispatch({
          type: 'SET_PAGINATION',
          payload: {
            page: data.metadata.page,
            pageSize: data.metadata.pageSize,
            totalPages: data.metadata.totalPages,
            totalRecords: data.metadata.totalRecords,
            currentPage: data.metadata.page,
          },
        });
        let result = ToCompanies(data);
        dispatch({
          type: 'SET_COMPANIES',
          payload: result,
        });
      } else {
        throw new Error('Failed to fetch module data');
      }
    } catch (error) {
      console.error('Error fetching module data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanyEmployees = async (legacyCustomerId: string) => {
    if (!accessToken) return;
    console.log('fetchCompanyEmployees, legacyId: ', legacyCustomerId);
    const url = `https://portal.api.cloudfactory.dk/Microsoft/GetAllUsersOnCustomer?customerId=${legacyCustomerId}`;
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log('fetchCompanyEmployees... response OK... data: ', data);
        const employeeProductsPromises = data.map(async (employee: any) => {
          let products = await fetchEmployeeProducts(
            employee.id,
            legacyCustomerId
          );
          return { ...employee, products };
        });

        console.log(
          'fetchCompanyEmployees... response OK... employeeProductsPromises: ',
          employeeProductsPromises
        );
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.employees,
        });

        const employeesWithProducts = await Promise.all(
          employeeProductsPromises
        );

        console.log(
          'fetchCompanyEmployees... employeesWithProducts: ',
          employeesWithProducts,
          'state.departments?: ',
          state.departments,
          'state.selectedCompany?: ',
          state.selectedCompany
        );

        // convert users to addEmployees
        let result = ToAddEmployees(
          employeesWithProducts,
          user.id,
          state.selectedCompany,
          state.departments[0]?.id
        );
        console.log(
          'fetchCompanyEmployees...RESULT Employee Products RETURN:',
          employeeProductsPromises
        );
        dispatch({
          type: 'SET_COMPANY_EMPLOYEES',
          payload: result,
        });
      } else {
        throw new Error('Failed to fetch users data');
      }
    } catch (error) {
      console.error('Error fetching users data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployeeProducts = async (
    employeeId: string,
    legacyCustomerId: string
  ) => {
    if (!accessToken) return;
    legacyCustomerId = '93a40604-5ff6-4bfa-81f5-45134707e539';
    const url = `https://portal.api.cloudfactory.dk/v2/microsoft/customer/${legacyCustomerId}/users/${employeeId}/licenses`;
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log('NEW : fetchEmployeeProducts:', data);
        return data;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching users data:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanyProducts = async (legacyCustomerId: string) => {
    if (!accessToken) return;
    const url = `https://portal.api.cloudfactory.dk/v2/microsoft/customer/${legacyCustomerId}/subscriptions?Statuses=Active`;
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();

        data.forEach((product: any) => {
          fetchCatalogueProductData(product, 1, 50);
        });
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.products,
        });
      } else {
        throw new Error('Failed to fetch users data');
      }
    } catch (error) {
      console.error('Error fetching users data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCatalogueProductData = async (
    obj: any,
    pageIndex: number,
    pageSize: number
  ) => {
    if (!accessToken) return;

    let partnerId = '95511d6b-b0b4-4e7f-b291-6751fd94a6e2';
    let userId = 'c4ea4b54-fd63-4835-a32f-ef2fe71d0dde';
    let legacyCustomerId = '93a40604-5ff6-4bfa-81f5-45134707e539';
    const validOn = new Date().toISOString();

    let paymentCycle = obj.billingCycle === 'Monthly' ? 1 : 12;
    let bindingPeriod = obj.termDuration === 'P1Y' ? 12 : 1;
    let sku = removeLastColonPart(obj.sku);

    const url = `https://portal.api.cloudfactory.dk/v2/catalogue/products?PageIndex=1&PageSize=50&partnerId=${partnerId}&Filter.SKUs=${sku}&Filter.BillingCycles=${paymentCycle}&Filter.CommitmentLengths=${bindingPeriod}&Filter.ValidOn=${validOn}&Filter.CustomerId=${legacyCustomerId}&Filter.UserId=${userId}`;

    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        let result = ToProduct(
          obj,
          data.results[0].product,
          data.results[0].price
        );
        dispatch({
          type: 'ADD_PRODUCT',
          payload: result,
        });
      } else {
        console.error(
          'Failed to fetch product data, status:',
          response.status,
          response.statusText
        );
        const errorData = await response.text();
        console.error('Error details:', errorData);
      }
    } catch (error) {
      console.error('Error fetching product data:', error);
    } finally {
      setLoading(false);
    }
  };

  // HANDLE FUNCTIONS
  const HandleShow = (type: string) => {
    switch (type) {
      case 'test':
        let companyId = '93a40604-5ff6-4bfa-81f5-45134707e539';
        let employeeId = 'c4ea4b54-fd63-4835-a32f-ef2fe71d0dde';
        console.log(
          'NEW : state.selectedCompany.thirdPartyId.toString()',
          state.selectedCompany.thirdPartyId.toString()
        );
        handleShowProducts(state.selectedCompany);
        // fetchCompanyEmployees(companyId);
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.employee,
        });
        break;
      case 'companies':
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.companies,
        });
        break;
      case 'products':
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.products,
        });
        break;
      case 'employees':
        //fetchCompanyEmployees(state.selectedCompany.legacyId.toString());
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.employees,
        });
        break;

      case 'resources':
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.resources,
        });
        break;
      case 'nextCompany':
        dispatch({
          type: 'SET_NEXT_COMPANY',
          payload: false,
        });
        dispatch({
          type: 'SET_RIGHT_SIDE_VIEW',
          payload: ShowRightSideEnum.companies,
        });
        break;
      default:
        break;
    }
  };

  const handleModuleClick = (
    moduleId: number,
    pageIndex: number,
    pageSize: number
  ) => {
    console.log('handleModuleClick', moduleId, pageIndex, pageSize);
    setExpanded(!expanded);
    setSelectedModule(moduleId);
    switch (moduleId) {
      case 1:
        fetchCompaniesData(pageIndex, pageSize);
        break;
      case 2:
        //fetchCatalogueProductData('', pageIndex, pageSize);
        break;
      case 3:
        break;
      default:
        break;
    }
  };

  const handleCompanySelect = (company: ICompanyAddViewModel) => {
    dispatch({
      type: 'SET_SELECTED_ADD_COMPANY',
      payload: company,
    });
  };

  const handleShowEmployee = (company: ICompanyViewModel) => {
    console.log('handleShowEmployee', company);
    if (company.legacyId < 0) return;
    dispatch({
      type: 'SET_SELECTED_COMPANY',
      payload: company,
    });
    dispatch({
      type: 'SET_RIGHT_SIDE_VIEW',
      payload: ShowRightSideEnum.employee,
    });
  };

  const handleShowProducts = (company: ICompanyViewModel) => {
    console.log('handleShowProducts', company);
    if (company.legacyId < 0) return;
    dispatch({
      type: 'SET_SELECTED_COMPANY',
      payload: company,
    });
    dispatch({
      type: 'REMOVE_SELECTED_COMPANY',
      payload: company.email,
    });
    fetchCompanyProducts(company.thirdPartyId.toString());
    dispatch({
      type: 'SET_RIGHT_SIDE_VIEW',
      payload: ShowRightSideEnum.products,
    });
  };

  const handleClickEmployee = (employee: IImportEmployeeAddViewModel) => {
    if (state.selectedCompany === null) return;
    dispatch({
      type: 'SET_SELECTED_EMPLOYEE',
      payload: employee,
    });

    let resource: IResourcesViewModel = ToResource(
      employee,
      state.selectedCompany
    );
    console.log('handleClickEmployee', resource, state.selectedCompany);
    dispatch({
      type: 'SET_SELECTED_RESOURCE',
      payload: resource,
    });
  };

  const handleRemoveEmployees = (employees: IEmployeeViewModel[]) => {
    setIsFirstEmployeeSaved(true);
    console.log('handleRemoveEmployees', employees);
    const emailsToRemove = employees.map((employee) => employee.email);
    dispatch({ type: 'REMOVE_COMPANY_EMPLOYEES', payload: emailsToRemove });
  };

  const handleRemoveResources = (resource: IResourcesViewModel[]) => {
    console.log('handleRemoveEmployees', resource);
    const emailsToRemove = resource.map((employee) => employee.email);
    dispatch({ type: 'REMOVE_COMPANY_EMPLOYEES', payload: emailsToRemove });
  };

  const handleRemoveProducts = (products: IProductViewModel[]) => {
    console.log('NEW : handleRemoveProducts', products);
    const productsToRemove = products.map((product) =>
      product.thirdPartyId.toString()
    );
    dispatch({ type: 'REMOVE_COMPANY_PRODUCTS', payload: productsToRemove });

    if (productsToRemove.length === state.products.length) {
      fetchCompanyEmployees(state.selectedCompany.legacyId.toString());
      console.log('NEW : DONE', state);
      dispatch({
        type: 'SET_RIGHT_SIDE_VIEW',
        payload: ShowRightSideEnum.employees,
      });
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleClickProduct = (product: IImportProductAddViewModel) => {
    console.log('handleClickProduct', product);
  };
  console.log('NEW : STATE : ', state);

  // USE EFFECTS
  useEffect(() => {
    if (state.rightSideView === ShowRightSideEnum.companies) return;
    console.log('NEW : state Fetching companies data', state.selectedEmployee);
  }, [state.rightSideView]);

  useEffect(() => {
    if (!_.isEmpty(state.localCompany)) {
      console.log('localCompany useEffect', state.localCompany);
      fetchCompanyEmployees(state.localCompany.legacyId.toString());
    }
  }, [state.localCompany]);

  useEffect(() => {
    console.log('NEW : state.products', state.products);
  }, [state.products]);
  useEffect(() => {
    console.log('NEW : state.totalPages', state.totalPages);
  }, [state.totalPages]);
  useEffect(() => {
    fetchCompaniesData(state.currentPage, state.pageSize);
  }, [state.currentPage, state.pageSize]);
  return (
    <Grid item xs={12}>
      <SearchFilter Header='Cloud Factory API' Icon={CloudRoundedIcon} />
      <Grid container sx={{ mt: 1, p: 0, display: 'flex', flexGrow: 1 }}>
        <Accordion
          disableGutters
          elevation={0}
          sx={{
            backgroundColor: 'primary.main',
            borderRadius: 2,
            width: '100%',
          }}
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Grid item xs={12} sx={{ m: 0, p: 0 }}>
              <FormBox
                Icon={Business}
                title={t('modules.api')}
                FormBTN={null}
              />
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} sx={{ pl: 1 }}>
              <Grid item xs={6}>
                <Paper
                  elevation={3}
                  sx={{ p: 2, height: '100%', borderRadius: 2 }}
                >
                  <Typography variant='h5' gutterBottom>
                    API Token Manager
                  </Typography>

                  <Button
                    variant='contained'
                    onClick={() => fetchAccessToken(refreshToken)}
                    startIcon={
                      loading ? (
                        <CircularProgress size={18} color='inherit' />
                      ) : (
                        <CloudRoundedIcon />
                      )
                    }
                  >
                    Refresh Token
                  </Button>
                  {accessToken ? (
                    <Typography variant='body2' sx={{ mt: 2 }}>
                      Access Token: Set
                    </Typography>
                  ) : (
                    <Typography variant='body2' sx={{ mt: 2 }}>
                      No Access Token Set
                    </Typography>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={3}
                  sx={{ p: 2, height: '100%', borderRadius: 2 }}
                >
                  <Typography variant='h5' gutterBottom>
                    System Status
                  </Typography>
                  <Typography variant='body1'>
                    Check the current system status and recent updates.
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant='subtitle1'>Latest Update:</Typography>
                    <Typography variant='body2'>{status}</Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 1,
                    height: '100%',
                    borderRadius: 2,
                    backgroundColor: 'transparent',
                    boxShadow: 0,
                  }}
                >
                  <Typography variant='h5' gutterBottom>
                    Available Modules
                  </Typography>
                  <Grid container spacing={2}>
                    {moduleOptions.map((module, index) => (
                      <Grid item key={index} xs={12 / moduleOptions.length}>
                        <Paper
                          elevation={3}
                          sx={{
                            p: 2,
                            cursor: 'pointer',
                            borderRadius: 2,
                            backgroundColor:
                              selectedModule === module.id
                                ? 'primary.light'
                                : mouseOver === module.id
                                ? 'primary.light'
                                : 'background.paper',
                          }}
                          onMouseOver={() => setMouseOver(module.id)}
                          onMouseOut={() => setMouseOver(0)}
                          onClick={() =>
                            handleModuleClick(
                              module.id,
                              currentPage,
                              itemsPerPage
                            )
                          }
                        >
                          <Stack direction='row' spacing={1}>
                            <ListItemIcon>
                              <ApiIcon sx={{ fontSize: 46 }} />
                            </ListItemIcon>
                            <ListItemText
                              primary={module.name}
                              secondary={module.description}
                            />
                            {module.id === 2 && (
                              <Select
                                variant='standard'
                                value={state.localCompany.name} // Ensure state.localCompany is updated correctly
                                onChange={(event) => {
                                  const selectedCompany =
                                    localCompanyData.companies.find(
                                      (
                                        company:
                                          | ICompanyAddViewModel
                                          | ICompanyViewModel
                                      ) => company.name === event.target.value
                                    );
                                  if (selectedCompany) {
                                    dispatch({
                                      type: 'SET_LOCAL_COMPANY',
                                      payload: selectedCompany,
                                    });
                                  }
                                }}
                              >
                                {localCompanyData &&
                                  localCompanyData.companies.map(
                                    (localCompany: ICompanyViewModel) => (
                                      <MenuItem
                                        key={localCompany.id}
                                        value={localCompany.name}
                                      >
                                        {truncateText(localCompany.name, 20)}
                                      </MenuItem>
                                    )
                                  )}
                              </Select>
                            )}
                          </Stack>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Grid item xs={5} sx={{ pr: 1, mt: 1 }}>
          <Grid item xs={12} sx={{ p: 0, m: 0 }}>
            <SearchFilter
              Icon={Business}
              Header={
                state.rightSideView === ShowRightSideEnum.employee
                  ? t('employees.header')
                  : state.rightSideView === ShowRightSideEnum.companies
                  ? t('company.header')
                  : state.rightSideView === ShowRightSideEnum.resources
                  ? t('resources.header')
                  : t('product.header')
              }
              gfx={
                <Stack direction='row' spacing={1} sx={{ mt: -1 }}>
                  <IconButton
                    onClick={() => HandleShow('test')}
                    sx={{
                      color:
                        state.companies.length > 0
                          ? 'success.lighter'
                          : 'primary.light',
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <RecentActorsRoundedIcon
                      sx={{ fontSize: 35, m: 0, p: 0 }}
                    />
                  </IconButton>

                  <IconButton
                    disabled={
                      state.products.length > 0 ||
                      state.companyEmployees.length > 0 ||
                      state.companies.length === 0
                    }
                    onClick={() => HandleShow('companies')}
                    sx={{
                      color:
                        state.companies.length > 0
                          ? 'success.lighter'
                          : 'primary.light',
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <BusinessRoundedIcon sx={{ fontSize: 35, m: 0, p: 0 }} />
                  </IconButton>

                  <IconButton
                    disabled={state.products.length <= 0}
                    onClick={() => HandleShow('products')}
                    sx={{
                      color:
                        state.products.length > 0
                          ? 'success.lighter'
                          : 'primary.light',
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <DevicesOtherRoundedIcon
                      sx={{ fontSize: 35, m: 0, p: 0 }}
                    />
                  </IconButton>

                  <IconButton
                    disabled={
                      state.products.length > 0 ||
                      state.companyEmployees.length <= 0
                    }
                    onClick={() => HandleShow('employees')}
                    sx={{
                      color:
                        state.companyEmployees.length > 0 &&
                        state.products.length === 0
                          ? 'success.lighter'
                          : 'primary.light',
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <PeopleAltRoundedIcon sx={{ fontSize: 35, m: 0, p: 0 }} />
                  </IconButton>

                  <IconButton
                    disabled={
                      state.products.length > 0 ||
                      state.companyEmployees.length <= 0 ||
                      !isFirstEmployeeSaved
                    }
                    onClick={() => HandleShow('resources')}
                    sx={{
                      color:
                        state.companyEmployees.length > 0
                          ? 'success.lighter'
                          : 'primary.light',
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <MapsHomeWorkRoundedIcon
                      sx={{ fontSize: 35, m: 0, p: 0 }}
                    />
                  </IconButton>
                </Stack>
              }
            />
            <List
              sx={{
                height: Math.ceil(height - 274 - (expanded ? 384 : 0)),
                width: '100%',
                mb: 0,
                p: 1,
                backgroundColor: 'primary.back',
                overflow: 'auto',
                borderBottomLeftRadius: 2,
                borderBottomRightRadius: 2,
              }}
            >
              {state.rightSideView === ShowRightSideEnum.companies &&
                state.companies.length > 0 &&
                state.companies?.map(
                  (company: ICompanyAddViewModel, index: number) => (
                    <Grid
                      item
                      xs={12}
                      sx={{ cursor: 'pointer' }}
                      key={index}
                      onClick={() => handleCompanySelect(company)}
                    >
                      <SingleCompanyView index={index} company={company} />
                    </Grid>
                  )
                )}
              {(state.rightSideView === ShowRightSideEnum.employees ||
                state.rightSideView === ShowRightSideEnum.resources) &&
                state.companyEmployees &&
                state.companyEmployees.length > 0 &&
                state.companyEmployees.map(
                  (employee: IImportEmployeeAddViewModel, index: number) => (
                    <Grid
                      item
                      xs={12}
                      sx={{ cursor: 'pointer' }}
                      key={index}
                      onClick={() => handleClickEmployee(employee)}
                    >
                      <SingleEmployeeView index={index} employee={employee} />
                    </Grid>
                  )
                )}
              {state.rightSideView === ShowRightSideEnum.products &&
                state.products &&
                state.products.map(
                  (product: IImportProductAddViewModel, index: number) => (
                    <Grid
                      item
                      xs={12}
                      sx={{ cursor: 'pointer' }}
                      key={index}
                      onClick={() => handleClickProduct(product)}
                    >
                      <CloudFactoryProductView products={product} />
                    </Grid>
                  )
                )}
            </List>
          </Grid>

          <Grid item xs={12} sx={{ p: 0, m: 0 }}>
            <Paper
              variant='elevation3'
              sx={{
                p: 1,
                height: 64,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              <Grid
                container
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Grid item xs={9} sx={{}}>
                  <Pagination
                    showFirstButton
                    showLastButton
                    size='small'
                    count={state.totalPages}
                    page={state.currentPage}
                    onChange={(event, value) =>
                      dispatch({ type: 'SET_CURRENT_PAGE', payload: value })
                    }
                    sx={{ margin: 'auto', padding: '10px' }}
                  />
                </Grid>

                <Button
                  disabled={state.products.length >= 0 ? false : true}
                  size='small'
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    dispatch({ type: 'SET_NEXT_COMPANY', payload: true })
                  }
                >
                  {t('modules.cloudFactory.nextCompany')}
                </Button>
                <Button
                  disabled={state.nextCompany ? false : true}
                  size='small'
                  variant='contained'
                  color='secondary'
                  onClick={() => HandleShow('nextCompany')}
                >
                  {t('modules.cloudFactory.confirm')}
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={7}>
          {state.rightSideView === ShowRightSideEnum.companies && (
            <Grid item xs={12} sx={{ pr: 1, mt: 1 }}>
              <ImportCompanyForm
                expanded={expanded}
                company={
                  state.selectedAddCompany || ({} as ICompanyAddViewModel)
                }
                handleShowProducts={handleShowProducts}
              />
            </Grid>
          )}

          {state.rightSideView === ShowRightSideEnum.employees &&
            state.companyEmployees && (
              <Grid item xs={12} sx={{ pr: 1, mt: 1 }}>
                <ImportEmployeesList
                  expanded={expanded}
                  employees={state.companyEmployees}
                  company={state.selectedCompany}
                  departments={state.departments}
                  handleRemoveEmployees={handleRemoveEmployees}
                />
              </Grid>
            )}
          {state.rightSideView === ShowRightSideEnum.resources &&
            state.companyEmployees && (
              <Grid item xs={12} sx={{ pr: 1, mt: 1 }}>
                <ImportResourcesList
                  expanded={expanded}
                  employees={state.companyEmployees}
                  company={state.selectedCompany}
                  departments={state.departments}
                  handleRemoveResources={handleRemoveResources}
                />
              </Grid>
            )}
          {/*  {state.rightSideView === ShowRightSideEnum.employee &&
            state.selectedCompany &&
            state.selectedEmployee && (
              <Grid item xs={12} sx={{ pr: 1, mt: 1 }}>
                <ImportEmployeeForm
                  expanded={expanded}
                  employee={state.selectedEmployee}
                  company={state.selectedCompany}
                  departments={state.departments}
                />
              </Grid>
            )} */}

          {/*   {state.rightSideView === ShowRightSideEnum.resource &&
            state.selectedCompany && (
              <Grid item xs={12} sx={{ pr: 1, mt: 1 }}>
                <ImportResourceForm
                  expanded={expanded}
                  resource={state.selectedResource || null}
                  company={state.selectedCompany}
                  departments={state.departments}
                />
              </Grid>
            )} */}
          {state.rightSideView === ShowRightSideEnum.products &&
            state.selectedCompany &&
            state.selectedCompany.id &&
            state.products && (
              <Grid item xs={12} sx={{ pr: 1, mt: 1 }}>
                <ImportProductList
                  expanded={expanded}
                  products={state.products}
                  company={state.selectedCompany}
                  departments={state.departments}
                  handleRemoveProducts={handleRemoveProducts}
                />
              </Grid>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};
