import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  IBillingCompanyProductViewModel,
  IBillingDetailViewModel,
  IBillingPostViewModel,
} from 'Interfaces/BillingConfig';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import React from 'react';
import { blueGrey } from '@mui/material/colors';
import { pdfStyle } from './PDFStyle';
import { VIEW_HEIGHT } from '@mui/x-date-pickers/internals/constants/dimensions';
import dayjs from 'dayjs';

// Define the data type for the prop
interface Props {
  data: IBillingPostViewModel;
}

// Create Document Component
export const PDFAgreementView = ({ data }: Props) => {
  // Sort function
  data.billingViewModels.sort((a, b) => {
    if (!a.agreementProduct || !b.agreementProduct) return 0;
    const dateA = a.agreementProduct?.lastBilled
      ? dayjs(a.agreementProduct?.lastBilled)
      : dayjs();
    const dateB = b.agreementProduct?.lastBilled
      ? dayjs(b.agreementProduct?.lastBilled)
      : dayjs();

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;

    // If dates are equal, compare product names
    if (a.agreementProduct.product.name < b.agreementProduct.product.name)
      return -1;
    if (a.agreementProduct.product.name > b.agreementProduct.product.name)
      return 1;

    // If product names are equal, compare by agreementProduct (assuming it's a string or number)
    if (a.agreementProduct < b.agreementProduct) return -1;
    if (a.agreementProduct > b.agreementProduct) return 1;

    return 0;
  });

  // The data.billingViewModels is now sorted.
  const monthlyProducts = { ...data };
  return (
    <>
      <View style={pdfStyle.column50}>
        <Text style={pdfStyle.textLeft}>{'AGREEMENTS'}</Text>
      </View>
      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column20}>
          <Text style={pdfStyle.textLeft}>{'month'}</Text>
        </View>
        <View style={pdfStyle.column35}>
          <Text style={pdfStyle.textLeft}>{'product name'}</Text>
        </View>
        <View style={pdfStyle.column10}>
          <Text style={pdfStyle.textRight}>{'quantity'}</Text>
        </View>
        <View style={pdfStyle.column15}>
          <Text style={pdfStyle.textRight}>{'retail price'}</Text>
        </View>
        <View style={pdfStyle.column20}>
          <Text style={pdfStyle.textRight}>{'line total'}</Text>
        </View>
      </View>
      {/* Divider */}
      <View style={pdfStyle.divider}></View>
      {monthlyProducts.billingViewModels.map(
        (billingItem: IBillingDetailViewModel, index: number) => {
          // Check if there's a companyProduct in the current billing item
          if (billingItem.agreementProduct) {
            const product = billingItem.agreementProduct; // For clarity
            return (
              <View key={index} style={pdfStyle.containerView}>
                <View style={pdfStyle.rowView}>
                  <View style={pdfStyle.column20}>
                    <Text style={pdfStyle.textLeft}>
                      {dayjs(product.lastBilled).format('YYYY-MMMM')}
                    </Text>
                  </View>
                  <View style={pdfStyle.column35}>
                    <Text style={pdfStyle.textLeft}>
                      {product.product.name}
                    </Text>
                  </View>
                  <View style={pdfStyle.column10}>
                    <Text style={pdfStyle.textRight}>{product.quantity}</Text>
                  </View>
                  <View style={pdfStyle.column15}>
                    <Text style={pdfStyle.textRight}>
                      {product.product.retailPrice?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) ?? '0.00'}
                    </Text>
                  </View>
                  <View style={pdfStyle.column20}>
                    <Text style={pdfStyle.textRight}>
                      {(
                        product.quantity * (product.product.retailPrice ?? 0)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>
                  </View>
                </View>
              </View>
            );
          }
          return <View key={index}> </View>;
        }
      )}
      {/* Divider */}
      <View style={pdfStyle.divider} />
      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textLeft}>{'AGREEMENT TOTAL'}</Text>
        </View>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textRight}>
            {data.agreementInvoiceValue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </View>
      </View>
      <View style={pdfStyle.avsnitt} />
      <View style={pdfStyle.avsnitt} />
    </>
  );
};
