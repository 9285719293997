import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  IBillingDetailViewModel,
  IBillingPostViewModel,
} from 'Interfaces/BillingConfig';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import React from 'react';
import { blueGrey } from '@mui/material/colors';
import { pdfStyle } from './PDFStyle';
import { Padding } from '@mui/icons-material';

// Define the data type for the prop
interface Props {
  data: IBillingPostViewModel;
}

// Create Document Component
export const PDFInvestmentView = ({ data }: Props) => {
  const total = data.billingViewModels.reduce((acc, item) => {
    if (item.companyProduct) {
      return (
        acc + item.companyProduct.retailPrice * item.companyProduct.quantity
      );
    }
    return acc;
  }, 0);
  return (
    <>
      <View style={pdfStyle.column100}>
        <Text style={pdfStyle.textLeft}>{'INVESTMENT'}</Text>
      </View>

      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textLeft}>{'product name'}</Text>
        </View>
        <View style={pdfStyle.column25}>
          <Text style={pdfStyle.textLeft}>{'owner'}</Text>
        </View>
        <View style={pdfStyle.column25}>
          <Text style={pdfStyle.textRight}>{'line total'}</Text>
        </View>
      </View>
      {/* Divider */}
      <View style={pdfStyle.divider}></View>
      <View style={pdfStyle.column25}></View>
      {/* Investment */}
      {data.billingViewModels.map(
        (billingItem: IBillingDetailViewModel, index: number) => {
          // Check if there's a companyProduct in the current billing item
          if (billingItem.companyProduct) {
            const product = billingItem.companyProduct; // For clarity
            return (
              <View key={index} style={pdfStyle.containerView}>
                <View style={pdfStyle.rowView}>
                  <View style={pdfStyle.column50}>
                    <Text style={pdfStyle.textLeft}>
                      {product.product.name}
                    </Text>
                  </View>
                  <View style={pdfStyle.column25}>
                    <Text
                      style={{
                        ...pdfStyle.textInfo,
                        paddingLeft: 5,
                        paddingBottom: 5,
                      }}
                    >
                      {product.owner?.name || 'none'}
                    </Text>
                  </View>

                  <View style={pdfStyle.column25}>
                    <Text style={pdfStyle.textRight}>
                      {(product.retailPrice * product.quantity).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            );
          }
          return <View key={index}></View>;
        }
      )}
      {/* Divider */}
      <View style={pdfStyle.divider} />
      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textLeft}>{'INVESTMENTS TOTAL'}</Text>
        </View>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textRight}>
            {total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </View>
      </View>

      <View style={pdfStyle.avsnitt} />
      <View style={pdfStyle.avsnitt} />
    </>
  );
};
