import { useEffect, useReducer, useState } from 'react';



import { useParams } from 'react-router';
import { usePost, usePut } from 'http/useInnovit';
import { rqPost } from 'http/apiRoutes';
import { FormBox } from 'components/form';

import {
  Button,
  Grid,
  List,
  IconButton,
  Box,
  ListItem,
  TextField,
  Stack,
  Select,
  MenuItem,
  Typography,
  Divider,
  Switch,
} from '@mui/material';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import useWindowsDimension from 'functions/useWindowsDimension';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { ObjectDrawer } from './ObjectDrawer';
import {
  EnumObjectType,
  EnumPlacement,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import {
  ConvertProductToCompanyProduct,
  ConvertStorageProductToCompanyProduct,
} from 'functions/Convert';
import { IBillingProductViewModel } from 'Interfaces/BillingConfig';
import { IStorageProductViewModel } from 'Interfaces/IStorageConfig';
import { ProductCardView } from 'components/ui/ProductCardView';
import {
  IResourceTypeViewModel,
  IResourcesAddViewModel,
  IResourcesInitialState,
  IResourcesUpdateViewModel,
  IResourcesViewModel,
} from 'Interfaces/IResourcesConfig';
import {
  ICompanyViewModel,
  IDepartmentViewModel,
  ThirdPartEnum,
} from 'Interfaces/ICompaniesConfig';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { useTranslation } from 'react-i18next';
import userAtom from 'data/atoms/userAtom';
import { useAtom } from 'jotai';
import * as yup from 'yup';


const schema = yup.object().shape({
  name: yup
  .string()
  .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters')
  .required('Name is required'),
  email: yup.string().email().required('Email is required'),
});

const ConvertToAddResource = (
  stateResource: IResourcesInitialState,
  companyId: string,
  creatorId: string
) => {
  let _resource: IResourcesAddViewModel = {
    name: stateResource.name,
    email: stateResource.email,
    resourceTypeId: stateResource.resourceTypeId,
    companyId: companyId,
    departmentId: stateResource.departmentId,
    contactId: stateResource.contact?.id || null,
    creatorId: creatorId,
    companyProducts: stateResource.companyProducts,
    storageProducts: stateResource.storageProducts,
    productCatalog: stateResource.productCatalog,
    unassignedCompanyProducts: stateResource.unassignedCompanyProducts,
    isActive: true,
    legacyId: stateResource.legacyId,
    thirdPartyId: stateResource.thirdPartyId,
    thirdParty: stateResource.thirdParty,
  };
  return _resource;
};

const ConvertToUpdateResource = (
  stateResource: IResourcesInitialState,
  companyId: string,
  resourceId: string
) => {
  let _resource: IResourcesUpdateViewModel = {
    id: resourceId || 'new',
    name: stateResource.name,
    email: stateResource.email,
    resourceTypeId: stateResource.resourceType.id,
    companyId: companyId,
    departmentId: stateResource.departmentId,
    contactId: stateResource.contact.id || 'new',
    companyProducts: stateResource.companyProducts,
    storageProducts: stateResource.storageProducts,
    productCatalog: stateResource.productCatalog,
    unassignedCompanyProducts: stateResource.unassignedCompanyProducts,
    isActive: true,
    legacyId: stateResource.legacyId,
    thirdPartyId: stateResource.thirdPartyId,
    thirdParty: stateResource.thirdParty,
    isValid: stateResource.isValid
  };
  return _resource;
};

const validateFieldWithSchema = async (field: keyof IResourcesInitialState, value: any) => {
  try {
    await schema.validateAt(field, { [field]: value });
    return true;
  } catch {
    return false;
  }
};


const isButtonDisabled = async (state: IResourcesInitialState) => {
  const isEmailValid = await validateFieldWithSchema("email", state.email);
  const isNameValid = await validateFieldWithSchema("name", state.name);

  const isFormValid = 
    isNameValid &&
    state.departmentId !== '0' &&
    isEmailValid &&
    state.contactId !== 'new';

  return !isFormValid;
};


const initialState: IResourcesInitialState = {
  id: 'new',
  name: '',
  email: '',
  resourceTypeId: null,
  resourceType: {} as unknown as IResourceTypeViewModel,
  company: {} as unknown as ICompanyViewModel,
  companyId: 'new',
  contact: {} as unknown as IEmployeeViewModel,
  contactId: 'new',
  departmentId: '0',
  isActive: true,
  department: {} as IDepartmentViewModel,
  companyProducts: [] as ICompanyProductViewModel[],
  productCatalog: [] as ICompanyProductViewModel[],
  storageProducts: [] as ICompanyProductViewModel[],
  unassignedCompanyProducts: [] as ICompanyProductViewModel[],
  isDirty: false,
  isOnboardingEnabled: false,
  legacyId: 0,
  thirdPartyId: '',
  thirdParty: ThirdPartEnum.None,
  isValid: false,

};

type Action =
  | { type: 'UPDATE_RESOURCE'; payload: IResourcesViewModel }
  | { type: 'UPDATE_RESOURCE_TYPE'; payload: IResourceTypeViewModel }
  | { type: 'VALIDATE' }
  | { type: 'UPDATE_FIELD'; field: string; value: any }
  | { type: 'UPDATE_NAME'; payload: string }
  | { type: 'SET_EMAIL'; payload: string }
  | { type: 'SET_DIRTY'; payload: boolean }
  | { type: 'TOGGLE_ONBOARDING_ENABLED'; payload: boolean }
  | { type: 'CHANGE_DEPARTMENT'; payload: IDepartmentViewModel }
  | { type: 'UPDATE_FIELD'; field: keyof IResourcesViewModel; value: any }
  | { type: 'SET_CONTACT'; payload: IEmployeeViewModel }
  | { type: 'ADD_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_UNASSIGN_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | {
      type: 'REMOVE_UNASSIGN_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | {
      type: 'UPDATE_UNASSIGN_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | { type: 'CHANGE_CONTACT'; payload: IEmployeeViewModel }

const reducer = (
  state: IResourcesInitialState,
  action: Action
): IResourcesInitialState => {
  switch (action.type) {
    case 'UPDATE_RESOURCE':
      //console.log('UPDATE_RESOURCE', action.payload);
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        contact: action.payload.contact,
        contactId: action.payload.contactId,
        email: action.payload.email,
        resourceType: action.payload.resourceType,
        resourceTypeId: action.payload.resourceType.id,
        departmentId: action.payload.departmentId,
        department: action.payload.department,
        companyProducts: !!action.payload.companyProducts
          ? action.payload.companyProducts
          : [],
        storageProducts: [],
        productCatalog: [],
        unassignedCompanyProducts: [],
      };
    case 'TOGGLE_ONBOARDING_ENABLED':
      return { ...state, isOnboardingEnabled: action.payload };
    case 'SET_DIRTY':
      console.log('FORM IS DIRTY');
      return {
        ...state,
        isDirty: action.payload,
      };
    case 'UPDATE_NAME':
      return {
        ...state,
        name: action.payload,
      };
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.payload,
      };
    case 'UPDATE_RESOURCE_TYPE':
      console.log('RESOURCE TYPE CONSOLE LOG', action.payload);
      return {
        ...state,
        resourceTypeId: action.payload.id,
        resourceType: action.payload,
      };
    case 'UPDATE_FIELD':
      return { ...state, [action.field]: action.value };
    case 'SET_CONTACT':
      return {
        ...state,
        contact: action.payload,
        contactId: action.payload?.id ?? 'new',
      };
    case 'CHANGE_CONTACT':
      return {
        ...state,
        contact: action.payload,
        contactId: action.payload?.id ?? 'new',
        isDirty: true,
      };
    case 'CHANGE_DEPARTMENT':
      return {
        ...state,
        departmentId: action.payload.id,
        department: action.payload,
      };
    case 'ADD_PRODUCT':
      //  console.log('ADD_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: [...state.productCatalog, action.payload],
      };
    case 'REMOVE_PRODUCT':
      //console.log('REMOVE_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: state.productCatalog.filter(
          (product: any) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_PRODUCT':
      //console.log('UPDATE_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: state.productCatalog.map((product: any) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_STORAGE_PRODUCT':
      //console.log('ADD_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: [...state.storageProducts, action.payload],
      };
    case 'REMOVE_STORAGE_PRODUCT':
      //console.log('REMOVE_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: state.storageProducts.filter(
          (product: any) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_STORAGE_PRODUCT':
      //console.log('UPDATE_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: state.storageProducts.map((product: any) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_COMPANY_PRODUCT':
      //('ADD_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: [...state.companyProducts, action.payload],
      };
    case 'REMOVE_COMPANY_PRODUCT':
      //console.log('REMOVE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: state.companyProducts.filter(
          (product: any) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_COMPANY_PRODUCT':
      //console.log('UPDATE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: state.companyProducts.map((product: any) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_UNASSIGN_COMPANY_PRODUCT':
      //console.log('ADD_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: [
          ...state.unassignedCompanyProducts,
          action.payload,
        ],
      };
    case 'REMOVE_UNASSIGN_COMPANY_PRODUCT':
      //console.log('REMOVE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.filter(
          (product: any) => product.id !== action.payload.id
        ),
      };
      case 'UPDATE_FIELD':
        return { ...state, [action.field]: action.value, isDirty: true };
        case 'VALIDATE':
          try {
            schema.validateSync(state, { abortEarly: false });
            return { ...state, isValid: true};
          } catch (error) {
            const errors: FormErrors = {};
            if (error instanceof yup.ValidationError) {
              error.inner.forEach((err) => {
                if (err.path) {  // Ensure err.path is defined
                  errors[err.path] = err.message;
                }
              });
            }
            return { ...state, isValid: false};
          }
    case 'UPDATE_UNASSIGN_COMPANY_PRODUCT':
    default:
      return state;
  }
};

export interface FormErrors {
  name?: string;
  email?: string;
  [key: string]: string | undefined; // For additional fields if needed
}
type Props = {
  resource: IResourcesViewModel;
  objectDrawerList: objectArray[];
  setIsObjectList: (value: boolean) => void;
};

export const ResourceForm = ({
  resource,
  objectDrawerList,
  setIsObjectList,
}: Props) => {
  const { t } = useTranslation();

  /* INITIALIZATIONS */
  const { width, height } = useWindowsDimension();
  let _ = require('lodash');
  const { companyId, resourceId } = useParams() as any | undefined;
  const [user] = useAtom(userAtom);
  const [isDisabled, setIsDisabled] = useState(true);
  const [errors, setErrors] = useState<FormErrors>({});

  // states
  const [state, dispatch] = useReducer(reducer, initialState);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  // api
  const isEnabled = true;
  const postResources = usePost(rqPost.resources(isEnabled));
  const putResources = usePut(rqPost.resources(isEnabled));


  // handles
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    validateField(name as keyof IResourcesViewModel, value);
  };
  const handleConfirm = (event: any) => {
    //console.log("delete");
  };

  const handleChange = async (
    e: any,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    const { name, value } = e.target;
    dispatch({ type: 'SET_DIRTY', payload: true });

    switch (objectType) {
      case EnumObjectType.Product:
        let product = ConvertProductToCompanyProduct(
          selectedObject as IBillingProductViewModel
        );
        if (
          state.isOnboardingEnabled &&
          product.product &&
          product.product.paymentCycle === 0
        ) {
          product.retailPrice = 0; // Set the price to 0 only if onboarding is enabled
        }
        dispatch({
          type: 'ADD_PRODUCT',
          payload: product as ICompanyProductViewModel,
        });
        break;
      case EnumObjectType.CompanyProduct:
        dispatch({
          type: 'ADD_COMPANY_PRODUCT',
          payload: selectedObject as ICompanyProductViewModel,
        });
        break;
      case EnumObjectType.unassignedCompanyProduct:
        dispatch({
          type: 'ADD_UNASSIGN_COMPANY_PRODUCT',
          payload: selectedObject as ICompanyProductViewModel,
        });
        break;
      case EnumObjectType.StorageProduct:
        let storageProduct = ConvertStorageProductToCompanyProduct(
          selectedObject as IStorageProductViewModel
        );
        dispatch({
          type: 'ADD_STORAGE_PRODUCT',
          payload: storageProduct as ICompanyProductViewModel,
        });
        break;
      case EnumObjectType.Package:
        let packageProducts = selectedObject.productPackageProducts.map(
          (packageProduct: ICompanyProductViewModel) => {
            let newProduct = { ...packageProduct };
            newProduct.retailPrice = packageProduct.product.retailPrice;
            newProduct.serviceTag = '';
            return newProduct;
          }
        );
        packageProducts.forEach((product: ICompanyProductViewModel) => {
          dispatch({
            type: 'ADD_PRODUCT',
            payload: product as ICompanyProductViewModel,
          });
          dispatch({
            type: 'SET_DIRTY',
            payload: true,
          });
        });
        break;
    }
   
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: 'UPDATE_FIELD', field: name, value: value });
    dispatch({ type: 'VALIDATE' });
  };

  const handleChangeEmployee = (e: any, employee: IEmployeeViewModel) => {
    e.preventDefault();
    dispatch({
      type: 'SET_CONTACT',
      payload: employee,
    });
    dispatch({
      type: 'SET_DIRTY',
      payload: true,
    });

    //setIsObjectList(true);
  };

  const handleRemoveProduct = (
    companyProduct: ICompanyProductViewModel,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.Product:
        dispatch({ type: 'REMOVE_PRODUCT', payload: companyProduct });
        dispatch({
          type: 'SET_DIRTY',
          payload: false,
        });
        break;
      case EnumObjectType.unassignedCompanyProduct:
        dispatch({
          type: 'REMOVE_UNASSIGN_COMPANY_PRODUCT',
          payload: companyProduct,
        });
        dispatch({
          type: 'SET_DIRTY',
          payload: false,
        });
        break;
      case EnumObjectType.CompanyProduct:
        if (companyProduct.resourceId) {
          // Utfører første dispatch-kall
          dispatch({
            type: 'UPDATE_COMPANY_PRODUCT',
            payload: {
              ...companyProduct,
              resourceId: null,
              resource: null,
              placement: EnumPlacement.NONE,
            },
          });
          // Utfører andre dispatch-kall
          dispatch({
            type: 'SET_DIRTY',
            payload: true,
          });
        } else {
          // Utfører dispatch-kall hvis betingelsen ikke er oppfylt
          dispatch({
            type: 'UPDATE_COMPANY_PRODUCT',
            payload: {
              ...companyProduct,
              resourceId: state.id,
              placement: EnumPlacement.RESOURCE,
            },
          });
          // Utfører andre dispatch-kall
          dispatch({
            type: 'SET_DIRTY',
            payload: false,
          });
        }
        break;

      case EnumObjectType.StorageProduct:
        dispatch({
          type: 'REMOVE_STORAGE_PRODUCT',
          payload: companyProduct,
        });
        dispatch({
          type: 'SET_DIRTY',
          payload: false,
        });
        break;
    }
  };

  const handleDepartmentChange = (e: any) => {
    const selectedValue = e.target.value;
    //console.log('handleDepartmentChange', e.target.value);
    if (!selectedValue || selectedValue === '0') {
      console.error('Invalid value received from Select');
      return;
    }
    const selectedDepartmentId = selectedValue.toString();
    const selectedDepartment = objectDrawerList[
      EnumObjectType.Department
    ].objectList.find(
      (dep: IDepartmentViewModel) => dep.id === selectedDepartmentId
    );

    if (selectedDepartment) {
      dispatch({
        type: 'CHANGE_DEPARTMENT',
        payload: selectedDepartment,
      });
    }
  };
  type FormOrMouseEvent = React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLDivElement, MouseEvent>;

  const handleChangeResourceType = (
    event: FormOrMouseEvent,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.ResourceTypes:
        let object = selectedObject as IResourceTypeViewModel;
        dispatch({ type: 'UPDATE_RESOURCE_TYPE', payload: object });
        /*   dispatch({ type: 'VALIDATE' }); */
        dispatch({
          type: 'SET_DIRTY',
          payload: true,
        });
        break;
      default:
        break;
    }
  };

  const handleChangeProductData = (
    companyProduct: ICompanyProductViewModel,
    objectType: EnumObjectType
  ) => {
    console.log('handleChangeProductData', objectType, companyProduct);
    switch (objectType) {
      case EnumObjectType.Product:
        dispatch({ type: 'UPDATE_PRODUCT', payload: companyProduct });
        break;
      case EnumObjectType.CompanyProduct:
        dispatch({ type: 'UPDATE_COMPANY_PRODUCT', payload: companyProduct });
        break;
      case EnumObjectType.unassignedCompanyProduct:
        dispatch({
          type: 'UPDATE_UNASSIGN_COMPANY_PRODUCT',
          payload: companyProduct,
        });
        break;
      case EnumObjectType.StorageProduct:
        dispatch({
          type: 'UPDATE_STORAGE_PRODUCT',
          payload: companyProduct,
        });
        break;
    }
    console.log('handleChangeProductData - x', state.productCatalog);
  };
  useEffect(() => {
    console.log('RESOURCES FORM STATE', state);
  }, [state]);

  useEffect(() => {
    if (resource) {
      dispatch({ type: 'UPDATE_RESOURCE', payload: resource });
    }
    //console.log('Resource =>', resource);
  }, [resource]);

  useEffect(() => {
    const checkIfButtonShouldBeDisabled = async () => {
      const result = await isButtonDisabled(state);
      setIsDisabled(result);
    };
  
    checkIfButtonShouldBeDisabled();
  }, [state]);

  const validateField = async (
    field: keyof IResourcesViewModel, 
    value: string
  ) => {
    try {
      await schema.validateAt(field, { [field]: value });
      setErrors((prev) => ({ ...prev, [field]: '' }));
    } catch (error: any) {
      if (error instanceof yup.ValidationError) {
        setErrors((prev) => ({ ...prev, [field]: error.message }));
      }
    }
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch({ type: 'VALIDATE' });
  
    if (state.isValid) {
      let _resources: IResourcesInitialState = _.cloneDeep(state);
      _resources.companyProducts.forEach((product: ICompanyProductViewModel) => {
        product.lastBilled = new Date();
      });
      if (resourceId === 'new') {
        let newResource = ConvertToAddResource(_resources, companyId, user.id);
        console.log('newResource', newResource);
        postResources.mutate(newResource, {
          onSuccess: (res: IResourcesViewModel) => {
            setIsObjectList(true);
          },
        });
      } else {
        let newResource = ConvertToUpdateResource(
          _resources,
          companyId,
          resourceId
        );
        putResources.mutate(newResource, {
          onSuccess: (res: IResourcesViewModel) => {
            setIsObjectList(true);
          },
        });
      }
    } else {
      console.log('Form is invalid');
    }
  };
  


  

  return (
    <Grid
      container
      sx={{
        m: 0,
        p: 0,
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {/*   HEADER   */}
      <Grid item xs={12} sx={{ m: 0, p: 0, mb: 2 }}>
        <FormBox Icon={FeedRoundedIcon} title={t('resources.detailsHeader')} />
      </Grid>
      {/*   NAME  */}
      <Grid
        item
        xs={6}
        sx={{
          m: 0,
          pr: 1,
          pt: 1,
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: 'normal',
          fontSize: 14,
          color: 'primary.text',
        }}
      >
        <TextField
        sx={{ '& .Mui-focused': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
        label={t('resources.name')}
        name="name"
        value={state.name}
        onChange={handleFieldChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        error={!!errors.name}
        helperText={errors.name}
      />
      </Grid>
      {/*EMAIL*/}
      <Grid
        item
        xs={6}
        sx={{
          m: 0,
          pl: 1,
          pt: 1,
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: 'normal',
          fontSize: 14,
          color: 'primary.text',
        }}
      >
        <TextField
        sx={{ '& .Mui-focused': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
        label={t('resources.email')}
        name="email"
        value={state.email}
        onChange={handleFieldChange}
        onBlur={handleBlur}
        error={!!errors.email}
        helperText={errors.email}
      />
      </Grid>

      {/*   DEPARTMENT   */}
      <Grid
        item
        xs={6}
        sx={{
          m: 0,
          mt: 1,
          pr: 1,
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: 'normal',
          fontSize: 14,
          color: 'primary.text',
        }}
      >
        <Typography> {t('resources.department')}</Typography>
        {true && (
          <Select
            sx={{ width: '100%', p: 0 }}
            labelId='department'
            id='department'
            variant='standard'
            value={state.departmentId ?? '0'}
            label='department'
            onChange={(e) => {
              handleDepartmentChange(e);
            }}
          >
            <MenuItem key={'0'} value={'0'}>
              {t('resources.chooseDepartment')}
            </MenuItem>
            {objectDrawerList[EnumObjectType.Department].objectList?.map(
              (department: IDepartmentViewModel) => (
                <MenuItem key={department.id} value={department.id}>
                  {department.name}
                </MenuItem>
              )
            )}
          </Select>
        )}
      </Grid>
      {/* RESOURCE TYPE OBJECT */}
      <Grid
        item
        xs={6}
        sx={{
          m: 0,
          pr: 1,
          pt: 2,
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: 'normal',
          fontSize: 14,
          color: 'primary.text',
        }}
      >
        {state.resourceType && state.resourceTypeId ? (
          <Grid
            container
            display={'flex'}
            flexDirection={'row'}
            sx={{
              width: '100%',
              height: 60,
              borderRadius: 2,
              backgroundColor: 'primary.main',
              pt: 3.4,
              ml: 1,
            }}
          >
            <Grid item sx={{ m: 0, p: 0, flexGrow: 1 }}>
              <Typography>{state.resourceType.name}</Typography>
            </Grid>
            <Grid item sx={{ m: 0, p: 0 }}>
              <Stack direction='row'>
                <Box
                  sx={{
                    mr: 1,
                    width: 30,
                    height: 30,
                    backgroundColor: 'primary.light',
                    display: 'flex', // <-- Set this to flex
                    justifyContent: 'center', // <-- This centers children horizontally
                    alignItems: 'center', // <-- This centers children vertically
                    borderRadius: 2,
                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                    '&:hover': {
                      filter: 'brightness(120%)',
                    },
                  }}
                >
                  <IconButton
                    sx={{ m: 0, p: 0 }}
                    onClick={(e) => {
                      dispatch({
                        type: 'UPDATE_RESOURCE_TYPE',
                        payload: {} as IResourceTypeViewModel,
                      });
                    }}
                  >
                    <DeleteForeverRoundedIcon />
                  </IconButton>
                </Box>
                <ObjectDrawer
                  buttonContext={t('resources.selectResourceType')}
                  buttonIcon={<ChangeCircleIcon />}
                  objectList={!!objectDrawerList ? objectDrawerList : []}
                  objectType={[EnumObjectType.ResourceTypes]}
                  handleChange={handleChangeResourceType}
                  buttonHeight={30}
                  buttonWidth={30}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sx={{ m: 0, p: 0 }}>
              <Divider sx={{ backgroundColor: 'primary.text' }} />
            </Grid>
          </Grid>
        ) : (
          objectDrawerList[Number(EnumObjectType.ResourceTypes)] && (
            <ObjectDrawer
              buttonContext={t('resources.selectResourceType')}
              buttonIcon={null}
              objectList={!!objectDrawerList ? objectDrawerList : []}
              objectType={[EnumObjectType.ResourceTypes]}
              handleChange={handleChangeResourceType}
              buttonHeight={60}
              buttonWidth={'100%'}
            />
          )
        )}
      </Grid>
      {/*   DEPARTMENT INFORMATION HEADER  */}
      <Grid item xs={12} sx={{ m: 0, p: 0, mb: 2, mt: 1 }}>
        <FormBox
          Icon={HomeRoundedIcon}
          title={t('resources.departmentInformation')}
        />
      </Grid>
      {/*   DEPARTMENT STREET  */}
      <Grid item xs={4} sx={{ pr: 1 }}>
        <Typography sx={{ height: 24 }}>
          {state.department?.mainAddress?.street}
        </Typography>
        <Divider sx={{ mt: 0 }} variant='dividerMenu' />
      </Grid>
      {/*   DEPARTMENT POSTAL CODE  */}
      <Grid item xs={4} sx={{ pl: 1, pr: 1 }}>
        <Typography sx={{ height: 24 }}>
          {state.department?.mainAddress?.postalCode}
        </Typography>
        <Divider sx={{ mt: 0 }} variant='dividerMenu' />
      </Grid>
      {/*   DEPARTMENT AREA  */}
      <Grid item xs={4} sx={{ pl: 1 }}>
        <Typography sx={{ height: 24 }}>
          {state.department?.mainAddress?.area}
        </Typography>
        <Divider sx={{ mt: 0 }} variant='dividerMenu' />
      </Grid>
      {/*   DEPARTMENT CITY  */}
      <Grid item xs={4} sx={{ pr: 1, pt: 2 }}>
        <Typography sx={{ height: 24 }}>
          {state.department?.mainAddress?.city}
        </Typography>
        <Divider sx={{ mt: 0 }} variant='dividerMenu' />
      </Grid>
      {/*   DEPARTMENT COUNTRY  */}
      <Grid item xs={4} sx={{ pl: 1, pr: 1, pt: 2 }}>
        <Typography sx={{ height: 24 }}>
          {state.department?.mainAddress?.country}
        </Typography>
        <Divider sx={{ mt: 0 }} variant='dividerMenu' />
      </Grid>
      {/*   DEPARTMENT POSTBOX  */}
      <Grid item xs={4} sx={{ pl: 1, pt: 2, mb: 2 }}>
        <Typography sx={{ height: 24 }}>
          {state.department?.mainAddress?.postBox}
        </Typography>
        <Divider sx={{ mt: 0 }} variant='dividerMenu' />
      </Grid>
      {/*   CONTACT PERSON HEADER */}
      <Grid item xs={12} sx={{ m: 0, p: 0, mb: 2 }}>
        <FormBox
          Icon={PersonSearchRoundedIcon}
          title={t('resources.contactPerson')}
          FormBTN={
            <ObjectDrawer
              buttonContext={t('resources.addContactPerson')}
              buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26, m: 0 }} />}
              objectList={!!objectDrawerList ? objectDrawerList : []}
              objectType={[EnumObjectType.CompanyEmployees]}
              handleChange={handleChangeEmployee}
              buttonHeight={30}
              buttonWidth={30}
            />
          }
        />
      </Grid>
      {/* CONTACT PERSON */}
      <Grid item xs={12} sx={{ p: 0, mb: 2 }}>
        {!!state.contact ? (
          <Stack direction='column'>
            <SingleEmployeeView employee={state.contact} height={80} />
          </Stack>
        ) : (
          <ObjectDrawer
            buttonContext={t('resources.addContactPerson')}
            buttonIcon={null}
            objectList={!!objectDrawerList ? objectDrawerList : []}
            objectType={[EnumObjectType.CompanyEmployees]}
            handleChange={handleChangeEmployee}
            buttonHeight={82}
            buttonWidth={'100%'}
          />
        )}
      </Grid>
      {/* PRODUCT HEADER */}
      <Grid item xs={12} sx={{ m: 0, p: 0, mb: 2 }}>
        <FormBox
          Icon={DevicesOtherRoundedIcon}
          title={t('resources.products')}
          FormBTN={
            <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
              <Grid display={'flex'} sx={{ mr: 2 }}>
                <Switch
                  checked={state.isOnboardingEnabled}
                  onChange={(e) =>
                    dispatch({
                      type: 'TOGGLE_ONBOARDING_ENABLED',
                      payload: e.target.checked,
                    })
                  }
                  name='enableOnboarding'
                  color='secondary'
                  sx={{}}
                />
                <Typography
                  sx={{
                    m: 0,
                    pt: 1,
                    pr: 0,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    fontSize: 14,
                    color: 'warning.text',
                  }}
                >
                  {t('switch.onboarding')}
                </Typography>
              </Grid>
              {state.isOnboardingEnabled ? (
                <ObjectDrawer
                  buttonContext={t('switch.onboarding')}
                  buttonIcon={<AddRoundedIcon sx={{ fontSize: 26 }} />}
                  objectList={objectDrawerList || []}
                  objectType={[
                    EnumObjectType.Product,
                    EnumObjectType.StorageProduct,
                    EnumObjectType.unassignedCompanyProduct,
                    EnumObjectType.Package,
                  ]}
                  handleChange={handleChange}
                  buttonHeight={26}
                  buttonWidth={26}
                />
              ) : (
                <Box
                  sx={{
                    width: 26,
                    height: 26,
                    backgroundColor: 'primary.light',
                    display: 'flex', // <-- Set this to flex
                    justifyContent: 'center', // <-- This centers children horizontally
                    alignItems: 'center', // <-- This centers children vertically
                    borderRadius: 2,
                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                  }}
                >
                  <IconButton
                    disabled={true}
                    sx={{
                      p: 0,
                      m: 0,
                      width: 26,
                      height: 26,
                      //color: 'secondary.light',
                      /*  '&:hover': {
              color: 'secondary.light'
            } */
                    }}
                    // onClick={() => setOpen(!open)}
                  >
                    <AddRoundedIcon sx={{ fontSize: 26 }} />
                  </IconButton>
                </Box>
              )}
            </Grid>
          }
        />
      </Grid>
      {/* PRODUCTS */}
      <Grid item xs={12} sx={{ m: 0, p: 0, mb: 2 }}>
        <List
          sx={{
            width: '100%',
            height: Math.ceil(height - 742),
            //p: 2,
            overflow: 'auto',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
        >
          {state?.companyProducts?.map(
            (companyProduct: ICompanyProductViewModel, index: number) => {
              return !!companyProduct ? (
                <ListItem sx={{ mb: 1, pl: 1, pr: 1 }} key={index * 10}>
                  <ProductCardView
                    companyProduct={companyProduct}
                    location={EnumObjectType.Resource}
                    objectType={EnumObjectType.CompanyProduct}
                    handleRemoveProduct={handleRemoveProduct}
                    height={80}
                    handleChange={handleChangeProductData}
                  />
                </ListItem>
              ) : null;
            }
          )}
          {state?.unassignedCompanyProducts?.map(
            (companyProduct: ICompanyProductViewModel, index: number) => {
              return !!companyProduct ? (
                <ListItem sx={{ mb: 1, p: 0 }} key={index * 100}>
                  <ProductCardView
                    companyProduct={companyProduct}
                    location={EnumObjectType.Resource}
                    objectType={EnumObjectType.unassignedCompanyProduct}
                    handleRemoveProduct={handleRemoveProduct}
                    handleChange={handleChangeProductData}
                  />
                </ListItem>
              ) : null;
            }
          )}
          {state?.storageProducts?.map(
            (companyProduct: ICompanyProductViewModel, index: number) => {
              return !!companyProduct ? (
                <ListItem key={index * 1000} sx={{ mb: 1, p: 0 }}>
                  <ProductCardView
                    companyProduct={companyProduct}
                    location={EnumObjectType.Resource}
                    objectType={EnumObjectType.StorageProduct}
                    handleRemoveProduct={handleRemoveProduct}
                    handleChange={handleChangeProductData}
                  />
                </ListItem>
              ) : null;
            }
          )}
          {state?.productCatalog?.map(
            (companyProduct: ICompanyProductViewModel, index: number) => {
              return !!companyProduct ? (
                <ListItem sx={{ mb: 1, p: 0 }} key={index * 10000}>
                  <ProductCardView
                    companyProduct={companyProduct}
                    location={EnumObjectType.Resource}
                    objectType={EnumObjectType.Product}
                    handleRemoveProduct={handleRemoveProduct}
                    handleChange={handleChangeProductData}
                  />
                </ListItem>
              ) : null;
            }
          )}
        </List>
      </Grid>
      {/*   FOOTER   */}
      <Grid item xs={12} sx={{ m: 0, p: 0, mb: 2 }}>
        <Grid
          item
          sx={{
            //   m: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: 42.6,
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Button
              sx={{ mr: 2 }}
              disabled={resourceId === 'new' ? true : false}
              variant='contained'
              onClick={() => setDeleteConfirm(!deleteConfirm ? true : false)}
            >
              {t('button.delete')}
            </Button>
            {!!deleteConfirm === true && (
              <Button
                sx={{ justifySelf: 'flex-start' }}
                variant='contained'
                color='secondary'
                onClick={handleConfirm}
              >
                {t('button.confirmDelete')}
              </Button>
            )}
          </Box>

          <Button type="submit" variant="contained" disabled={isDisabled} onClick={onSubmit}>
          {resourceId === 'new' ? t('button.create') : t('button.update')}
        </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
