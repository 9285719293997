import dayjs from 'dayjs';
import { t } from 'i18next';
import i18n from 'languages/i18n';

export interface IModule {
  id: number;
  name: string;
  description: string;
  isOwned: boolean;
  price: number;
  expires: string;
  bindingPeriod: number;
}

export const modulesAvailable = [
  {
    id: 1,
    name: i18n.t('modules.cloudFactory.name'),
    description: i18n.t('modules.cloudFactory.descriptionInfo'),
    isOwned: true,
    price: 1490,
    expires: dayjs().add(7, 'month').format('DD-MM-YYYY'),
    bindingPeriod: 12,
  },
  {
    id: 2,
    name: 'Modul 2',
    description: 'Dette er modul 2',
    isOwned: false,
    price: 1490,
    expires: dayjs().add(7, 'month').format('DD-MM-YYYY'),
    bindingPeriod: 12,
  },
  {
    id: 3,
    name: 'Modul 3',
    description: 'Dette er modul 3',
    isOwned: false,
    price: 1490,
    expires: dayjs().add(7, 'month').format('DD-MM-YYYY'),
    bindingPeriod: 12,
  },
] as IModule[];
