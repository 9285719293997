import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { Container, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { MultiBarView } from 'components/ui/Charts/MultiBarView';
import useWindowsDimension from 'functions/useWindowsDimension';
import { dummyMultiChartBar1 } from 'components/ui/Charts/Components/YearChartDummyData';
import axios from 'axios';

export const Test = () => {
  const { width, height } = useWindowsDimension();

  const parentRef = useRef<HTMLDivElement>(null); // Spesifiserer HTMLDivElement som type for ref
  const [parentWidth, setParentWidth] = useState(0);

  useLayoutEffect(() => {
    const updateParentWidth = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    };

    updateParentWidth(); // Oppdater ved mount
    window.addEventListener('resize', updateParentWidth); // Oppdater ved vindu endres

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);

  interface DataType {
    id: number;
    name: string;
    // Legg til flere felter basert på dataene du henter
  }
  
    const [data, setData] = useState<DataType[]>([]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get<any[]>('https://www.dustinhome.no/product/5020000831/thinkbook-16-g6');
          setData(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []);
  
console.log('data:', data);
  return (
    <Grid
      container
      display={'flex'}
      direction={'row'}
      sx={{ m: 0, p: 0, height: height - 28, backgroundColor: 'primary.back' }}
    >
      <Grid ref={parentRef} xs={6} sx={{ pr: 0.5 }}>
        <MultiBarView
          data={dummyMultiChartBar1}
          width={parentWidth}
          height={350}
        />
      </Grid>
      <Grid xs={6} sx={{ pl: 0.5 }}>
        <MultiBarView
          data={dummyMultiChartBar1}
          width={parentWidth}
          height={350}
        />
      </Grid>

      <Container>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            {/* Legg til flere kolonner basert på dataene */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.id}</TableCell>
              <TableCell>{item.name}</TableCell>
              {/* Legg til flere celler basert på dataene */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>

    </Grid>
  );
};