import { LogLevel } from '@azure/msal-browser';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const itsid = '43a1f395-3dcc-4f93-9b5f-64d16474421c';

const environment = process.env.REACT_APP_ENV || process.env.NODE_ENV;
//console.log('environment', environment);

let baseUri = 'https://bluu.ccsolution.no'; // default to production

if (environment === 'development') {
  baseUri = 'http://localhost:3000';
} else if (environment === 'test') {
  baseUri = 'http://test.bluu.ai'; // or whatever your test URI is
}

// authority: "https://login.microsoftonline.com/43831993-6193-4999-9fd2-50e9fa682c56",
// redirectUri: "http://localhost:3000/blank.html",
export const itsConfig = {
  auth: {
    clientId: itsid,
    authority:
      'https://login.microsoftonline.com/43831993-6193-4999-9fd2-50e9fa682c56',
    redirectUri: `${baseUri}/blank.html`,
    postLogoutRedirectUri: baseUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            //    console.error(message);
            return;
          case LogLevel.Info:
            //   console.info(message);
            return;
          case LogLevel.Verbose:
            //  console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
/* export const loginRequest = {
  scopes: ["User.Read"],
}; */
export const itsRequest = {
  scopes: ['43a1f395-3dcc-4f93-9b5f-64d16474421c/.default'],
};

// Add here the endpoints for MS Graph API services you would like to use.
/* export const graphConfig = {
  me: 'https://graph.microsoft.com/v1.0/me',
  companies: 'https://its-staging-admin.azurewebsites.net/api/Company',
}; */
