import { Button, Grid, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { IBudgetSelectedMonth } from './BudgetConfig';
import { formatValue } from 'functions/formatValue';
import * as yup from 'yup';

type Action =
  | { type: 'SET_BUDGET'; payload: { budget: IBudgetSelectedMonth } }
  | { type: 'SELECT_MONTH_SUM'; payload: { initialValue: number } }
  | { type: 'SET_SELECTED_MONTH'; payload: { month: string } };

interface SingleMonthBudgetViewProps {
  budgetData: IBudgetSelectedMonth;
  dispatch: React.Dispatch<Action>;
}

const schema = yup.object().shape({
  fillValue: yup.number().required('Initial value is required').min(0, 'Value must be positive'),
  fillPercentageValue: yup.number().required('Percentage value is required').min(0, 'Value must be positive').max(100, 'Value cannot exceed 100'),
  jan: yup.number().required('Value is required').min(0, 'Value must be positive'),
  feb: yup.number().required('Value is required').min(0, 'Value must be positive'),
  mar: yup.number().required('Value is required').min(0, 'Value must be positive'),
  apr: yup.number().required('Value is required').min(0, 'Value must be positive'),
  may: yup.number().required('Value is required').min(0, 'Value must be positive'),
  jun: yup.number().required('Value is required').min(0, 'Value must be positive'),
  jul: yup.number().required('Value is required').min(0, 'Value must be positive'),
  aug: yup.number().required('Value is required').min(0, 'Value must be positive'),
  sep: yup.number().required('Value is required').min(0, 'Value must be positive'),
  oct: yup.number().required('Value is required').min(0, 'Value must be positive'),
  nov: yup.number().required('Value is required').min(0, 'Value must be positive'),
  dec: yup.number().required('Value is required').min(0, 'Value must be positive')
});

const monthKeyMapping = {
  Jan: 'jan',
  Feb: 'feb',
  Mar: 'mar',
  Apr: 'apr',
  May: 'may',
  Jun: 'jun',
  Jul: 'jul',
  Aug: 'aug',
  Sep: 'sep',
  Okt: 'oct',
  Nov: 'nov',
  Dec: 'dec',
} as const;

export const SingleMonthBudgetView: React.FC<SingleMonthBudgetViewProps> = ({ budgetData, dispatch }) => {
  const [fillValue, setFillValue] = useState<number>(0);
  const [fillPercentageValue, setFillPercentageValue] = useState<number>(0);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const months = [
    t('Settings.Budget.SingleMonthBudgetView.Jan'),
    t('Settings.Budget.SingleMonthBudgetView.Feb'),
    t('Settings.Budget.SingleMonthBudgetView.Mar'),
    t('Settings.Budget.SingleMonthBudgetView.Apr'),
    t('Settings.Budget.SingleMonthBudgetView.May'),
    t('Settings.Budget.SingleMonthBudgetView.Jun'),
    t('Settings.Budget.SingleMonthBudgetView.Jul'),
    t('Settings.Budget.SingleMonthBudgetView.Aug'),
    t('Settings.Budget.SingleMonthBudgetView.Sep'),
    t('Settings.Budget.SingleMonthBudgetView.Okt'),
    t('Settings.Budget.SingleMonthBudgetView.Nov'),
    t('Settings.Budget.SingleMonthBudgetView.Dec'),
  ];

  const validateField = async (field: string, value: any) => {
    try {
      await schema.validateAt(field, { [field]: value });
      setErrors((prev: any) => ({ ...prev, [field]: undefined }));
    } catch (error: any) {
      setErrors((prev) => ({ ...prev, [field]: error.message }));
    }
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, selectedMonth: string) => {
    const { name, value } = e.target;
    const numericValue = Number(value.replace(/\D/g, '')); // Remove non-digit characters
    validateField(monthKeyMapping[selectedMonth as keyof typeof monthKeyMapping], numericValue);

    const monthKey = monthKeyMapping[selectedMonth as keyof typeof monthKeyMapping];
    if (monthKey) {
      budgetData[monthKey].value = numericValue;
      dispatch({ type: 'SET_BUDGET', payload: { budget: budgetData } });
      dispatch({ type: 'SET_SELECTED_MONTH', payload: { month: selectedMonth } });
    }
  };

  const handleFillValueYear = () => {
    let year = fillValue / 12;
    Object.keys(monthKeyMapping).forEach(month => {
      budgetData[monthKeyMapping[month as keyof typeof monthKeyMapping]].value = year;
    });
    dispatch({ type: 'SET_BUDGET', payload: { budget: budgetData } });
  };

  const handleFillValueMonth = () => {
    let monthValue = fillValue;
    Object.keys(monthKeyMapping).forEach(month => {
      budgetData[monthKeyMapping[month as keyof typeof monthKeyMapping]].value = monthValue;
    });
    dispatch({ type: 'SET_BUDGET', payload: { budget: budgetData } });
  };

  const handleFillPercentage = () => {
    if (fillPercentageValue === 0) return;

    let percentage = (fillValue * fillPercentageValue) / 100;

    Object.keys(monthKeyMapping).forEach(month => {
      budgetData[monthKeyMapping[month as keyof typeof monthKeyMapping]].value += percentage;
    });

    dispatch({ type: 'SET_BUDGET', payload: { budget: budgetData } });
  };

  const handleDecreasePercentage = () => {
    if (fillPercentageValue === 0) return;

    let percentage = (fillValue * fillPercentageValue) / 100;

    Object.keys(monthKeyMapping).forEach(month => {
      budgetData[monthKeyMapping[month as keyof typeof monthKeyMapping]].value -= percentage;
    });

    dispatch({ type: 'SET_BUDGET', payload: { budget: budgetData } });
  };

  const handleReset = () => {
    setFillPercentageValue(0);
    setFillValue(0);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select(); // Select the entire value
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>, selectedMonth: string) => {
    const value = Number(e.target.value.replace(/\s+/g, '')); // Remove spaces before processing
    const numericValue = Math.floor(Number(value)); // Convert to a number and floor it

    const monthKey = monthKeyMapping[selectedMonth as keyof typeof monthKeyMapping];
    if (monthKey) {
      budgetData[monthKey].value = numericValue;
      dispatch({ type: 'SET_BUDGET', payload: { budget: budgetData } });
      dispatch({ type: 'SET_SELECTED_MONTH', payload: { month: selectedMonth } });
    }
  };

  return (
    <Grid container sx={{ display: "flex", flexDirection: "row", p: 1 }}>
      <Typography sx={{ mb: 1 }}>{budgetData.name}</Typography>
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          name='fillValue'
          sx={{
            '& .Mui-focused': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
            mb: 1,
            mr: 1,
            width: '20%'
          }}
          label="Initial Value"
          value={fillValue}
          onChange={(e) => {
            const newValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
            const numericValue = newValue ? Number(newValue) : 0;
            setFillValue(numericValue); // Update state only with numeric value
            validateField('fillValue', numericValue);
          }}
          onFocus={handleFocus}
          error={Boolean(errors.fillValue)}
          helperText={errors.fillValue}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
        <Grid item sx={{ mb: 1, flexGrow: 1 }}>
          <Button variant='contained' sx={{ mr: 1, backgroundColor: "primary.light" }} onClick={handleFillValueYear}>Split on year</Button>
          <Button variant='contained' sx={{ mr: 5, backgroundColor: "primary.light" }} onClick={handleFillValueMonth}>Every month</Button>
        </Grid>
        <TextField
          name='fillPercentageValue'
          sx={{
            '& .Mui-focused': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
            mb: 1,
            width: '20%'
          }}
          label="Enter Percentage"
          value={fillPercentageValue}
          onChange={(e) => {
            let newValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
            newValue = newValue ? Math.min(Number(newValue), 100).toString() : ''; // Cap the value at 100
            const numericValue = newValue ? Number(newValue) : 0;
            setFillPercentageValue(numericValue); // Update state only with numeric value
            validateField('fillPercentageValue', numericValue);
          }}
          onFocus={handleFocus}
          error={Boolean(errors.fillPercentageValue)}
          helperText={errors.fillPercentageValue}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
        <Grid item sx={{ mb: 1, flexGrow: 1 }}>
          <Button variant='contained' sx={{ backgroundColor: "primary.light", mr: 1 }} onClick={handleFillPercentage}>Increase %</Button>
          <Button variant='contained' sx={{ mr: 12, backgroundColor: 'primary.light' }} onClick={handleDecreasePercentage}>Decrease %</Button>
          <Button variant='contained' sx={{ backgroundColor: "secondary.main" }} onClick={handleReset}>Reset</Button>
        </Grid>
      </Grid>
      {months.map((month, index) => (
        <Grid item xs={1} sx={{ p: 0.5 }} key={index}>
          <TextField
            name={monthKeyMapping[month as keyof typeof monthKeyMapping]}
            sx={{
              '& .Mui-focused': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              },
            }}
            label={month}
            value={formatValue(budgetData[monthKeyMapping[month as keyof typeof monthKeyMapping]]?.value ?? 0, 0)}
            onChange={(e: any) => {
              const newValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
              handleFieldChange({ ...e, target: { ...e.target, value: newValue } }, month);
            }}
            onFocus={handleFocus}
            onBlur={(e: any) => handleBlur(e, month)}
            error={Boolean(errors[monthKeyMapping[month as keyof typeof monthKeyMapping]])}
            helperText={errors[monthKeyMapping[month as keyof typeof monthKeyMapping]]}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
