import { IStorageProductViewModel } from 'Interfaces/IStorageConfig';
import GenerateGuid from './GenerateGuid';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { IBillingProductViewModel } from 'Interfaces/BillingConfig';
import { EnumPlacement } from 'Interfaces/IGlobalTypeConfig';

export const ConvertStorageProductToCompanyProduct = (
  storageProduct: IStorageProductViewModel
): ICompanyProductViewModel => {
  return {
    id: storageProduct.id,
    serviceTag: storageProduct.resourceName || '',
    productId: storageProduct.product?.id || '',
    companyId: GenerateGuid(),
    purchaserId: GenerateGuid(),
    ownerId: null,
    owner: null,
    comment: '',
    lastBilled: null,
    productQuantity: 1,
    quantity: 1, //xxx
    unitPrice: storageProduct.unitPrice,
    discount: storageProduct.discount || 0,
    retailPrice: storageProduct.retailPrice,
    lifetime: storageProduct.product?.productType?.LifeExpectancy || 3, //xxx
    security: true, //xxx
    status: 0, //xxx
    productWarning: '',
    product: storageProduct.product,
    placement: EnumPlacement.EMPLOYEE,
    isActive: true,
    isBillable: true,
    serialNumber: storageProduct.serialNumber || '',
    resource: null,
    resourceId: null,
    toggleEdit: false,
    guaranteeEndDate: null,
    isDateEditable: false,
  };
};

export const ConvertProductToCompanyProduct = (
  productCatalog: IBillingProductViewModel
): ICompanyProductViewModel => {
  return {
    id: GenerateGuid(),
    serviceTag: '',
    productId: productCatalog?.id || '',
    companyId: GenerateGuid(),
    purchaserId: GenerateGuid(),
    ownerId: null,
    owner: null,
    comment: '',
    lastBilled: null,
    productQuantity: 1,
    quantity: 1, //xxx
    unitPrice: productCatalog.unitPrice,
    discount: 0,
    retailPrice: productCatalog.retailPrice,
    lifetime: productCatalog?.productType?.LifeExpectancy || 3, //xxx
    security: true, //xxx
    status: 0, //xxx
    productWarning: '',
    product: productCatalog,
    placement: EnumPlacement.EMPLOYEE,
    isActive: true,
    isBillable: true,
    serialNumber: productCatalog.serialNumber,
    resource: null,
    resourceId: null,
    toggleEdit: false,
    guaranteeEndDate: null,
    isDateEditable: false,
  };
};
