import * as React from 'react';
import { Grid, Typography, Paper} from '@mui/material';
import GetPriorityColor from 'functions/GetPriorityColor';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';

export const SingleTicketCompanyView = (props: any) => {
  const { company} = props;


  return (
    <Paper
      variant='elevation3'
      sx={{
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        borderColor: GetPriorityColor(company?.priority || 0),
        backgroundColor: 'primary.back',
        width: '100%',
        cursor: 'pointer',
      }}
    >
      <Grid container sx={{display: 'flex', flexDirection:'row'}}>
        <Grid
          item
          xs={4}
          sx={{
            width: '100%',
            height: 64,
            backgroundColor: 'primary.light',

          }}
        >
          <img
            src={company?.url || MissingPhoto.COMPANY_URL}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: 3,
              objectFit:'scale-down',
            }}
            alt={'logo'}
          />
        </Grid>

        <Grid
          item
          xs={8}
          sx={{
            pl: 1,
          }}
        >
            <Typography
              sx={{
                letterSpacing: 2,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: 14,
              }}
            >
              {company?.name} 
            </Typography>
            <Typography
              sx={{
                letterSpacing: 3,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 12,
              }}
            >
              {company?.organizationNumber} 
            </Typography>
            <Typography
              sx={{
                letterSpacing: 3,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 12,
              }}
            >
              {company?.email} 
            </Typography>
      </Grid>
      </Grid>
    </Paper>
  );
};
