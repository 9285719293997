import {
  ICompanyViewModel,
  IDepartmentViewModel,
  ThirdPartEnum,
} from 'Interfaces/ICompaniesConfig';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';
import {
  IResourceTypeViewModel,
  IResourcesViewModel,
} from 'Interfaces/IResourcesConfig';
import { IImportEmployeeAddViewModel } from './ICloudFactoryInterfaces';

export const ToResource = (
  employee: IImportEmployeeAddViewModel,
  company: ICompanyViewModel
): IResourcesViewModel => {
  console.log('NEW : newResource: c', employee);
  
  let name = '';
  if (employee?.name) {
    name = employee.name;
  } else if (employee?.email) {
    name = employee.email.split('@')[0];
  }
  let newResource: IResourcesViewModel = {
    id: 'new',
    name: name,
    resourceTypeId: null,
    resourceType: {} as unknown as IResourceTypeViewModel,
    email: employee.email,
    company: company ?? ({} as unknown as ICompanyViewModel),
    companyId: company?.id ?? 'new',
    contact: {} as unknown as IEmployeeViewModel,
    contactId: 'new',
    department: {} as unknown as IDepartmentViewModel,
    departmentId: 'new',
    companyProducts: [] as unknown as ICompanyProductViewModel[],
    productCatalog: [] as unknown as ICompanyProductViewModel[],
    storageProducts: [] as unknown as ICompanyProductViewModel[],
    unassignedCompanyProducts: [] as unknown as ICompanyProductViewModel[],
    legacyId: 0,
    thirdPartyId: '',
    thirdParty: ThirdPartEnum.CloudFactory,
  };
  console.log('NEW : newResource: c1', newResource);

  return newResource;
};
