import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import { FormBox } from 'components/form';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {
  EnumAddressType,
  IDeliveryAddressViewModel,
} from 'Interfaces/IGlobalTypeConfig';
import { FadingTypography } from 'functions/FadingTypography';
import { useTranslation } from 'react-i18next';


type Props = {
  addressList: IDeliveryAddressViewModel[];
  handleChange: (deliveryAddress: IDeliveryAddressViewModel) => void;
};
const initialState: IDeliveryAddressViewModel = {
  id: '0',
  addressId: '',
  addressType: EnumAddressType.custom,
  street: '',
  postalCode: '',
  area: '',
  city: '',
  country: '',
  postBox: '',
  attention: '',
};

export const DeliveryAddressView = ({ addressList, handleChange }: Props) => {
const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = useState<EnumAddressType>(
    EnumAddressType.custom
  );
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] =
    useState<IDeliveryAddressViewModel>(initialState);
  const [isFading, setIsFading] = useState(false);
  const isOwnerStreet = addressList[0]?.street !== '' ? true : false;

  const isCompanyStreet = addressList[1]?.street !== '' ? true : false;

  const isDepartmentStreet = addressList[2]?.street !== '' ? true : false;

  const isEmployeeStreet = addressList[3]?.street !== '' ? true : false;

  const handleChangeRadio = (event: any) => {
    event.preventDefault();
    setSelectedValue(event.target.value);
  };
  const handleChangeCustomRadio = (event: any) => {
    event.preventDefault();
    setSelectedValue(EnumAddressType.custom);
    setSelectedDeliveryAddress(addressList[4]);
  };
  const handleInputChange = (event: any) => {
    event.preventDefault();
    const { id, value } = event.target;
    //const updatedValue = id === 'postalCode' ? Number(value) : value;
    setSelectedDeliveryAddress((prevAddress) => ({
      ...prevAddress,
      [id]: value,
    }));
    setSelectedValue(EnumAddressType.custom);
  };
  const handleBlurInputChange = (event: any) => {
    event.preventDefault();
    const { id, value } = event.target;
    //const updatedValue = id === 'postalCode' ? Number(value) : value;
    setSelectedDeliveryAddress((prevAddress) => ({
      ...prevAddress,
      [id]: value,
    }));
    setSelectedValue(EnumAddressType.custom);
  };

  const isUpdateButtonDisabled = () => {
    if (
      selectedDeliveryAddress.street.length > 1 &&
      String(selectedDeliveryAddress.postalCode).length > 1 &&
      selectedDeliveryAddress.area.length > 1 &&
      selectedDeliveryAddress.city.length > 1 &&
      selectedDeliveryAddress.country.length > 1
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    // find selected address from addressList
    const deliveryAddress = addressList[Number(selectedValue)];
    // check if deliveryAddress is not null and has street
    if (deliveryAddress && deliveryAddress.street) {
      // set selected address
      setSelectedDeliveryAddress(deliveryAddress);
      // call handleChange function and return the address
      handleChange(deliveryAddress);
    } else {
      // if deliveryAddress is null or has no street, set selected address to initial state
      setSelectedDeliveryAddress(initialState);
      setSelectedValue(EnumAddressType.custom);
    }
  }, [selectedValue]);

  const triggerFadeIn = () => {
    setIsFading(true);

    // Optionally, reset the state after the animation duration
    setTimeout(() => {
      setIsFading(false);
    }, 2000); // Adjust this duration to match your fade-in duration
  };

  return (
    <Grid item xs={12} sx={{ p: 0 }}>
      <FormBox
        Icon={FeedRoundedIcon}
        title={t("DeliveryAddressView.deliveryAddress")}
        FormOBJ={
          <FormControlLabel
            sx={{
              width: 130,
              height: 30,
              backgroundColor: 'primary.back',
              borderRadius: 3,
              mt: 0,
              '& .MuiFormControlLabel-label': {
                fontSize: 14,
                letterSpacing: 1,
              },
              '& .Mui-checked + .MuiFormControlLabel-label': {
                color: 'secondary.text',
              },
            }}
            value={EnumAddressType.custom}
            control={
              <Radio
                sx={{
                  '&.Mui-checked': { color: 'secondary.light' },
                  m: 0,
                  p: 0.3,
                }}
                onChange={handleChangeCustomRadio}
                checked={selectedValue === EnumAddressType.custom}
              />
            }
            label={t("DeliveryAddressView.custom")}
          />
        }
        FormBTN={
          <Button
            sx={{ mt: -0.5 }}
            type='submit'
            variant='contained'
            onClick={() => {
              handleChange(selectedDeliveryAddress);
              triggerFadeIn();
            }}
            disabled={isUpdateButtonDisabled()}
          >
            {t("DeliveryAddressView.update")}
          </Button>
        }
        info={
          <FadingTypography
            text={t("DeliveryAddressView.addressUpdated")}
            from={0}
            to={1}
            isFading={isFading}
          />
        }
      />
  
      <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
        <Grid item xs={2} sx={{}}>
          <FormControl sx={{ p: 0, ml: 2 }}>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              value={selectedValue}
              onChange={handleChangeRadio}
              name='radio-buttons-group'
            >
              <FormControlLabel
                sx={{
                  width: '100%',
                  backgroundColor: 'primary.back',
                  borderRadius: 3,
                  mt: 0,
                  '& .MuiFormControlLabel-label': {
                    fontSize: 14,
                    letterSpacing: 1,
                  },
                  '& .Mui-checked + .MuiFormControlLabel-label': {
                    color: 'secondary.text',
                  },
                }}
                value={EnumAddressType.owner}
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': { color: 'secondary.light' },
                      m: 0,
                      p: 0.3,
                    }}
                    disabled={!isOwnerStreet}
                  />
                }
                label={t("DeliveryAddressView.ourStorage")}
              />
              <FormControlLabel
                sx={{
                  width: '100%',
                  backgroundColor: 'primary.back',
                  borderRadius: 3,
                  mt: 0.5,
                  '& .MuiFormControlLabel-label': {
                    fontSize: 14,
                    letterSpacing: 1,
                  },
                  '& .Mui-checked + .MuiFormControlLabel-label': {
                    color: 'secondary.text',
                  },
                }}
                value={EnumAddressType.company}
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': { color: 'secondary.light' },
                      m: 0,
                      p: 0.3,
                    }}
                    disabled={!isCompanyStreet}
                  />
                }
                label={t("DeliveryAddressView.company")}
              />
              <FormControlLabel
                sx={{
                  width: '100%',
                  backgroundColor: 'primary.back',
                  borderRadius: 3,
                  mt: 0.5,
                  '& .MuiFormControlLabel-label': {
                    fontSize: 14,
                    letterSpacing: 1,
                  },
                  '& .Mui-checked + .MuiFormControlLabel-label': {
                    color: 'secondary.text',
                  },
                }}
                value={EnumAddressType.department}
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': { color: 'secondary.light' },
                      m: 0,
                      p: 0.3,
                    }}
                    disabled={!isDepartmentStreet}
                  />
                }
                label={t("DeliveryAddressView.department")}
              />
              <FormControlLabel
                sx={{
                  width: '100%',
                  backgroundColor: 'primary.back',
                  borderRadius: 3,
                  mt: 0.5,
                  '& .MuiFormControlLabel-label': {
                    fontSize: 14,
                    letterSpacing: 1,
                  },
                  '& .Mui-checked + .MuiFormControlLabel-label': {
                    color: 'secondary.text',
                  },
                }}
                value={EnumAddressType.employee}
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': { color: 'secondary.light' },
                      m: 0,
                      p: 0.3,
                    }}
                    disabled={!isEmployeeStreet}
                  />
                }
                label={t("DeliveryAddressView.employee")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={7.5} sx={{ pl: 2, m: 0 }}>
          <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
            <Grid item xs={5} sx={{ pr: 2, pt: 1 }}>
              <InputLabel htmlFor='street' shrink={false}>
                {t("DeliveryAddressView.streetName")}
              </InputLabel>
              <TextField
                id='street'
                sx={{
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                value={selectedDeliveryAddress?.street}
                onChange={handleInputChange}
                onBlur={handleBlurInputChange}
              />
            </Grid>
  
            <Grid item xs={2} sx={{ pr: 2, pt: 1 }}>
              <InputLabel htmlFor='postalCode' shrink={false}>
                {t("DeliveryAddressView.postalCode")}
              </InputLabel>
              <TextField
                id='postalCode'
                sx={{
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                value={selectedDeliveryAddress?.postalCode}
                onChange={handleInputChange}
                onBlur={handleBlurInputChange}
              />
            </Grid>
  
            <Grid item xs={5} sx={{ pr: 2, pt: 1 }}>
              <InputLabel htmlFor='area' shrink={false}>
                {t("DeliveryAddressView.area")}
              </InputLabel>
              <TextField
                id='area'
                sx={{
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                value={selectedDeliveryAddress?.area}
                onChange={handleInputChange}
                onBlur={handleBlurInputChange}
              />
            </Grid>
  
            <Grid item xs={5} sx={{ pr: 2, pt: 1 }}>
              <InputLabel htmlFor='city' shrink={false}>
                {t("DeliveryAddressView.city")}
              </InputLabel>
              <TextField
                id='city'
                sx={{
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                value={selectedDeliveryAddress?.city}
                onChange={handleInputChange}
                onBlur={handleBlurInputChange}
              />
            </Grid>
  
            <Grid item xs={5} sx={{ pt: 1, pr: 2 }}>
              <InputLabel htmlFor='country' shrink={false}>
                {t("DeliveryAddressView.country")}
              </InputLabel>
              <TextField
                id='country'
                sx={{
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                value={selectedDeliveryAddress?.country}
                onChange={handleInputChange}
                onBlur={handleBlurInputChange}
              />
            </Grid>
  
            <Grid item xs={2} sx={{ pt: 1, pr: 2 }}>
              <InputLabel htmlFor='postbox' shrink={false}>
                {t("DeliveryAddressView.postbox")}
              </InputLabel>
              <TextField
                id='postbox'
                sx={{
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                value={selectedDeliveryAddress?.postBox}
                onChange={handleInputChange}
                onBlur={handleBlurInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2.5}>
          <TextField
            fullWidth
            label={t("DeliveryAddressView.attention")}
            variant='outlined'
            multiline
            rows={3.5} // Adjust the number of rows to control the height
            sx={{
              backgroundColor: 'primary.back',
            }} // Adjust width and add margin as needed
          />
        </Grid>
      </Grid>
    </Grid>
  );
  
};
