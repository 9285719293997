import * as React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import GetColorByName from 'functions/GetColorByName';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';

export const SingleTicketEmployeeView = (props: any) => {
  const { employee} = props;
  return (
    <Paper
      variant='elevation3'
      sx={{
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        borderColor: GetColorByName(`${employee?.department?.name}`),
        backgroundColor: 'primary.back',
        width: '100%',
        cursor: 'pointer',
      }}
    >
     <Grid container sx={{display: 'flex', flexDirection:'row'}}>
        <Grid
          item
          xs={2}
          sx={{
            width: '100%',
            height: 64,
            backgroundColor: 'primary.light',

          }}
        >
          <img
            src={employee?.url || MissingPhoto.EMPLOYEE_URL}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: 3,
              objectFit:'scale-down',
            }}
            alt={'previewUrl'}
          />
        </Grid>

        <Grid
            item
            xs={10}
            sx={{
              pl: 1,
            }}
        >
            <Typography
              sx={{
                letterSpacing: 2,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: 14,
              }}
            >
              {employee?.name}
            </Typography>
            <Typography
              sx={{
                letterSpacing: 3,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 12,
              }}
            >
              {employee?.phone}
            </Typography>
            <Typography
              sx={{
                letterSpacing: 3,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 12,
              }}
            >
              {employee?.email}
            </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
