import {
  IProductTypeViewModel,
  IBillingProductViewModel,
} from 'Interfaces/BillingConfig';
import DvrIcon from '@mui/icons-material/Dvr';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EngineeringIcon from '@mui/icons-material/Engineering';
import LivingIcon from '@mui/icons-material/Living';
import { ICompanyProductViewModel } from './ICompanyProductConfig';
import { IProductPackageViewModel } from './IPackageConfig';
import { IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { IEmployeeViewModel } from './IEmployeeConfig';
import { ICompanySimpleViewModel } from './ICompaniesConfig';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Link } from 'react-router-dom';
import { IProductVariantViewModel } from './IProductConfig';
import dayjs from 'dayjs';

export interface HeaderButtonInterface {
  name: string;
  component: { Link: typeof Link };
  to: string;
  icon: React.ElementType;
}
export enum EnumChartPeriod {
  week = 0,
  month = 1,
  quarter = 2,
  yearMonth = 3,
  yearQuarter = 4,
}

export enum EnumChartType {
  All = 0,
  Agreement = 1,
  Consultant = 2,
  Investment = 3,
  Other = 4,
}

export enum EnumObjectType {
  Company = 0,
  Department = 1,
  Resource = 2,
  CompanyEmployees = 3,
  Package = 4,
  CompanyProduct = 5,
  StorageProduct = 6,
  Product = 7,
  Ticket = 8,
  TicketPost = 9,
  WorkRole = 10,
  Vendor = 11,
  unassignedCompanyProduct = 12,
  allEmployees = 13,
  primaryContacts = 14,
  IssueType = 15,
  AppOwnerEmployees = 16,
  EmployeeProducts = 17,
  ProductCategories = 18,
  Brands = 19,
  SelectedCompany = 20,
  SelectedDepartment = 21,
  SelectedResource = 22,
  sharedProducts = 23,
  ResourceTypes = 24,
}
export const enumToStringMapping: Record<EnumObjectType, string> = {
  [EnumObjectType.Company]: 'Company',
  [EnumObjectType.Department]: 'Department',
  [EnumObjectType.Resource]: 'Resource',
  [EnumObjectType.CompanyEmployees]: 'Company Employees',
  [EnumObjectType.Package]: 'Package',
  [EnumObjectType.CompanyProduct]: 'Company Product',
  [EnumObjectType.StorageProduct]: 'Storage Product',
  [EnumObjectType.Product]: 'Product Catalog',
  [EnumObjectType.Ticket]: 'Ticket',
  [EnumObjectType.TicketPost]: 'TicketPost',
  [EnumObjectType.WorkRole]: 'WorkRole',
  [EnumObjectType.Vendor]: 'Vendor',
  [EnumObjectType.unassignedCompanyProduct]: 'Company Products',
  [EnumObjectType.allEmployees]: 'Employees',
  [EnumObjectType.primaryContacts]: 'Primary Contacts',
  [EnumObjectType.IssueType]: 'Issue Type',
  [EnumObjectType.AppOwnerEmployees]: 'Employees',
  [EnumObjectType.EmployeeProducts]: 'Employee Products',
  [EnumObjectType.ProductCategories]: 'Product Categories',
  [EnumObjectType.Brands]: 'Brands',
  [EnumObjectType.SelectedCompany]: 'Selected Company',
  [EnumObjectType.SelectedDepartment]: 'Selected Department',
  [EnumObjectType.SelectedResource]: 'Selected Resource',
  [EnumObjectType.sharedProducts]: 'Shared Products',
  [EnumObjectType.ResourceTypes]: 'Resource Types',
};

export const allObjectList: objectArray[] = [
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.Company,
    icon: DvrIcon,
    iconName: 'Company',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.Department,
    icon: LivingIcon,
    iconName: 'Departments',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.Resource,
    icon: LivingIcon,
    iconName: 'Resource',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.CompanyEmployees,
    icon: PeopleAltIcon,
    iconName: 'Company Employees',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.Package,
    icon: LivingIcon,
    iconName: 'Package',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.CompanyProduct,
    icon: AccountBalanceIcon,
    iconName: 'Company Products',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.StorageProduct,
    icon: WarehouseIcon,
    iconName: 'Storage Product',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.Product,
    icon: DvrIcon,
    iconName: 'Product catalog',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.Ticket,
    icon: DvrIcon,
    iconName: 'Tickets',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.TicketPost,
    icon: DvrIcon,
    iconName: 'Ticket Posts',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.WorkRole,
    icon: EngineeringIcon,
    iconName: 'WorkRoles',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.Vendor,
    icon: DvrIcon,
    iconName: 'Vendors',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.unassignedCompanyProduct,
    icon: AccountBalanceIcon,
    iconName: 'Company Products',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.allEmployees,
    icon: PeopleAltIcon,
    iconName: 'Employees',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.primaryContacts,
    icon: PeopleAltIcon,
    iconName: 'Primary Contacts',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.IssueType,
    icon: DvrIcon,
    iconName: 'Issue Type',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.AppOwnerEmployees,
    icon: PeopleAltIcon,
    iconName: 'Employees',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.EmployeeProducts,
    icon: DvrIcon,
    iconName: 'Employee Products',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.ProductCategories,
    icon: DvrIcon,
    iconName: 'Product categories',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.Brands,
    icon: DvrIcon,
    iconName: 'Brands',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.SelectedCompany,
    icon: DvrIcon,
    iconName: 'Company',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.SelectedDepartment,
    icon: DvrIcon,
    iconName: 'Department',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.SelectedResource,
    icon: DvrIcon,
    iconName: 'Resource',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.sharedProducts,
    icon: DvrIcon,
    iconName: 'Shared Products',
  },
  {
    isActive: false,
    objectList: [] as any,
    objectType: EnumObjectType.ResourceTypes,
    icon: DvrIcon,
    iconName: 'Resource Types',
  },
];
export enum EnumTicketStatus {
  New = 0,
  Scheduled = 1,
  InProgress = 2,
  OnHold = 3,
  AwaitingResponses = 4,
  AwaitingParts = 5,
  Resolved = 6,
  Reopened = 7,
  Invoiced = 8,
  Updated = 9,
}
export const enumTicketStatusToStringMapping: Record<EnumTicketStatus, string> =
  {
    [EnumTicketStatus.New]: 'New',
    [EnumTicketStatus.Scheduled]: 'Scheduled',
    [EnumTicketStatus.InProgress]: 'In Progress',
    [EnumTicketStatus.OnHold]: 'On Hold',
    [EnumTicketStatus.AwaitingResponses]: 'Awaiting Responses',
    [EnumTicketStatus.AwaitingParts]: 'Awaiting Parts',
    [EnumTicketStatus.Resolved]: 'Resolved',
    [EnumTicketStatus.Reopened]: 'Reopened',
    [EnumTicketStatus.Invoiced]: 'Invoiced',
    [EnumTicketStatus.Updated]: 'Updated',
  };

export enum EnumAddressType {
  owner = 0,
  company = 1,
  department = 2,
  employee = 3,
  custom = 4,
}

export enum EnumPlacement {
  NONE = 0,
  EMPLOYEE = 1,
  DEPARTMENT = 2,
  COMPANY = 3,
  RESOURCE = 4,
}
export enum EnumScheduleType {
  None = 0,
  Reminder = 1,
}
export enum EnumRole {
  Manager = 0,
  DepartmentManager = 1,
  Employee = 2,
}

export enum EnumCompanyType {
  PROSPECT = 0,
  FORMER_CLIENT = 1,
  CLIENT = 2,
  LOST = 3,
  VENDOR = 4,
  APPOWNER = 5,
}

export enum EnumCompanyProductStatus {
  NONE = 0,
  STORAGE = 1,
  SERVICE = 2,
  PROCESSING = 3,
  DELIVERED = 4,
  AWAITING_TO_BE_ORDERED = 5,
  PRODUCT_ORDERED = 6,
  PRODUCT_IN_TRANSIT = 7,
  PRODUCT_RECEIVED = 8,
  TO_DELETE = 9,
}
export const enumCompanyProductStatusToStringMapping: Record<
  EnumCompanyProductStatus,
  string
> = {
  [EnumCompanyProductStatus.NONE]: 'None',
  [EnumCompanyProductStatus.STORAGE]: 'Storage',
  [EnumCompanyProductStatus.SERVICE]: 'Service Needed',
  [EnumCompanyProductStatus.PROCESSING]: 'Processing',
  [EnumCompanyProductStatus.DELIVERED]: 'Delivered',
  [EnumCompanyProductStatus.AWAITING_TO_BE_ORDERED]: 'Awaiting to be ordered',
  [EnumCompanyProductStatus.PRODUCT_ORDERED]: 'Product ordered',
  [EnumCompanyProductStatus.PRODUCT_IN_TRANSIT]: 'Product in transit',
  [EnumCompanyProductStatus.PRODUCT_RECEIVED]: 'Product received',
  [EnumCompanyProductStatus.TO_DELETE]: 'To delete',
};
export enum EnumOrderStatus {
  All = 0,
  Ordered = 1,
  InTransit = 2,
  PartialDelivered = 3,
  Delivered = 4,
}

export enum EnumOrderPostStatus {
  New = 0,
  Ordered = 1,
  InTransit = 2,
  PartialDelivered = 3,
  Delivered = 4,
}

export interface IObjectDataList {
  productCatalogList: IBillingProductViewModel[];
  storageProductList: ICompanyProductViewModel[];
  unassignedCompanyProductList: ICompanyProductViewModel[];
  productPackageList: IProductPackageViewModel[];
  workRoleList: IWorkRoleViewModel[];
  employeeList: IEmployeeViewModel[];
  employeeProductList: ICompanyProductViewModel[];
  issueTypeList: IMobileAnswersViewModel[];
  vendorList: ICompanySimpleViewModel[];
}

export interface IScheduleViewModel {
  id: string;
  scheduleType: EnumScheduleType;
  deliveryDate: dayjs.Dayjs;
  startDate: dayjs.Dayjs;
  estimatedTime: { hours: number; minutes: number };
}
export interface IEstimatedTimeModel {
  hours: number;
  minutes: number;
}

export interface IEmailModel {
  ticketNr: string;
  status: string;
  startTime: Date;
  endTime: Date;
  contactName: string;
  contactEmail: string;
  authorName: string;
  authorEmail: string;
  authorPhone: string;
  authorTittle: string;
  companyName: string;
  comment: string;
  productName: string;
  serviceTag: string;
}

export interface IAddressViewModel {
  id?: string | null;
  street: string;
  postalCode: number | string;
  area: string;
  city: string;
  country: string;
  postBox?: string | null;
}

export interface IDeliveryAddressViewModel {
  id?: string | null;
  addressId?: string | null;
  addressType: EnumAddressType;
  street: string;
  postalCode: string | number;
  area: string;
  city: string;
  country: string;
  postBox?: string | null;
  attention?: string | null;
}

export const addressListData: IDeliveryAddressViewModel[] = [
  {
    id: '0',
    addressId: null,
    addressType: EnumAddressType.owner,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
  {
    id: '1',
    addressId: null,
    addressType: EnumAddressType.company,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
  {
    id: '2',
    addressId: null,
    addressType: EnumAddressType.department,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
  {
    id: '3',
    addressId: null,
    addressType: EnumAddressType.employee,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
  {
    id: '4',
    addressId: null,
    addressType: EnumAddressType.custom,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
];

export enum EnumProductVersion {
  Product = 0,
  CompanyProduct = 1,
  StorageProduct = 2,
  OrderProduct = 3,
}
export const ObjectCompanyProductFilterList = [
  // { id: 'All', name: 'All' },
  { id: 'Unassigned', name: 'Unassigned' },
];

export enum EnumJobType {
  None = 0,
  NewEmployee = 1,
  NewMachine = 2,
  Montage = 3,
  ReplaceMachine = 4,
  Support = 5,
  OnBoarding = 6,
  OffBoarding = 7,
}

export enum EnumBillingType {
  SystemPeriod = 0,
  CompanyPeriod = 1,
  Solved = 2,
}

export enum EnumDirection {
  In = 0,
  Out = 1,
}

export interface IMobileAnswersViewModel {
  id: string;
  productTypeId: string;
  local: string;
  english: string;
  isActive: boolean;
}

interface IImg {
  COMPANY_URL: string;
  EMPLOYEE_URL: string;
  PRODUCT_URL: string;
  IMAGE_URL: string;
  PACKAGE_URL: string;
  BILLING_SUPPORT_URL: string;
  BILLING_PRODUCT_URL: string;
  BILLING_AGREEMENT_URL: string;
  BILLING_OTHER_URL: string;
}
export const MissingPhoto: IImg = {
  COMPANY_URL: process.env.PUBLIC_URL + '/img/noCompany.png',
  EMPLOYEE_URL: process.env.PUBLIC_URL + '/img/noEmployee.png',
  PRODUCT_URL: process.env.PUBLIC_URL + '/img/noProduct.png',
  IMAGE_URL: process.env.PUBLIC_URL + '/img/noImage.png',
  PACKAGE_URL: process.env.PUBLIC_URL + '/img/noPackage.png',
  BILLING_SUPPORT_URL: process.env.PUBLIC_URL + '/img/BillingSupport.png',
  BILLING_PRODUCT_URL: process.env.PUBLIC_URL + '/img/BillingHardware.png',
  BILLING_AGREEMENT_URL: process.env.PUBLIC_URL + '/img/BillingAgreement.png',
  BILLING_OTHER_URL: process.env.PUBLIC_URL + '/img/BillingOther.png',
};

export enum EnumBoard {
  Sale = 0,
  Support = 1,
}

export enum EnumPriority {
  NONE = 0,
  LOW = 1,
  NORMAL = 2,
  HIGH = 3,
  CRITICAL = 4,
}

export enum IEnumBillingViewModel {
  SystemPeriod = 0,
  CompanyPeriod = 1,
  Solved = 2,
}

export enum IEnumCycleAlignment {
  None = 0,
  Category = 1,
  ProductType = 2,
  Product = 3,
}

export enum EnumProductSecurity {
  None = 0,
  SecurityOne = 1,
  SecurityTwo = 2,
  SecurityThree = 3,
}
export interface ISelectItemViewModel {
  id: string;
  name: string;
}

export const SecurityData: ISelectItemViewModel[] = [
  { id: '0', name: 'None' },
  { id: '1', name: 'Low' },
  { id: '2', name: 'Medium' },
  { id: '3', name: 'High' },
];

export const paymentCycleData: ISelectItemViewModel[] = [
  { id: '0', name: 'None' },
  { id: '1', name: 'Month' },
  { id: '3', name: 'Quarter' },
  { id: '12', name: 'Year' },
  { id: '36', name: 'Three Years' },
];

export const cycleAlignmentData: ISelectItemViewModel[] = [
  { id: '0', name: 'None' },
  { id: '1', name: 'Product' },
  { id: '2', name: 'ProductType' },
  { id: '3', name: 'Category' },
];
export const bindingPeriodData: ISelectItemViewModel[] = [
  { id: '0', name: 'None' },
  { id: '1', name: 'Month' },
  { id: '3', name: 'Quarter' },
  { id: '12', name: 'Year' },
  { id: '36', name: 'Three Years' },
];
export enum EnumBindingPeriod {
  None = 0,
  Month = 1,
  Quarter = 3,
  Year = 12,
  ThreeYears = 36,
}

export enum EnumCycleAlignment {
  None = 0,
  Product = 1,
  ProductType = 2,
  Category = 3,
}

export interface ICategoriesViewModel {
  id: string;
  category: string;
  productTypes: IProductTypeViewModel[];
}
export const variants = [
  { id: '1', name: 'Investment' },
  { id: '2', name: 'Agreement' },
] as IProductVariantViewModel[];

export interface IFileAddViewModel {
  fileName: string;
  content: any;
}

export interface objectArray {
  isActive: boolean;
  objectList: any[];
  objectType: EnumObjectType;
  icon: any;
  iconName: string;
}
