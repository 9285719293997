import { ThirdPartEnum } from 'Interfaces/ICompaniesConfig';
import { EnumCycleAlignment } from 'Interfaces/IGlobalTypeConfig';
import { IImportProductAddViewModel, removeLastColonPart } from './ICloudFactoryInterfaces';

export const ToProduct = (
  obj: any,
  product: any,
  price: any
): IImportProductAddViewModel => {

  let comment = product.description ?? '';
  let divValue = product.recursionTerm === 12 && product.billingTerm === 12 ? 1 
  : product.recursionTerm === 12 && product.billingTerm === 1 ? product.recursionTerm: 1;
  let newListItem: IImportProductAddViewModel = {
    name: obj.name,
    comment: comment,
    companyId: obj.companyId,
    serialNumber: product.attributes['ms-provisioning-id'],
    unitPrice: price?.cost ? price.cost / divValue : 0,
    retailPrice: price?.sale ? price.sale / divValue : 0,
    security: 1,
    fileModel: null,
    tempType: 0,
    imageFile: '',
    productTypeId: '',
    EAN: product.attributes['ms-provisioning-id'],
    brandId: '',
    quantity: obj.quantity,
    paymentCycle: product.billingTerm,
    cycleAlignment: EnumCycleAlignment.Product,
    bindingPeriod: product.recursionTerm,
    productFeatureDataIds: [],
    legacyId: -1,
    thirdPartyId: removeLastColonPart(obj.sku),
    thirdParty: ThirdPartEnum.CloudFactory,
    purchaserId: '',
  };
  return newListItem;
};
