import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  EnumObjectType,
  MissingPhoto,
} from 'Interfaces/IGlobalTypeConfig';
import GetPriorityColor from 'functions/GetPriorityColor';
import { IOrderPostViewModel } from 'Interfaces/IOrderConfig';
import { useTranslation } from 'react-i18next';
import {
  iProductSimpleViewModel,
} from 'Interfaces/IProductConfig';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import { TextStyle } from 'data/FontStyle';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import { set } from 'lodash';
type FormOrMouseEvent =
  | React.FormEvent<HTMLFormElement>
  | React.MouseEvent<HTMLDivElement, MouseEvent>;
type Props = {
  orderPost: IOrderPostViewModel;
  handleRemoveProduct: {
    (
      event: React.MouseEvent<HTMLButtonElement>,
      selectedObject: iProductSimpleViewModel
    ): void;
  } | null;
  handleChangeProduct: {
    (
      event: FormOrMouseEvent,
      selectedObject: any,
      orderPost: IOrderPostViewModel
    ): void;
  };
  handleChangeOrderQuantity: {
    (
      event: FormOrMouseEvent,
      selectedObject: any,
      objectType: EnumObjectType
    ): void;
  };
  handleChangeProductPrice: {
    (event: FormOrMouseEvent, selectedObject: any, price: number): void;
  };
  objectDrawerList: any;
};

export const SingleOrderPostProductView = ({
  orderPost,
  handleRemoveProduct,
  handleChangeProduct,
  handleChangeOrderQuantity,
  handleChangeProductPrice,
  objectDrawerList,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  //const [price, setPrice] = useState<number>(orderPost.unitPrice);
  const handleChangeProductPriceLocal = (
    event: any,
    selectedObject: any,
    price: number
  ) => {
    // setPrice(price);
    orderPost.unitPrice = price;
    handleChangeProductPrice(event, selectedObject, price);
  };
  const handleChangeProductLocal = (
    event: any,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    handleChangeProduct(event, selectedObject, orderPost);
  };

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        mb: 2,
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        zIndex: 1,
        backgroundColor: 'primary.main',
        borderColor: GetPriorityColor(0),
        boxShadow:
          '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        ':hover': {
          /*  cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out', */
        },
      }}
    >
      {/* HEADER */}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'space-between' }}>
        <Grid item xs={1} sx={{}}>
          <img
            src={orderPost?.url ?? MissingPhoto.PRODUCT_URL}
            alt='company'
            style={{ width: 64, height: 64, borderRadius: 3 }}
          />
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Typography
            sx={{
              color: 'white',
              fontWeight: 'bold',
              letterSpacing: 2,
              fontSize: 16,
              ml: 2,
              mt: 0.5,
              mb: 1,
            }}
          >
            {orderPost?.prevProducts[0]?.name}
          </Typography>
          <Typography
            sx={{
              color: 'white',
              fontWeight: 'bold',
              letterSpacing: 2,
              fontSize: 16,
              ml: 2,
              mt: 0.5,
              mb: 1,
            }}
          >
            x {orderPost?.quantity}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{}}>
          <Typography
            sx={{
              color: 'white',
              textAlign: 'right',
              fontWeight: 300,
              fontSize: 14,
              ml: 2,
              mt: 0.5,
              mb: 1,
              mr: 1,
            }}
          >
            {orderPost?.prevProducts[0]?.brand.brandName || ''}
          </Typography>

          {/* RETAIL PRICE */}
          <Stack direction={'column'} sx={{}}>
            <Typography
              sx={{
                width: '100%',
                letterSpacing: 1,
                pl: 1,
                mt: -1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                textTransform: 'capitalize',
                fontSize: 12,
              }}
            >
              {t('Products.ProductForm.costPrice')}:
            </Typography>
            <TextField
              name={t('Products.ProductForm.costPrice')}
              onFocus={handleFocus}
              sx={{
                mt: -1,
                '& .Mui-focused': {
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                },
                '& .MuiInputAdornment-root': {
                  '& p': {
                    fontSize: '14px',
                    textAlign: 'left',
                  },
                },
              }}
              inputProps={{
                style: { textAlign: 'right', fontSize: 14, paddingRight: 0 },
                type: 'number',
              }}
              InputProps={{
                sx: {
                  // Skjuler pilene for Webkit-baserte nettlesere som Chrome og Safari
                  '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
                    {
                      webkitAppearance: 'none',
                      margin: 0,
                    },
                  // Skjuler pilene for Firefox
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
              }}
              value={orderPost.unitPrice}
              onChange={(e: any) => {
                handleChangeProductPriceLocal(e, orderPost, e.target.value);
              }}
              onBlur={(e: any) => {
                handleChangeProductPrice(e, orderPost, e.target.value);
              }}
            />
          </Stack>
        </Grid>
        <Grid item sx={{}}>
          <Stack direction={'column'} sx={{}}>
            <ObjectDrawer
              buttonContext={t('Orders.OrderPosts.addProduct')}
              buttonIcon={<AutorenewRoundedIcon sx={{ fontSize: 26 }} />}
              objectList={!!objectDrawerList ? objectDrawerList : []}
              objectType={[EnumObjectType.Product]}
              handleChange={handleChangeProductLocal}
              buttonHeight={30}
              buttonWidth={30}
            />
            {!isOpen ? (
              <IconButton
                sx={{ m: 0, p: 0 }}
                onClick={(e) => {
                  setIsOpen(!isOpen);
                }}
              >
                <ExpandMoreRoundedIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{ m: 0, p: 0 }}
                onClick={(e) => {
                  setIsOpen(!isOpen);
                }}
              >
                <ExpandLessRoundedIcon />
              </IconButton>
            )}
          </Stack>
        </Grid>
      </Grid>

      {isOpen &&
        orderPost?.prevProducts.map(
          (product: iProductSimpleViewModel, index: number) => (
            <Grid
              item
              key={index}
              xs={12}
              sx={{ display: 'flex', alignItems: 'space-between' }}
            >
              <Paper
                elevation={0}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexGrow: 1,
                  ml: 1,
                  height: 48,
                  borderRadius: 2,
                  zIndex: 1,
                  backgroundColor: 'rgba(0,0,0,.1)',
                }}
              >
                <Grid
                  item
                  xs={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 1,
                  }}
                >
                  <img
                    src={product?.productOwner?.url || MissingPhoto.EMPLOYEE_URL}
                    alt='company'
                    style={{ width: 48, height: 48, borderRadius: 3 }}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flexGrow: 1,
                    p: 0,
                  }}
                >
                  <Typography
                    sx={{
                      color: 'primary.text',
                      fontWeight: 'bold',
                      letterSpacing: 2,
                      fontSize: 12,
                      ml: 1,
                      mt: 0.5,
                    }}
                  >
                    {product?.productOwner?.name ?? ''}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'primary.text',
                      fontWeight: 300,
                      fontSize: 12,
                      ml: 2,
                      mt: 0.5,
                      mb: 1,
                    }}
                  >
                    {product?.productOwner?.phone ?? ''}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 1,
                  }}
                >
                  <Stack direction={'row'} sx={{}}>
                    {/* QUANTITY NEEDED */}
                    <Stack direction={'column'} sx={{ pr: 0.5 }}>
                      <Typography
                        sx={{
                          width: '100%',
                          letterSpacing: 1,
                          pl: 1,
                          mt: -1,
                          textAlign: 'left',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                          fontSize: 12,
                          color: 'primary.text',
                        }}
                      >
                        {t('Products.ProductForm.needed')}:
                      </Typography>
                      <TextField
                        disabled={true}
                        name={t('Products.ProductForm.needed')}
                        onFocus={handleFocus}
                        sx={{
                          mt: -1,
                          '& .Mui-focused': {
                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                          },
                          '& .MuiInputAdornment-root': {
                            '& p': {
                              fontSize: '14px',
                              textAlign: 'left',
                            },
                          },
                        }}
                        inputProps={{
                          style: {
                            textAlign: 'right',
                            fontSize: 14,
                            paddingRight: 0,
                          },
                          type: 'number',
                        }}
                        InputProps={{
                          sx: {
                            // Skjuler pilene for Webkit-baserte nettlesere som Chrome og Safari
                            '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
                              {
                                webkitAppearance: 'none',
                                margin: 0,
                              },
                            // Skjuler pilene for Firefox
                            '& input[type=number]': {
                              MozAppearance: 'textfield',
                            },
                          },
                        }}
                        value={product.originalQuantity - product.alreadyOrderedQuantity}
                      />
                    </Stack>
                    {/* QUANTITY */}
                    <Stack direction={'column'} sx={{ pl: 0.5 }}>
                      <Typography
                        sx={{
                          width: '100%',
                          letterSpacing: 1,
                          pl: 1,
                          mt: -1,
                          textAlign: 'left',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                          fontSize: 12,
                        }}
                      >
                        {t('Products.ProductForm.quantity')}:
                      </Typography>
                      <TextField
                        name={t('Products.ProductForm.quantity')}
                        onFocus={handleFocus}
                        sx={{
                          mt: -1,
                          '& .Mui-focused': {
                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                          },
                          '& .MuiInputAdornment-root': {
                            '& p': {
                              fontSize: '14px',
                              textAlign: 'left',
                            },
                          },
                        }}
                        inputProps={{
                          style: {
                            textAlign: 'right',
                            fontSize: 14,
                            paddingRight: 0,
                          },
                          type: 'number',
                        }}
                        InputProps={{
                          sx: {
                            // Skjuler pilene for Webkit-baserte nettlesere som Chrome og Safari
                            '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
                              {
                                webkitAppearance: 'none',
                                margin: 0,
                              },
                            // Skjuler pilene for Firefox
                            '& input[type=number]': {
                              MozAppearance: 'textfield',
                            },
                          },
                        }}
                        value={product.orderedQuantity}
                        onChange={(e: any) => {
                          handleChangeOrderQuantity!(
                            e,
                            product,
                            EnumObjectType.Product
                          );
                        }}
                        //onBlur={handleBlur}
                      />
                    </Stack>
                  </Stack>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 1,
                  }}
                >
                  <Box
                    sx={[
                      TextStyle.buttonBox,
                      { p: 0, backgroundColor: 'warning.main' },
                    ]}
                  >
                    <IconButton
                      sx={{ m: 0, p: 0, color: 'warning.text' }}
                      onClick={(e) => {
                        handleRemoveProduct!(e, product);
                      }}
                    >
                      <DeleteForeverRoundedIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Paper>
            </Grid>
          )
        )}
    </Grid>
  );
};
