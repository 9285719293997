import { Grid, List, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import useWindowsDimension from 'functions/useWindowsDimension';
import { SearchFilter } from 'components/ui/SearchFilter';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import { rq } from 'http/apiRoutes';
import { useGet } from 'http/useInnovit';
import { useEffect, useRef, useState } from 'react';
import { SingleTicketView } from 'components/ui/tickets/SingleTicketView';
import { getFilteredList } from 'functions/misc';
import { useTranslation } from 'react-i18next';


export const CompanyTickets = () => {
  const { t } = useTranslation();
  /* INITIALIZATION */
  const { width, height } = useWindowsDimension();
  const { companyId } = useParams() as any;
  const navigate = useNavigate();
  const [tickets, setTickets] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState('All');
  const boardRef = useRef(1);

  // API
  let isEnabled = companyId ? true : false;
  const { data: ticketsData } = useGet(
    rq.CompanyTickets(companyId, isEnabled),
    isEnabled
  );
  //console.log(ticketsData);
  // FUNCTIONS

  // HANDLES

  const handleFilter = (e: any) => {
    setFilterValue(e);
    //console.log('handleFilter ', e);

    setTickets(getFilteredList(ticketsData?.tickets, e.toString()));
    setFilterValue(e);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTickets(getFilteredList(ticketsData?.tickets, e.target.value));
  };

  // USE EFFECTS
  useEffect(() => {
    if (ticketsData) {
      setTickets(ticketsData.tickets);
    }
  }, [ticketsData]);

  return (
    <Grid container  sx={{
      display: 'flex',
      flexDirection: 'row',
      m:0,
      p:0,
    }}
  >
       {/* LEFT SIDE */}
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderRadius: 3,
          pr:.5
        }}
      >
        <Grid item xs={12} sx={{ p: 0, m: 0 }}>
          <SearchFilter
            Icon={SupportAgentRoundedIcon}
            Header={t("company.companyTickets.header")}
            filterValue={filterValue}
            handleFilterChange={handleFilter}
            handleSearch={handleSearch}
            hasSelectAll={true}
            //handleNew={handleNew}
            // selectList={ticketType}
          />

          <List
            sx={{
              height: Math.ceil(height - 242),
              width: '100%',
              mb: 0,
              p: 2,
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {tickets?.length === 0 && <div>{t("company.companyTickets.noData")}</div>}
            {tickets?.map((ticket: any, index: any) => (
              <SingleTicketView
                key={index}
                index={index}
                ticket={ticket}
                //setCompany={setCompany}
              />
            ))}
          </List>
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          ></Paper>
        </Grid>
      </Grid>
      {/* RIGHT SIDE */}
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'row',

          borderRadius: 2,
          pl: .5,
        }}
      >
        <Grid
          container
          sx={{
            height: Math.ceil(height - 124),
            backgroundColor: 'primary.main',
            borderRadius: 2,
            p: 1,
          }}
        >
         {t("company.companyTickets.selectedTicketView")}

        </Grid>
      </Grid>
    </Grid>
  );
};
