import { ICompanyViewModel, ThirdPartEnum } from 'Interfaces/ICompaniesConfig';
import { IImportEmployeeAddViewModel } from './ICloudFactoryInterfaces';

export const ToAddEmployees = (
  data: any,
  userId: string,
  company: ICompanyViewModel,
  departmentId: string
): IImportEmployeeAddViewModel[] => {
  if (!data) return [] as IImportEmployeeAddViewModel[];
  console.log('NEW : employees: før', data);
  let newList: IImportEmployeeAddViewModel[] =
    [] as IImportEmployeeAddViewModel[];

  data.forEach((employee: any) => {
    if (
      employee.userPrincipalName &&
      employee.userPrincipalName.includes('#EXT#')
    ) {
      return;
    }

    let productIds: string[] = employee.products
      ? employee.products.map((product: any) => product.displayName)
      : [];

    let name = employee.displayName;
    if (employee.firstName && employee.lastName)
      name = employee.firstName + ' ' + employee.lastName;

    let newListItem: IImportEmployeeAddViewModel = {
      name: name,
      creatorId: userId,
      companyId: company.id as string,
      phone: '+47 000 00 000',
      email: employee.userPrincipalName,
      departmentId: departmentId,
      primaryContact: false,
      legacyId: 0,
      thirdPartyId: employee.id,
      thirdParty: ThirdPartEnum.CloudFactory,
      productIds: productIds,
    };
    newList.push(newListItem);
  });
  return newList;
};
