import { memo, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { FormAccordionSummary, FormBoxElevated } from 'components/form';

import ArtTrackRoundedIcon from '@mui/icons-material/ArtTrackRounded';
import {
  Accordion,
  AccordionDetails,
  Button,
  ButtonGroup,
  Grid,
  List,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import useWindowsDimension from 'functions/useWindowsDimension';
import ImageUploader from 'components/ui/ImageUploader';
import GetPriorityColor from 'functions/GetPriorityColor';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { IAddressViewModel } from 'Interfaces/IGlobalTypeConfig';
import { SearchFilter } from 'components/ui/SearchFilter';
import { formatPhoneNumber } from 'functions/FormatPhoneNumber';
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
import {
  ICompanyAddViewModel,
  ICompanyInterface,
  ICompanyUpdateViewModel,
  ICompanyViewModel,
  IFileModel,
  ThirdPartEnum,
} from 'Interfaces/ICompaniesConfig';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';

import ConvertBase64StringToFileModel from 'functions/ConvertBase64StringToFileModel';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';
import { useTranslation } from 'react-i18next';
import { usePost, usePut } from 'http/useInnovit';
import { rqPost } from 'http/apiRoutes';

const phoneRegex = /^\+[0-9]{2}(?=(?:\s*\d){8})([\d\s]*)$/;
const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters')
    .required('Name is required'),
  organizationNumber: yup
    .string()
    .required('Organization number is required')
    .min(9, 'Organization number must be at least 9 characters')
    .max(9, 'Organization number must be at most 9 characters')
    .matches(/^[0-9]{9}$/, '9 numbers required'),
  email: yup.string().email().required('Email is required'),
  // phone: yup.string().matches(/^(|[0-9]{8,16})$/, "Between 8-16 characters"),
  //webPage: yup.string().notRequired(),
  // .test(
  //   'test-webPage', // Name of the test
  //   'Enter a valid webPage URL starting with www.', // Error message
  //   (value) => !value || /^www\.[\w-]+\.[a-zA-Z]{2,}$/.test(value) // Test function
  // ),
  phone: yup
    .string()
    .required('Phone number is required')
    .matches(phoneRegex, 'Enter a valid phone number'),
  mainAddress: yup.object().shape({
    street: yup
      .string()
      .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters')
      .required('Street is required'),
    area: yup
      .string()
      .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters')
      .required('Area is required'),
    postalCode: yup
      .string()
      .matches(/^(|[0-9]{4,4})$/, '4 numbers required')
      .required('Postal code is required'),
    city: yup
      .string()
      .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters')
      .required('City is required'),
    country: yup
      .string()
      .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters')
      .required('Country is required'),
  }),
  deliveryAddress: yup
    .object()
    .notRequired()
    .shape({
      street: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),
      area: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),
      postalCode: yup.string().matches(/^(|[0-9]{4,4})$/, '4 numbers required'),
      city: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),
      country: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),
    }),
  invoiceAddress: yup
    .object()
    .notRequired()
    .shape({
      street: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),

      area: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),

      postalCode: yup.string().matches(/^(|[0-9]{4,4})$/, '4 numbers required'),

      city: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),

      country: yup
        .string()
        .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters'),
    }),
});

const ConvertToUpdateCompany = (
  state: ICompanyInterface,
  fileModel: IFileModel | null
) => {
  let companyToUpdate: ICompanyUpdateViewModel = {
    id: state.id ?? 'new',
    organizationNumber: state.organizationNumber,
    name: state.name,
    mainAddress: state.mainAddress,
    deliveryAddress: state.deliveryAddress ?? null,
    invoiceAddress: state.invoiceAddress ?? null,
    phone: state.phone,
    email: state.email,
    supportEmail: state.supportEmail,
    companyType: state.companyType,
    webPage: state.webPage,
    fileModel: fileModel,
    productBilling: state.productBilling,
    onHold: state.onHold,
    isSupport: state.isSupport,
    onHoldNote: state.onHoldNote,
    priority: state.priority,
    fixedHourlyRate: state.fixedHourlyRate,
    workRoleId: state.workRoleId,
    contactId: state.contactId,
    companyProducts: state.companyProducts,
    productCatalog: state.productCatalog,
    storageProducts: state.storageProducts,
    unassignedCompanyProducts: state.unassignedCompanyProducts,
    legacyId: state.legacyId,
    thirdPartyId: state.thirdPartyId,
    thirdParty: state.thirdParty,
  };
  return companyToUpdate;
};
const initialState: ICompanyInterface = {
  id: 'new',
  organizationNumber: '',
  name: '',
  mainAddress: {
    street: '',
    area: '',
    postalCode: '',
    city: '',
    country: 'Norway',
  } as IAddressViewModel,
  deliveryAddress: {
    street: '',
    area: '',
    postalCode: '',
    city: '',
    country: 'Norway',
  } as IAddressViewModel,
  invoiceAddress: {
    street: '',
    area: '',
    postalCode: '',
    city: '',
    country: 'Norway',
  } as IAddressViewModel,
  phone: '+47',
  email: '',
  supportEmail: '',
  companyType: 2,
  webPage: '',
  url: '',
  fileModel: null,
  productBilling: true,
  onHold: false,
  isSupport: false,
  onHoldNote: '',
  priority: 2,
  fixedHourlyRate: 0,
  workRole: null,
  workRoleId: null,
  contactId: null,
  contact: null,
  companyProducts: [] as ICompanyProductViewModel[],
  productCatalog: [] as ICompanyProductViewModel[],
  storageProducts: [] as ICompanyProductViewModel[],
  unassignedCompanyProducts: [] as ICompanyProductViewModel[],
  isValid: false,
  isDirty: false,
  isOnboardingEnabled: false,
  isProductChanged: false,
  legacyId: 0,
  thirdPartyId: '',
  thirdParty: ThirdPartEnum.CloudFactory,
};
type Action =
  | { type: 'SET_DIRTY'; payload: boolean }
  | { type: 'SET_IS_PRODUCT_CHANGED'; payload: boolean }
  | { type: 'VALIDATE' }
  | { type: 'UPDATE_FIELD'; field: keyof ICompanyInterface; value: any }
  | {
      type: 'UPDATE_ADDRESS_FIELD';
      addressType: 'mainAddress' | 'deliveryAddress' | 'invoiceAddress';
      field: keyof IAddressViewModel;
      value: any;
    }
  | { type: 'UPDATE_COMPANY_TYPE'; value: number }
  | { type: 'CHANGE_WORK_ROLE'; payload: IWorkRoleViewModel }
  | { type: 'CHANGE_PRIORITY'; payload: number }
  | { type: 'TOGGLE_ON_HOLD'; payload: boolean }
  | { type: 'SET_PRODUCT_CATALOG'; payload: ICompanyProductViewModel[] }
  | { type: 'ADD_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_UNASSIGN_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'SET_CONTACT'; payload: IEmployeeViewModel }
  | { type: 'SET_URL'; payload: string }
  | { type: 'SET_SHARED_PRODUCTS'; payload: ICompanyProductViewModel[] }
  | {
      type: 'REMOVE_UNASSIGN_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | {
      type: 'UPDATE_UNASSIGN_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | { type: 'TOGGLE_ONBOARDING_ENABLED'; payload: boolean }
  | { type: 'SET_COMPANY'; payload: ICompanyAddViewModel };
const reducer = (
  state: ICompanyInterface,
  action: Action
): ICompanyInterface => {
  switch (action.type) {
    case 'SET_DIRTY':
      return {
        ...state,
        isDirty: action.payload,
      };
    case 'SET_IS_PRODUCT_CHANGED':
      return {
        ...state,
        isProductChanged: action.payload,
      };
    case 'VALIDATE':
      try {
        schema.validateSync(state, { abortEarly: false });
        return { ...state, isValid: true };
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          // Log the entire error object
          console.log('Validation Error: ', error);

          // Log specific details
          console.log('Errors: ', error.errors); // Array of error messages
          console.log('Inner Errors: ', error.inner); // Detailed error objects for each field

          // If you want to log more specific details for each field
          error.inner.forEach((err) => {
            console.log(err.path, err.message);
          });
        } else {
          // If the error is not a yup.ValidationError, log it
          console.log('Unexpected Error: ', error);
        }

        return { ...state, isValid: false };
      }
    case 'UPDATE_FIELD':
      if (action.field === 'phone') {
        return {
          ...state,
          [action.field]: formatPhoneNumber((action.value as string) || '+47'),
        };
      }
      return { ...state, [action.field]: action.value || '' };

    case 'UPDATE_ADDRESS_FIELD':
      console.log('update_address field', action);
      return {
        ...state,
        [action.addressType]: {
          ...state[action.addressType],
          [action.field]: action.value.toString(),
        },
      };
    case 'UPDATE_COMPANY_TYPE':
      return {
        ...state,
        companyType: action.value,
      };
    case 'CHANGE_WORK_ROLE':
      //console.log('change_work_role', action);
      return {
        ...state,
        workRole: action.payload,
        workRoleId: action.payload.id,
      };
    case 'CHANGE_PRIORITY':
      return {
        ...state,
        priority: action.payload,
      };
    case 'TOGGLE_ON_HOLD':
      //  console.log('TOGGLE_ON_HOLD', action.payload);
      return {
        ...state,
        onHold: action.payload,
      };
    case 'SET_PRODUCT_CATALOG':
      return {
        ...state,
        productCatalog: action.payload,
      };
    case 'ADD_PRODUCT':
      // console.log('ADD_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: [...state.productCatalog, action.payload],
      };
    case 'REMOVE_PRODUCT':
      // console.log('REMOVE_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: state.productCatalog.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_PRODUCT':
      //console.log('UPDATE_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: state.productCatalog.map((product) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_STORAGE_PRODUCT':
      //console.log('ADD_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: [...state.storageProducts, action.payload],
      };
    case 'REMOVE_STORAGE_PRODUCT':
      //console.log('REMOVE_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: state.storageProducts.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_STORAGE_PRODUCT':
      //console.log('UPDATE_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: state.storageProducts.map((product) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_COMPANY_PRODUCT':
      //console.log('ADD_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: [...state.companyProducts, action.payload],
      };
    case 'REMOVE_COMPANY_PRODUCT':
      //console.log('REMOVE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: state.companyProducts.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_COMPANY_PRODUCT':
      //console.log('UPDATE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: state.companyProducts.map((product) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_UNASSIGN_COMPANY_PRODUCT':
      //console.log('ADD_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: [
          ...state.unassignedCompanyProducts,
          action.payload,
        ],
      };
    case 'REMOVE_UNASSIGN_COMPANY_PRODUCT':
      //console.log('REMOVE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_UNASSIGN_COMPANY_PRODUCT':
      //console.log('UPDATE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.map(
          (product) =>
            product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'SET_URL':
      return {
        ...state,
        url: action.payload,
      };
    case 'SET_CONTACT':
      return {
        ...state,
        contact: action.payload,
        contactId: action.payload.id,
        isDirty: true,
      };
    case 'TOGGLE_ONBOARDING_ENABLED':
      return {
        ...state,
        isOnboardingEnabled: action.payload,
      };
    case 'SET_SHARED_PRODUCTS':
      return {
        ...state,
        companyProducts: action.payload as ICompanyProductViewModel[],
      };
    case 'SET_COMPANY':
      return {
        ...state,
        organizationNumber: action.payload.organizationNumber,
        name: action.payload.name,
        mainAddress: action.payload.mainAddress,
        deliveryAddress: action.payload.deliveryAddress,
        invoiceAddress: action.payload.invoiceAddress,
        phone: action.payload.phone,
        email: action.payload.email,
        companyType: action.payload.companyType,
        webPage: action.payload.webPage,
        productBilling: action.payload.productBilling,
        isSupport: action.payload.isSupport,
        onHold: action.payload.onHold,
        onHoldNote: action.payload.onHoldNote,
        fixedHourlyRate: action.payload.fixedHourlyRate,
        priority: action.payload.priority,
        legacyId: action.payload.legacyId,
        thirdPartyId: action.payload.thirdPartyId,
        thirdParty: ThirdPartEnum.CloudFactory,
      };
    default:
      return state;
  }
};
interface OHjsxProps {
  onHold: boolean;
  dispatch: React.Dispatch<Action>;
}
interface FormErrors {
  name?: string;
  organizationNumber?: number;
  email?: string;
  webPage?: string;
  fixedHourlyRate?: number;
}
type Props = {
  expanded: boolean;
  company: ICompanyAddViewModel;
  handleShowProducts: (company: ICompanyViewModel) => void;
};
export const ImportCompanyForm = ({
  company,
  handleShowProducts,
  expanded,
}: Props) => {
  const { t } = useTranslation();

  /* INITIALIZATIONS */
  const [companyId, setCompanyId] = useState<string>('new');
  console.log('company:', company);
  let navigate = useNavigate();
  const { height } = useWindowsDimension();
  // STATES
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [base64String, setBase64String] = useState('') as any;
  const [image, setImage] = useState(null) as any;
  const [user] = useAtom(userAtom);
  // test const [isEnabled, setIsEnabled] = useState<boolean>(false)

  // API CALLS
  let isEnabled = companyId !== 'new' ? true : false;
  const postCompany = usePost(
    rqPost.company(companyId || '', String(state.companyType), isEnabled),
    isEnabled
  );
  const putCompany = usePut(
    rqPost.company(companyId || '', String(state.companyType), isEnabled),
    isEnabled
  );

  const [errors, setErrors] = useState<FormErrors>({});

  const validateField = async (
    field: keyof ICompanyViewModel,
    value: string
  ) => {
    try {
      await schema.validateAt(field, { [field]: value });
      setErrors((prev) => ({ ...prev, [field]: '' }));
    } catch (error: any) {
      if (error instanceof yup.ValidationError) {
        setErrors((prev) => ({ ...prev, [field]: error.message }));
      }
    }
  };
  const priorityJSX = memo(() => {
    return (
      <>
        <ButtonGroup
          variant='contained'
          aria-label='outlined primary button group'
          size='small'
          sx={{ m: 2 }}
        >
          <Button
            key='LOW'
            sx={{
              width: 100,
              backgroundColor:
                state.priority === 1
                  ? GetPriorityColor(state.priority)
                  : 'primary.main',
            }}
            onClick={(e) => {
              handlePrioritySubmit(e, 1);
            }}
          >
            {t('priority.low')}
          </Button>
          <Button
            key='NORMAL'
            sx={{
              width: 100,
              backgroundColor:
                state.priority === 2
                  ? GetPriorityColor(state.priority)
                  : 'primary.main',
            }}
            onClick={(e) => {
              handlePrioritySubmit(e, 2);
            }}
          >
            {t('priority.normal')}
          </Button>
          <Button
            key='HIGH'
            sx={{
              width: 100,
              backgroundColor:
                state.priority === 3
                  ? GetPriorityColor(state.priority)
                  : 'primary.main',
            }}
            onClick={(e) => {
              handlePrioritySubmit(e, 3);
            }}
          >
            {t('priority.high')}
          </Button>
        </ButtonGroup>
      </>
    );
  });
  const OHjsx: React.FC<OHjsxProps> = ({ onHold, dispatch }) => {
    const { t } = useTranslation();
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'TOGGLE_ON_HOLD', payload: e.target.checked });
    };
    return (
      <Grid item sx={{ m: 0, p: 0 }}>
        <Stack direction='row'>
          <Typography
            sx={{
              m: 0,
              p: 2.2,
              pr: 0,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
              color: 'primary.text',
            }}
          >
            {onHold
              ? t('switch.yes').toUpperCase()
              : t('switch.no').toUpperCase()}
          </Typography>

          <Switch
            color={onHold ? 'secondary' : 'primary'}
            sx={{
              mt: 1,
              mr: 1,
              '& .MuiSwitch-thumb': {
                backgroundColor: onHold ? 'secondary.light' : 'primary.text', // Use any color you like for 'onHold' state
              },
              '& .MuiSwitch-track': {
                backgroundColor: onHold
                  ? 'rgba(0, 0, 0, 0.7)'
                  : 'rgba(0, 0, 255, 0.3)', // This gives a lighter red shade for the track. Adjust as needed.
              },
            }}
            checked={onHold}
            onChange={handleToggle}
            name='onHold'
          />
        </Stack>
      </Grid>
    );
  };
  // HANDLERS
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({
      type: 'UPDATE_FIELD',
      field: name as keyof ICompanyViewModel,
      value: (value as string) || '',
    });
    dispatch({ type: 'VALIDATE' });
    dispatch({
      type: 'SET_DIRTY',
      payload: true,
    });
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    validateField(name as keyof ICompanyViewModel, (value as string) || '');
  };
  const handlePrioritySubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    priority: number
  ) => {
    dispatch({ type: 'CHANGE_PRIORITY', payload: priority });
    dispatch({
      type: 'SET_DIRTY',
      payload: true,
    });
  };
  const handleConfirm = async (e: any) => {
    e.preventDefault();
    setDeleteConfirm(false);
  };

  const setFormDirty = () => {
    dispatch({ type: 'VALIDATE' });
    dispatch({
      type: 'SET_DIRTY',
      payload: true,
    });
  };

  // FUNCTIONS

  // USSEFFECTS
  useEffect(() => {
    if (company) {
      dispatch({ type: 'SET_COMPANY', payload: company });
      dispatch({ type: 'VALIDATE' });
    }
  }, [company]);

  // ONSUBMIT
  const onSubmit = (e: any) => {
    e.preventDefault();
    let fileModel = null;
    // converting image to fileModel
    if (image) {
      fileModel = ConvertBase64StringToFileModel(base64String, image.name);
    }
    // check if it is a new company
    if (state?.id !== 'new' && state?.id !== null) {
      let updateCompany: ICompanyUpdateViewModel = ConvertToUpdateCompany(
        state,
        fileModel
      );
      console.log('ONSUBMIT: updateCompany => ', updateCompany);

      putCompany.mutate(updateCompany, {
        onSuccess: (res: any) => {},
      });
    } else if (state?.id === 'new') {
      let newCompany: ICompanyAddViewModel = {
        organizationNumber: state.organizationNumber,
        name: state.name,
        mainAddress: state.mainAddress,
        deliveryAddress: state?.deliveryAddress ? state.deliveryAddress : null,
        invoiceAddress: state?.invoiceAddress ? state.invoiceAddress : null,
        phone: state.phone,
        email: state.email,
        supportEmail: state.supportEmail,
        companyType: state.companyType,
        webPage: state.webPage,
        fileModel: fileModel ? fileModel : null,
        productBilling: state.productBilling,
        onHold: state.onHold,
        isSupport: state.isSupport,
        onHoldNote: state.onHoldNote,
        priority: state.priority,
        fixedHourlyRate: state.fixedHourlyRate,
        workRoleId: state.workRoleId,
        contactId: null,
        companyProducts: state.companyProducts,
        productCatalog: state.productCatalog,
        storageProducts: state.storageProducts,
        unassignedCompanyProducts: state.unassignedCompanyProducts,
        legacyId: state.legacyId,
        thirdPartyId: state.thirdPartyId,
        thirdParty: ThirdPartEnum.CloudFactory,
      };
      console.log('ONSUBMIT: newCompany => ', newCompany);
      postCompany.mutate(newCompany, {
        onSuccess: (res: ICompanyViewModel) => {
          handleShowProducts(res);
        },
      });
    }
  };

  return (
    <Grid
      container
      sx={{
        m: 0,
        p: 0,
      }}
    >
      {/*  LEFT SIDE */}
      <Grid item xs={12} sx={{ pr: 0.5 }}>
        <Grid item xs={12}>
          <SearchFilter
            Icon={FeedRoundedIcon}
            Header={t('company.details.header')}
            filterValue={null}
            handleFilterChange={null}
            handleSearch={null}
            handleNew={null}
            selectList={null}
          />
        </Grid>

        <List
          sx={{
            width: '100%',
            height: Math.ceil(height - 274 - (expanded ? 386 : 0)),
            p: 2,
            overflow: 'auto',
            backgroundColor: 'primary.main',
          }}
        >
          <Stack direction='row'>
            <Grid item sx={{ width: 128, mb: 2 }}>
              <Stack direction='column'>
                <Typography
                  sx={{
                    m: 0,
                    p: 2,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'normal ',
                    fontSize: 14,
                    color: 'primary.text',
                  }}
                >
                  {t('company.details.logo')}
                </Typography>

                <ImageUploader
                  width={128}
                  height={64}
                  setImage={setImage}
                  previewImage={state?.url ? state.url : null}
                  setBase64String={(str) => {
                    if (typeof str === 'string' || str === null) {
                      setBase64String(str);
                    }
                  }}
                  setFormDirty={setFormDirty}
                  base64String={base64String}
                />
              </Stack>
            </Grid>
            <Grid
              container
              sx={{
                p: 3,
              }}
              rowSpacing={1}
              columnSpacing={{ xs: 2 }}
            >
              <Grid item xs={7}>
                <TextField
                  name='name'
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label={t('company.details.name')}
                  value={state.name || ''}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  name='organizationNumber'
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label={t('company.details.orgNumber')}
                  value={state.organizationNumber || ''}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  error={!!errors.organizationNumber}
                  helperText={errors.organizationNumber}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name='email'
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label={t('company.details.email')}
                  value={state.email || ''}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>

              <Grid item xs={6}>
                <MuiTelInput
                  name='phone'
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  continents={['EU']}
                  preferredCountries={['NO', 'SE', 'DK', 'DE', 'GB']}
                  defaultCountry='NO'
                  forceCallingCode={true}
                  label={t('company.details.phone')}
                  variant='standard'
                  value={state.phone || '+47'}
                  onChange={(value: string, info: MuiTelInputInfo) => {
                    validateField('phone', value);
                    dispatch({
                      type: 'UPDATE_FIELD',
                      field: 'phone',
                      value: value,
                    });
                    dispatch({ type: 'VALIDATE' });
                  }}
                />
              </Grid>

              <Grid item xs={7}>
                <TextField
                  name='webPage'
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label={t('company.details.website')}
                  value={state?.webPage || ''}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  error={!!errors.webPage}
                  helperText={errors.webPage}
                />
              </Grid>
              {company?.companyType !== 5 && (
                <Grid item xs={5}>
                  <Typography
                    sx={{
                      m: 0,
                      p: 0,
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'normal',
                      fontSize: 14,
                      color: 'primary.text',
                    }}
                  >
                    {t('company.details.type')}
                  </Typography>
                  <Select
                    sx={{ width: '100%', p: 0, mt: -0.5 }}
                    labelId='companyType'
                    id='companyType'
                    variant='standard'
                    value={state?.companyType || 2}
                    label={t('company.details.type')}
                    onChange={(e) =>
                      dispatch({
                        type: 'UPDATE_COMPANY_TYPE',
                        value: e.target.value as number,
                      })
                    }
                  >
                    <MenuItem value={0}>Prospect</MenuItem>
                    <MenuItem value={1}>FormerClient</MenuItem>
                    <MenuItem value={2}>Client</MenuItem>
                    <MenuItem value={3}>Lost</MenuItem>
                    <MenuItem value={4}>Vendor</MenuItem>
                  </Select>
                </Grid>
              )}
              <Grid item xs={6}></Grid>
            </Grid>
          </Stack>
          {/*     Priority   */}

          <Grid item xs={12} sx={{ mb: 2 }}>
            <FormBoxElevated
              Icon={ArtTrackRoundedIcon}
              title={t('priority.header')}
              FormJSX={priorityJSX}
            />
          </Grid>

          {/*     Main Address */}

          <Grid
            item
            xs={12}
            sx={{
              mb: 2,
              border: 0,
              borderRadius: 2,
              boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <Accordion
              disableGutters
              expanded
              sx={{
                backgroundColor: 'transparent',
                border: 0,
              }}
            >
              <FormAccordionSummary
                Icon={HomeRoundedIcon}
                title={t('address.mainAddress')}
              />
              <AccordionDetails>
                <TextField
                  name='street'
                  sx={{ width: '50%', p: 1, m: 0, mt: 1 }}
                  label={t('address.street')}
                  value={state.mainAddress?.street || ''}
                  onChange={(e) => {
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'mainAddress',
                      field: 'street',
                      value: e.target.value,
                    });
                    dispatch({ type: 'VALIDATE' });
                  }}
                />
                <TextField
                  name='postalCode'
                  sx={{ width: '20%', p: 1, m: 0, mt: 1 }}
                  label={t('address.zip')}
                  value={state.mainAddress?.postalCode || ''}
                  onChange={(e) => {
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'mainAddress',
                      field: 'postalCode',
                      value: e.target.value,
                    });
                    dispatch({ type: 'VALIDATE' });
                  }}
                />
                <TextField
                  name='area'
                  sx={{ width: '30%', p: 1, m: 0, mt: 1 }}
                  label={t('address.area')}
                  value={state.mainAddress?.area || ''}
                  onChange={(e) => {
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'mainAddress',
                      field: 'area',
                      value: e.target.value,
                    });
                    dispatch({ type: 'VALIDATE' });
                  }}
                />
                <TextField
                  name='city'
                  sx={{ width: '40%', p: 1, m: 0 }}
                  label={t('address.city')}
                  value={state.mainAddress?.city || ''}
                  onChange={(e) => {
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'mainAddress',
                      field: 'city',
                      value: e.target.value,
                    });
                    dispatch({ type: 'VALIDATE' });
                  }}
                />

                <Select
                  name='country'
                  sx={{ width: '30%', p: 0, mt: 3 }}
                  labelId='country'
                  id='country'
                  label={t('address.country')}
                  variant='standard'
                  value={state.mainAddress?.country || 'Norway'}
                  onChange={(e) => {
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'mainAddress',
                      field: 'country',
                      value: e.target.value,
                    });
                    dispatch({ type: 'VALIDATE' });
                  }}
                >
                  <MenuItem value={'Norway'}>Norway</MenuItem>
                  <MenuItem value={'Sweden'}>Sweden</MenuItem>
                  <MenuItem value={'Denmark'}>Denmark</MenuItem>
                  <MenuItem value={'Germany'}>Germany</MenuItem>
                  <MenuItem value={'England'}>England</MenuItem>
                </Select>
                <TextField
                  name='postBox'
                  sx={{ width: '30%', p: 1, m: 0 }}
                  label={t('address.postbox')}
                  value={state.mainAddress?.postBox || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'mainAddress',
                      field: 'postBox',
                      value: e.target.value,
                    })
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/*     Delivery Address */}

          <Grid
            item
            xs={12}
            sx={{
              mb: 2,
              border: 0,
              borderRadius: 2,
              boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <Accordion
              disableGutters
              sx={{
                backgroundColor: 'transparent',
                border: 0,
              }}
            >
              <FormAccordionSummary
                Icon={HomeRoundedIcon}
                title={t('address.deliveryAddress')}
              />
              <AccordionDetails>
                <TextField
                  name='street'
                  sx={{ width: '50%', p: 1, m: 0, mt: 1 }}
                  label={t('address.street')}
                  value={state.deliveryAddress?.street || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'deliveryAddress',
                      field: 'street',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='PostalCode'
                  sx={{ width: '20%', p: 1, m: 0, mt: 1 }}
                  label={t('address.zip')}
                  value={state.deliveryAddress?.postalCode || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'deliveryAddress',
                      field: 'postalCode',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='Area'
                  sx={{ width: '30%', p: 1, m: 0, mt: 1 }}
                  label={t('address.area')}
                  value={state.deliveryAddress?.area || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'deliveryAddress',
                      field: 'area',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='City'
                  sx={{ width: '40%', p: 1, m: 0 }}
                  label={t('address.city')}
                  value={state.deliveryAddress?.city || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'deliveryAddress',
                      field: 'city',
                      value: e.target.value,
                    })
                  }
                />

                <Select
                  sx={{ width: '30%', p: 0, mt: 3 }}
                  labelId='country'
                  id='country'
                  variant='standard'
                  value={state.deliveryAddress?.country || 'Norway'}
                  label={t('address.country')}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'deliveryAddress',
                      field: 'country',
                      value: e.target.value,
                    })
                  }
                >
                  <MenuItem value={'Norway'}>Norway</MenuItem>
                  <MenuItem value={'Sweden'}>Sweden</MenuItem>
                  <MenuItem value={'Denmark'}>Denmark</MenuItem>
                  <MenuItem value={'Germany'}>Germany</MenuItem>
                  <MenuItem value={'England'}>England</MenuItem>
                </Select>
                <TextField
                  name='postBox'
                  sx={{ width: '30%', p: 1, m: 0 }}
                  label={t('address.postbox')}
                  value={state.deliveryAddress?.postBox || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'deliveryAddress',
                      field: 'postBox',
                      value: e.target.value,
                    })
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/*     Invoice Address */}

          <Grid
            item
            xs={12}
            sx={{
              mb: 2,
              border: 0,
              borderRadius: 2,
              boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <Accordion
              disableGutters
              sx={{
                backgroundColor: 'transparent',
                border: 0,
              }}
            >
              <FormAccordionSummary
                Icon={HomeRoundedIcon}
                title={t('address.billingAddress')}
              />
              <AccordionDetails>
                <TextField
                  name='street'
                  sx={{ width: '50%', p: 1, m: 0, mt: 1 }}
                  label={t('address.street')}
                  value={state.invoiceAddress?.street || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'invoiceAddress',
                      field: 'street',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='postalCode'
                  sx={{ width: '20%', p: 1, m: 0, mt: 1 }}
                  label='Postal Code'
                  value={state.invoiceAddress?.postalCode || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'invoiceAddress',
                      field: 'postalCode',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='area'
                  sx={{ width: '30%', p: 1, m: 0, mt: 1 }}
                  label={t('address.area')}
                  value={state.invoiceAddress?.area || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'invoiceAddress',
                      field: 'area',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  name='city'
                  sx={{ width: '40%', p: 1, m: 0 }}
                  label={t('address.city')}
                  value={state.invoiceAddress?.city || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'invoiceAddress',
                      field: 'city',
                      value: e.target.value,
                    })
                  }
                />

                <Select
                  sx={{ width: '30%', p: 0, mt: 3 }}
                  labelId='country'
                  id='country'
                  variant='standard'
                  value={state.invoiceAddress?.country || 'Norway'}
                  label={t('address.country')}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'invoiceAddress',
                      field: 'country',
                      value: e.target.value,
                    })
                  }
                >
                  <MenuItem value={'Norway'}>Norway</MenuItem>
                  <MenuItem value={'Sweden'}>Sweden</MenuItem>
                  <MenuItem value={'Denmark'}>Denmark</MenuItem>
                  <MenuItem value={'Germany'}>Germany</MenuItem>
                  <MenuItem value={'England'}>England</MenuItem>
                </Select>
                <TextField
                  name='postBox'
                  sx={{ width: '30%', p: 1, m: 0 }}
                  label={t('address.postbox')}
                  value={state.invoiceAddress?.postBox || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      addressType: 'invoiceAddress',
                      field: 'postBox',
                      value: e.target.value,
                    })
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12} sx={{ mb: 2 }}>
            <FormBoxElevated
              Icon={ReportProblemRoundedIcon}
              title={t('company.details.onHold')}
              FormJSX={() => (
                <OHjsx onHold={state.onHold} dispatch={dispatch} />
              )} // Pass it as a component
            />
          </Grid>
          {state.onHold && (
            <TextField
              name='onHoldeNote'
              className='subvariant-hovered'
              label={t('company.details.onHoldNote')}
              variant='filled'
              multiline={true}
              onChange={(e) =>
                dispatch({
                  type: 'UPDATE_FIELD',
                  field: 'onHoldNote',
                  value: e.target.value,
                })
              }
            />
          )}
        </List>

        <Grid item xs={12}>
          <Paper
            variant='elevation3'
            sx={{
              width: '100%',
              p: 0,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              backgroundColor: 'primary.main',
            }}
          >
            <Grid
              container
              sx={{
                m: 0,
                p: 2,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              <Grid item>
                <Button
                  type='submit'
                  variant='contained'
                  onClick={onSubmit}
                  disabled={state.isValid === true}
                >
                  {postCompany.isLoading
                    ? t('button.create')
                    : t('button.create')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
