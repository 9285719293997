import React, { useEffect, useReducer, useState } from 'react';
import { Button, Grid, Paper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import SavingsIcon from '@mui/icons-material/Savings';
import { Header } from 'data/FontStyle';
import { SingleMonthBudgetView } from './SingleMonthBudgetView';
import { IBudgetSelectedMonth, IBudgetViewModel } from './BudgetConfig';
import { useGet, usePost, usePut } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import { t } from 'i18next';
import dayjs, { Dayjs } from 'dayjs';

type InitialState = {
  initialValue: number,
  month: string,
  investment: IBudgetSelectedMonth,
  agreement: IBudgetSelectedMonth,
  support: IBudgetSelectedMonth,
  other: IBudgetSelectedMonth,
  year: number,
  hasPendingChanges: boolean,
  isConfirmed: boolean,
  isLoaded: boolean
  isYearChanged: boolean,
}

const ConvertStateToBudgetPost = (state: InitialState) => {
  let obj: IBudgetViewModel = {
    aprAgr: state.agreement.apr.value,
    aprInv: state.investment.apr.value,
    aprOth: state.other.apr.value,
    aprSup: state.support.apr.value,
    augAgr: state.agreement.aug.value,
    augInv: state.investment.aug.value,
    augOth: state.other.aug.value,
    augSup: state.support.aug.value,
    decAgr: state.agreement.dec.value,
    decInv: state.investment.dec.value,
    decOth: state.other.dec.value,
    decSup: state.support.dec.value,
    febAgr: state.agreement.feb.value,
    febInv: state.investment.feb.value,
    febOth: state.other.feb.value,
    febSup: state.support.feb.value,
    janAgr: state.agreement.jan.value,
    janInv: state.investment.jan.value,
    janOth: state.other.jan.value,
    janSup: state.support.jan.value,
    julAgr: state.agreement.jul.value,
    julInv: state.investment.jul.value,
    julOth: state.other.jul.value,
    julSup: state.support.jul.value,
    junAgr: state.agreement.jun.value,
    junInv: state.investment.jun.value,
    junOth: state.other.jun.value,
    junSup: state.support.jun.value,
    marAgr: state.agreement.mar.value,
    marInv: state.investment.mar.value,
    marOth: state.other.mar.value,
    marSup: state.support.mar.value,
    mayAgr: state.agreement.may.value,
    mayInv: state.investment.may.value,
    mayOth: state.other.may.value,
    maySup: state.support.may.value,
    novAgr: state.agreement.nov.value,
    novInv: state.investment.nov.value,
    novOth: state.other.nov.value,
    novSup: state.support.nov.value,
    octAgr: state.agreement.oct.value,
    octInv: state.investment.oct.value,
    octOth: state.other.oct.value,
    octSup: state.support.oct.value,
    sepAgr: state.agreement.sep.value,
    sepInv: state.investment.sep.value,
    sepOth: state.other.sep.value,
    sepSup: state.support.sep.value,
    year: Number(state.year)
  };

  return obj;
};

const initialState: InitialState = {
  initialValue: 0,
  month: '',
  year: Number(dayjs().year()),
  investment: {
    name: "Investment",
    jan: { value: 0 },
    feb: { value: 0 },
    mar: { value: 0 },
    apr: { value: 0 },
    may: { value: 0 },
    jun: { value: 0 },
    jul: { value: 0 },
    aug: { value: 0 },
    sep: { value: 0 },
    oct: { value: 0 },
    nov: { value: 0 },
    dec: { value: 0 },
  } as IBudgetSelectedMonth,
  agreement: {
    name: 'Agreement',
    jan: { value: 0 },
    feb: { value: 0 },
    mar: { value: 0 },
    apr: { value: 0 },
    may: { value: 0 },
    jun: { value: 0 },
    jul: { value: 0 },
    aug: { value: 0 },
    sep: { value: 0 },
    oct: { value: 0 },
    nov: { value: 0 },
    dec: { value: 0 },
  } as IBudgetSelectedMonth,
  support: {
    name: 'Support',
    jan: { value: 0 },
    feb: { value: 0 },
    mar: { value: 0 },
    apr: { value: 0 },
    may: { value: 0 },
    jun: { value: 0 },
    jul: { value: 0 },
    aug: { value: 0 },
    sep: { value: 0 },
    oct: { value: 0 },
    nov: { value: 0 },
    dec: { value: 0 },
  } as IBudgetSelectedMonth,
  other: {
    name: 'Other',
    jan: { value: 0 },
    feb: { value: 0 },
    mar: { value: 0 },
    apr: { value: 0 },
    may: { value: 0 },
    jun: { value: 0 },
    jul: { value: 0 },
    aug: { value: 0 },
    sep: { value: 0 },
    oct: { value: 0 },
    nov: { value: 0 },
    dec: { value: 0 },
  } as IBudgetSelectedMonth,
  hasPendingChanges: false,
  isConfirmed: false,
  isLoaded: false,
  isYearChanged: true
}

type Action =
  | { type: 'SET_BUDGET'; payload: { budget: IBudgetSelectedMonth } }
  | { type: 'SET_INVESTMENT'; payload: { investment: IBudgetSelectedMonth } }
  | { type: 'SET_AGREEMENT'; payload: { agreement: IBudgetSelectedMonth } }
  | { type: 'SET_SUPPORT'; payload: { support: IBudgetSelectedMonth } }
  | { type: 'SET_OTHER'; payload: { other: IBudgetSelectedMonth } }
  | { type: 'SELECT_MONTH_SUM'; payload: { initialValue: number } }
  | { type: 'SET_SELECTED_MONTH'; payload: { month: string } }
  | { type: 'SET_SELECTED_YEAR'; payload: { year: number } }
  | { type: 'RESET_STATE' }
  | { type: 'MARK_PENDING_CHANGES' }
  | { type: 'CONFIRM_CHANGES' }
  | { type: 'RESET_BUTTONS'}
  | { type: 'SET_EMPTY_YEAR', payload: {year: number}}
  | { type: 'LOAD_DATA'; payload: { investment: IBudgetSelectedMonth, agreement: IBudgetSelectedMonth, support: IBudgetSelectedMonth, other: IBudgetSelectedMonth } };

function stateReducer(state: InitialState, action: Action): InitialState {
  switch (action.type) {
    case 'SET_INVESTMENT':
      return {
        ...state,
        investment: action.payload.investment,
        hasPendingChanges: true, // Only mark as pending if data is loaded
      };
    case 'SET_AGREEMENT':
      return {
        ...state,
        agreement: action.payload.agreement,
        hasPendingChanges: true, // Only mark as pending if data is loaded
      };
    case 'SET_SUPPORT':
      return {
        ...state,
        support: action.payload.support,
        hasPendingChanges: true, // Only mark as pending if data is loaded
      };
    case 'SET_OTHER':
      return {
        ...state,
        other: action.payload.other,
        hasPendingChanges: true, // Only mark as pending if data is loaded
      };
    case 'SET_BUDGET':
      return {
        ...state,
        hasPendingChanges: true, // Only mark as pending if data is loaded
      };
    case 'SELECT_MONTH_SUM':
      return {
        ...state,
        initialValue: action.payload.initialValue,
        hasPendingChanges: true, // Only mark as pending if data is loaded
      };
    case 'SET_SELECTED_MONTH':
      return {
        ...state,
        month: action.payload.month,
        hasPendingChanges: true, // Only mark as pending if data is loaded
      };
    case 'SET_SELECTED_YEAR':
      return {
        ...state,
        year: action.payload.year,
        hasPendingChanges: true, // Only mark as pending if data is loaded
        isYearChanged: true
      };
    case 'RESET_STATE':
      return initialState;
    case 'SET_EMPTY_YEAR': 
    return {
      ...initialState,
      year: action.payload.year,
      isYearChanged: false,
      hasPendingChanges: false,
      isConfirmed: false,
      
    };
    case 'MARK_PENDING_CHANGES':
      return {
        ...state,
        hasPendingChanges: true,
        isConfirmed: false,
      };
    case 'CONFIRM_CHANGES':
      return {
        ...state,
        isConfirmed: true,
      };
      case 'RESET_BUTTONS':
        return {
          ...state,
          hasPendingChanges: false,
          isConfirmed: false,
        };
    case 'LOAD_DATA':
      return {
        ...state,
        investment: action.payload.investment,
        agreement: action.payload.agreement,
        support: action.payload.support,
        other: action.payload.other,
        isYearChanged: false,
        isLoaded: true,
        hasPendingChanges: false, // Only mark as pending if data is loaded
        isConfirmed: false,
      };
    default:
      return state;
  }
}

const mapBudgetData = (name: string, data: any, categoryKey: string): IBudgetSelectedMonth => {
  return {
    name,
    jan: { value: data[`jan${categoryKey}`] || 0 },
    feb: { value: data[`feb${categoryKey}`] || 0 },
    mar: { value: data[`mar${categoryKey}`] || 0 },
    apr: { value: data[`apr${categoryKey}`] || 0 },
    may: { value: data[`may${categoryKey}`] || 0 },
    jun: { value: data[`jun${categoryKey}`] || 0 },
    jul: { value: data[`jul${categoryKey}`] || 0 },
    aug: { value: data[`aug${categoryKey}`] || 0 },
    sep: { value: data[`sep${categoryKey}`] || 0 },
    oct: { value: data[`oct${categoryKey}`] || 0 },
    nov: { value: data[`nov${categoryKey}`] || 0 },
    dec: { value: data[`dec${categoryKey}`] || 0 },
  };
};

export const Budget = () => {
  //Initalisations
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const currentYear = new Date().getFullYear();


  //Api

  const { data: budgetDataData, error, isError } = useGet(rq.getBudgetData(Number(state.year), state.isYearChanged), state.isYearChanged);
  const postBudget = usePost(rqPost.postBudget(true), true);


//Useffects
  useEffect(() => {


    console.log("2023: BudgetData =>", budgetDataData)

    if(isError) {
      console.log(error)
      dispatch({type: "SET_EMPTY_YEAR", payload: {year: Number(state.year)}})
      console.log("2023: Could not load year", error)
    } else if (budgetDataData != null) {
      console.log("2023: Load year success", budgetDataData)
      const investmentData = mapBudgetData('Investment', budgetDataData, 'Inv');
      const agreementData = mapBudgetData('Agreement', budgetDataData, 'Agr');
      const supportData = mapBudgetData('Support', budgetDataData, 'Sup');
      const otherData = mapBudgetData('Other', budgetDataData, 'Oth');

      dispatch({ type: 'LOAD_DATA', payload: { investment: investmentData, agreement: agreementData, support: supportData, other: otherData } });
    }
   
  }, [budgetDataData, isError, error, dispatch]);

  //Handles

  const submit = () => {
    let readyToPostBudget = ConvertStateToBudgetPost(state);
    console.log('2023: readyToPostBudget', readyToPostBudget);
    console.log("2023: hasPendingChanges", state.hasPendingChanges)
    console.log("2023: isConfirmed", state.isConfirmed)

    if (state.hasPendingChanges && state.isConfirmed) {
      postBudget.mutate(readyToPostBudget, {
        onSuccess: (res: any) => {
          console.log('2023: SUCCESS', res);
          dispatch({ type: 'RESET_BUTTONS' });

        },
        onError: (error: any) => {
          console.log('POST failed');
        },
      });
    }
  };


  const handleConfirm = () => {
    dispatch({ type: 'CONFIRM_CHANGES' });
  };

  return (
    <Grid item xs={12}>
      <Paper variant="elevation3">
        <Typography sx={{ ...Header.text, }}>
          <SavingsIcon sx={{ ...Header.icon }} />
          {t("Settings.Budget.BudgetComponent.Header")}
          {" "}
          {state.year}
        </Typography>
      </Paper>
      <ToggleButtonGroup
        color="primary"
        value={state.year}
        exclusive
        onChange={(e: any) => dispatch({ type: 'SET_SELECTED_YEAR', payload: { year: Number(e.target.value) } })}
        aria-label="Year Toggle"
      >
          <ToggleButton value={dayjs().year() - 1}>{dayjs().year() - 1}</ToggleButton>
          <ToggleButton value={dayjs().year()}>{dayjs().year()}</ToggleButton>
          <ToggleButton value={dayjs().year() + 1}>{dayjs().year() + 1}</ToggleButton>
      </ToggleButtonGroup>
      {/* Body */}
      <Paper variant="elevation3" sx={{ p: 1, mt: 1 }}>
        <SingleMonthBudgetView budgetData={state.investment} dispatch={dispatch} />
      </Paper>
      <Paper variant="elevation3" sx={{ p: 1, mt: 1 }}>
        <SingleMonthBudgetView budgetData={state.agreement} dispatch={dispatch} />
      </Paper>
      <Paper variant="elevation3" sx={{ p: 1, mt: 1 }}>
        <SingleMonthBudgetView budgetData={state.support} dispatch={dispatch} />
      </Paper>
      <Paper variant="elevation3" sx={{ p: 1, mt: 1, mb: 1 }}>
        <SingleMonthBudgetView budgetData={state.other} dispatch={dispatch} />
      </Paper>
        <Grid>
        <Grid>
         {state.hasPendingChanges && !state.isConfirmed &&
          <Typography>{t("Settings.Budget.BudgetComponent.confirmText")}</Typography>
         }
          
          <Button variant='contained' onClick={handleConfirm} disabled={state.isConfirmed || !state.hasPendingChanges}>{t("Settings.Budget.BudgetComponent.confirmBttn")}</Button>
        </Grid>
      {state.isConfirmed && state.hasPendingChanges && (
        <Grid>
          <Typography>{t("Settings.Budget.BudgetComponent.saveText")}</Typography>
          <Button variant='contained' onClick={submit}>{t("Settings.Budget.BudgetComponent.saveBttn")}</Button>
        </Grid>
      )}
        </Grid>
    </Grid>
  );
};