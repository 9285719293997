import { Grid, Stack, Switch, Typography } from '@mui/material';
import { TextStyle, gridStyle, switchStyle } from 'data/FontStyle';
import { useTranslation } from 'react-i18next';

interface SwitchTypeProps {
  isChecked: boolean;
  dispatch: any;
  type: string;
  header: string | null;
  showNotification: boolean;
  disabled: boolean; 
}
export const SwitchType: React.FC<SwitchTypeProps> = ({
  isChecked,
  dispatch,
  type,
  header,
  showNotification,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: type, payload: e.target.checked });
  };

  isChecked = disabled ? false : isChecked;

  return (
    <Grid
      item
      sx={
        header
          ? [
              switchStyle.base,
              {
                height: 60,
                backgroundColor: showNotification
                  ? 'success.dark'
                  : 'rgba(0,0,0,.2)',
                border: 2,
                borderColor: showNotification
                  ? 'success.light'
                  : 'rgba(0,0,0,.05)',
              },
            ]
          : [switchStyle.base, { backgroundColor: 'transparent', border: 0 }]
      }
    >
      {header && (
        <Typography sx={[switchStyle.header, { pl: 1, pt: 1 }]}>
          {header}
        </Typography>
      )}
      <Stack sx={{ flexDirection: 'row-reverse' }}>
        <Switch
          disabled={disabled}
          color={isChecked ? 'secondary' : 'primary'}
          sx={{
            '& .MuiSwitch-thumb': {
              backgroundColor: isChecked ? 'secondary.light' : 'primary.text', // Use any color you like for 'onHold' state
            },
          }}
          checked={isChecked}
          onChange={handleToggle}
          name='checkedA'
        />
        <Typography sx={[switchStyle.label, { pl: 1, pt: 0.3 }]}>
          {isChecked ? `${t('switch.yes')}` : `${t('switch.no')}`}
        </Typography>
      </Stack>
    </Grid>
  );
};
