import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EmployeeForm } from './EmployeeForm';
import { AssignProducts } from './AssignProducts'; // Import the AssignProducts component
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { IDepartmentViewModel } from '../../Interfaces/ICompaniesConfig';
import { EnumObjectType, objectArray } from 'Interfaces/IGlobalTypeConfig';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';
import _ from 'lodash';
import { useGetObjectList } from 'functions/useGetObjectList';

export const Employee = ({ assignMode }: { assignMode: boolean }) => {
  /* INITIALIZATIONS */
  const { companyId, employeeId } = useParams();
  const [employee, setEmployee] = useState<IEmployeeViewModel>(
    null as unknown as IEmployeeViewModel
  );
  const [departments, setDepartments] = useState<IDepartmentViewModel[]>(
    null as unknown as IDepartmentViewModel[]
  );

  /* API */
  const [isObjectList, setIsObjectList] = useState<boolean>(true);
  let departmentId = 'new';
  let resourceId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );

  useEffect(() => {
    setIsObjectList(false);
  }, [objectDrawerList]);

  let isEnabled =
    companyId !== undefined &&
    employeeId !== undefined &&
    companyId !== 'new' &&
    employeeId !== 'new'
      ? true
      : false;
  // Check if both companyId and employeeId are valid for fetching data
  const isValidId = companyId !== 'new' && employeeId !== 'new';

  const { data: employeeData } = useGet(
    rq.employeeWithProducts(companyId || 'new', employeeId || 'new', isEnabled),
    isEnabled
  );
  const { data: departmentData } = useGet(
    rq.departments(companyId || 'new', isEnabled),
    isEnabled
  );

  useEffect(() => {
    if (employeeData !== undefined) {
      console.log('employee.tsx, employeeData changed', employeeData);
      let tmp = _.cloneDeep(employeeData);
      setEmployee(tmp);
    }
  }, [employeeData]);

  useEffect(() => {
    let tmp = _.cloneDeep(departmentData?.departments);
    setDepartments(tmp);
  }, [departmentData]);

  useEffect(() => {}, [employeeId]);

  if (employee === null && departments === null && isObjectList === true) {
    return <div>loading...</div>;
  }

  return assignMode ? (
    <AssignProducts
      companyId={companyId!}
      employeeId={employeeId!}
      employee={employee}
    />
  ) : (
    <EmployeeForm
      employee={employee}
      departments={departments}
      objectDrawerList={objectDrawerList}
      company={objectDrawerList[EnumObjectType.SelectedCompany].objectList[0]}
    />
  );
};
