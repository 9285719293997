import { Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import { IOrderPostTicketViewModel } from 'Interfaces/IOrderConfig';
import { useTranslation } from 'react-i18next';
import GetPriorityColor from 'functions/GetPriorityColor';
import { iProductSimpleViewModel } from 'Interfaces/IProductConfig';
type FormOrMouseEvent =
  | React.FormEvent<HTMLFormElement>
  | React.MouseEvent<HTMLDivElement, MouseEvent>;
type Props = {
  orderPost: IOrderPostTicketViewModel;
  handleOrderTicketAssign: {
    (
      event: FormOrMouseEvent,
      orderPost: IOrderPostTicketViewModel,
      product: iProductSimpleViewModel
    ): void;
  } | null;
};

export const SingleOrderTicketView = ({
  orderPost,
  handleOrderTicketAssign,
}: Props) => {
  // INITIALIZATION
  const { t } = useTranslation();
  console.log('SingleOrderTicketView', orderPost);
  // API

  // HANDLES

  // EFFECTS

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        mb: 2,
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        zIndex: 1,
        backgroundColor: 'primary.main',

        borderColor: GetPriorityColor(orderPost.companyInfo.priority),
        boxShadow:
          '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        ':hover': {
          /*  cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out', */
        },
      }}
    >
      {/* HEADER */}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'space-between' }}>
        <Grid item xs={1} sx={{}}>
          <img
            src={orderPost.companyInfo?.url ?? MissingPhoto.COMPANY_URL}
            alt='company'
            style={{ width: 64, height: 32, borderRadius: 3 }}
          />
        </Grid>
        <Grid item xs={7} sx={{}}>
          <Typography
            sx={{
              color: 'white',
              fontWeight: 'bold',
              letterSpacing: 2,
              fontSize: 16,
              ml: 2,
              mt: 0.5,
              mb: 1,
            }}
          >
            {orderPost.companyInfo.name}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{}}>
          <Typography
            sx={{
              color: 'white',
              textAlign: 'right',
              fontWeight: 300,
              fontSize: 14,
              ml: 2,
              mt: 0.5,
              mb: 1,
              mr: 1,
            }}
          >
            {orderPost.ticketNo}
          </Typography>
        </Grid>
      </Grid>
      {/* HEADER */}

      {orderPost.ticketProducts.map(
        (product: iProductSimpleViewModel, index: number) => (
          <Grid
            item
            key={index}
            xs={12}
            onClick={(e) => {
              product.orderedQuantity < product.originalQuantity &&
                handleOrderTicketAssign &&
                handleOrderTicketAssign(e, orderPost, product);
            }}
            sx={{ display: 'flex', alignItems: 'space-between' }}
          >
            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                m: 1,
                height: 66,
                borderRadius: 2,
                zIndex: 1,
                backgroundColor: product.isAddedToOrderList
                  ? product.orderedQuantity >= product.originalQuantity - product.alreadyOrderedQuantity
                    ? 'success.dark'
                    : 'warning.dark'
                  : 'rgba(0,0,0,.2)',
                border: 1,
                borderColor: 'rgba(0,0,0,.2)',
                boxShadow:
                  '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                ':hover': {
                  cursor: 'pointer',
                  filter: 'brightness(120%)',
                  transition: 'all 0.2s ease-in-out',
                  borderColor: GetPriorityColor(orderPost.companyInfo.priority),
                },
              }}
            >
              <Grid item xs={1} sx={{}}>
                <img
                  src={product?.url ?? MissingPhoto.PRODUCT_URL}
                  alt='info'
                  style={{
                    width: 64,
                    height: 64,
                    minHeight: 64,
                    minWidth: 64,
                    borderRadius: 8,
                    backgroundColor: 'red',
                    padding: 0,
                    margin: 0,
                  }}
                />
              </Grid>
              <Grid item sx={{ flexGrow: 1 }}>
                <Stack direction='column' spacing={0}>
                  <Typography
                    sx={{
                      color: 'white',
                      fontWeight: 'bold',
                      letterSpacing: 2,
                      fontSize: 14,
                      height: 14,
                      ml: 2,
                      mt: 0.5,
                      mb: 1,
                    }}
                  >
                    {product.name}
                  </Typography>
                  <Stack direction='row' spacing={0} sx={{ ml: 2 }}>
                    <img
                      src={product.productOwner?.url ?? MissingPhoto}
                      alt='info'
                      style={{
                        marginLeft: 20,
                        width: 38,
                        height: 38,
                        minHeight: 38,
                        minWidth: 38,
                        borderRadius: 5,
                        backgroundColor: 'red',
                        padding: 0,
                        margin: 0,
                      }}
                    />
                    <Stack direction='column' spacing={0}>
                      <Typography
                        sx={{
                          color: 'white',
                          fontWeight: 'normal',
                          letterSpacing: 2,
                          fontSize: 12,
                          height: 14,
                          ml: 1,
                        }}
                      >
                        {product.productOwner?.name ?? ''}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'white',
                          fontWeight: 'normal',
                          letterSpacing: 2,
                          fontSize: 12,
                          height: 14,
                          ml: 1,
                        }}
                      >
                        {product.productOwner?.phone ?? ''}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item sx={{}}>
                <Stack direction='column' spacing={0}>
                  <Typography
                    sx={{
                      color: 'white',
                      textAlign: 'right',
                      fontWeight: 300,
                      fontSize: 12,
                      height: 14,
                      ml: 2,
                      mt: 0.5,
                      mr: 1,
                    }}
                  >
                    {product.brand.brandName}
                  </Typography>
                  <Stack direction={'row'} sx={{ pt: 1 }}>
                    {/* QUANTITY NEEDED */}
                    <Stack direction={'column'} sx={{ pr: 0.5 }}>
                      <Typography
                        sx={{
                          width: '100%',
                          letterSpacing: 1,
                          pl: 1,
                          textAlign: 'left',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontWeight: 'normal',
                          textTransform: 'capitalize',
                          fontSize: 12,
                          color: 'primary.text',
                        }}
                      >
                        {t('Products.ProductForm.quantity')}
                      </Typography>
                      <Typography
                        sx={{
                          width: '100%',
                          letterSpacing: 1,
                          pl: 1,
                          textAlign: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                          fontSize: 12,
                          color: 'primary.text',
                        }}
                      >
                        {product.originalQuantity}
                      </Typography>
                    </Stack>
                    {/* QUANTITY ORDERED */}
                    <Stack direction={'column'} sx={{ pl: 0.5 }}>
                      <Typography
                        sx={{
                          width: '100%',
                          letterSpacing: 1,
                          pl: 1,
                          textAlign: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontWeight: 'normal',
                          textTransform: 'capitalize',
                          fontSize: 12,
                          color: 'primary.text',
                        }}
                      >
                        {t('Products.ProductForm.quantityOrdered')}
                      </Typography>
                      <Typography
                        sx={{
                          width: '100%',
                          letterSpacing: 1,
                          pl: 1,
                          textAlign: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                          fontSize: 12,
                          color: 'primary.text',
                        }}
                      >
                        {product.orderedQuantity + product.alreadyOrderedQuantity}
                      </Typography>
                    </Stack>
                    {/* QUANTITY */}
                    <Stack direction={'column'} sx={{ pl: 0.5 }}>
                      <Typography
                        sx={{
                          width: '100%',
                          letterSpacing: 1,
                          pl: 1,
                          textAlign: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontWeight: 'normal',
                          textTransform: 'capitalize',
                          fontSize: 12,
                          color: 'primary.text',
                        }}
                      >
                        {t('Products.ProductForm.needed')}
                      </Typography>
                      <Typography
                        sx={{
                          width: '100%',
                          letterSpacing: 1,
                          pl: 1,
                          textAlign: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                          fontSize: 12,
                          color: 'primary.text',
                        }}
                      >
                        {product.originalQuantity - product.orderedQuantity - product.alreadyOrderedQuantity}
                      </Typography>
                    </Stack>
                    {/* UNITPRICE */}
                    <Stack direction={'column'} sx={{ pl: 0.5, width: 90 }}>
                      <Typography
                        sx={{
                          width: '100%',
                          letterSpacing: 1,
                          pl: 1,
                          textAlign: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontWeight: 'normal',
                          textTransform: 'capitalize',
                          fontSize: 12,
                          color: 'primary.text',
                        }}
                      >
                        {t('Products.ProductForm.costPrice')}
                      </Typography>
                      <Typography
                        sx={{
                          width: '100%',
                          letterSpacing: 1,
                          pl: 1,
                          textAlign: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                          fontSize: 12,
                          color: 'secondary.text',
                        }}
                      >
                        {product.unitPrice.toFixed(2)}
                      </Typography>
                    </Stack>
                    {/* UNITPRICE */}
                    <Stack direction={'column'} sx={{ pl: 0.5, width: 90 }}>
                      <Typography
                        sx={{
                          width: '100%',
                          letterSpacing: 1,
                          pl: 1,
                          textAlign: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontWeight: 'normal',
                          textTransform: 'capitalize',
                          fontSize: 12,
                          color: 'primary.text',
                        }}
                      >
                        {t('Products.ProductForm.retailPrice')}
                      </Typography>
                      <Typography
                        sx={{
                          width: '100%',
                          letterSpacing: 1,
                          pl: 1,
                          textAlign: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                          fontSize: 12,
                          color: product.isAddedToOrderList
                            ? product.orderedQuantity >=
                              product.originalQuantity
                              ? product.retailPrice <= product.unitPrice
                                ? 'warning.text'
                                : 'secondary.text'
                              : product.retailPrice <= product.unitPrice
                              ? 'warning.text'
                              : 'secondary.text'
                            : product.retailPrice <= product.unitPrice
                            ? 'warning.text'
                            : 'secondary.text',
                        }}
                      >
                        {product.retailPrice.toFixed(2)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            </Paper>
          </Grid>
        )
      )}
    </Grid>
  );
};
