import * as React from 'react';
import { useEffect, useState } from 'react';
import { Grid, Avatar, Stack, Typography } from '@mui/material';
import GetColorByName from 'functions/GetColorByName';
import getInitials from 'functions/GetInitials';
import { EnumPlacement, MissingPhoto } from 'Interfaces/IGlobalTypeConfig';

export const SingleTicketContactProduct = (props: any) => {
  const product = props.product;

  // console.log('Product.url =>', product?.product?.url);

  if (!product) {
    return null;
  }
  // console.log('product?.product?.url => ', product);
  return (
    <Grid
      container
      flexGrow={1}
      direction={'row'}
      sx={{
        borderLeft: 8,
        borderRight: 2,
        borderRadius: 2,
        borderColor: GetColorByName(
          product?.productType?.name || product?.product?.productType?.name
        ),
        backgroundColor:
          product?.owner || product?.placement === (3 as EnumPlacement)
            ? 'rgba(0, 0, 0, 0.2)'
            : 'rgba(0, 0, 0, 0.2)',
        height: 80,
      }}
    >
      <Grid
        item
        sx={{
          backgroundColor: 'primary.light',
          width: 80,
          height: 80,
        }}
      >
        <img
          src={product?.product?.url || MissingPhoto.PRODUCT_URL}
          style={{
            borderRadius: 3,
            objectFit: 'scale-down',
            width: '100%',
            height: '100%',
          }}
          alt={'previewUrl'}
        />
      </Grid>
      <Grid item flex={1} sx={{}}>
        <Grid container display={'flex'} direction={'row'}>
          <Grid
            item
            xs={10}
            sx={{
              pl: 1,
              height: 80,
            }}
          >
            <Typography
              sx={{
                letterSpacing: 1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: 14,
              }}
            >
              {product?.product?.name}
            </Typography>

            <Typography
              sx={{
                pt: 0.5,
                letterSpacing: 1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: 12,
              }}
            >
              TAG: {product?.serviceTag || 'no tag'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
