import { useEffect, useState } from 'react';
import { Grid, List, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useWindowsDimension from 'functions/useWindowsDimension';
import { SearchFilter } from 'components/ui/SearchFilter';
import ApiIcon from '@mui/icons-material/Api';
import { IModule, modulesAvailable } from './modulesAvailable';
import { ModuleListItem } from './ModuleListItem';

export const ModuleList = () => {
  const { t } = useTranslation();
  const { height } = useWindowsDimension();
  const [selectedModule, setSelectedModule] = useState<IModule | null>(null);
  const [modules, setModules] = useState<IModule[]>(modulesAvailable);

  return (
    <Grid item xs={12}>
      <Paper
        variant='elevation3'
        sx={{
          backgroundColor: 'primary.main',
          height: Math.ceil(height - 28),
          overflow: 'auto',
        }}
      >
        <SearchFilter Icon={ApiIcon} Header={t('modules.heading')} />
        <List
          sx={{
            p: 2,
            height: Math.ceil(height - 128),
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            overflow: 'auto',
          }}
        >
          {modules.map((module: IModule) => (
            <ModuleListItem
              key={module.id}
              module={module}
              selectedModule={selectedModule}
              setSelectedModule={setSelectedModule}
            />
          ))}
        </List>
      </Paper>
    </Grid>
  );
};

