import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import useWindowsDimension from 'functions/useWindowsDimension';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { useNavigate, useParams } from 'react-router';
import { MultiBarView } from 'components/ui/Charts/MultiBarView';
import { IMultiChartBar } from 'Interfaces/IChartConfig';
import { ConvertToMultiBarData } from 'functions/ConvertToMultiBarData';
import { dummyMultiChartBar1 } from 'components/ui/Charts/Components/YearChartDummyData';

export const CompanyFinance = () => {
  // INITIALIZATION
  const { height } = useWindowsDimension();
  let navigate = useNavigate();
  const { companyId } = useParams();
  const [detailLevel, setDetailLevel] = React.useState(0);
  const [isReady, setIsReady] = React.useState(false);
  const [mulitBarData, setMultiBarData] = React.useState<IMultiChartBar>(
    {} as IMultiChartBar
  );
  // CONST
  const parentRef = useRef<HTMLDivElement>(null); // Spesifiserer HTMLDivElement som type for ref
  const [parentWidth, setParentWidth] = useState(0);

  useLayoutEffect(() => {
    const updateParentWidth = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    };

    updateParentWidth(); // Oppdater ved mount
    window.addEventListener('resize', updateParentWidth); // Oppdater ved vindu endres

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);
  // DATA
  let thisYear = new Date().getFullYear();
  let isEnabled = detailLevel === -1 ? false : true;
  const { data: receivedYearData } = useGet(
    rq.getYearData(companyId || '', thisYear, thisYear, detailLevel, isEnabled),
    isEnabled
  );
  isEnabled = true;
  const { data: budgetData } = useGet(
    rq.getBudgetData(thisYear, isEnabled),
    isEnabled
  );
  console.log('budgetData =>', budgetData);

  useEffect(() => {
    if (receivedYearData && budgetData) {
      let mbData = ConvertToMultiBarData(receivedYearData.years[0], budgetData);
      console.log('mbData => mbData', mbData);
      console.log('mbData => dummyMultiChartBar', dummyMultiChartBar1);
      console.log('mbData => receivedYearData', receivedYearData);
      setMultiBarData(mbData);
      setIsReady(true);
    }
  }, [receivedYearData, budgetData]);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        m: 0,
        height: Math.ceil(height - 124),
        backgroundColor: 'red',
      }}
    >
      {/* HEADER  */}
      <Grid
        item
        xs={12}
        sx={{
          width: '100%',
          height: 96,
          p: 0.5,
        }}
      >
        <Paper variant='elevation3' sx={{ width: '100%', height: '100%' }}>
          HEADER
        </Paper>
      </Grid>

      <Grid item ref={parentRef} xs={6} sx={{ pr: 0.5 }}>
        {isReady && (
          <MultiBarView data={mulitBarData} width={parentWidth} height={350} />
        )}
      </Grid>

      <Grid
        item
        xs={6}
        sx={{
          p: 0.5,
          height: (height - 124 - 96) / 3,
        }}
      >
        <Paper
          variant='elevation3'
          sx={{ width: '100%', height: '100%' }}
        ></Paper>
      </Grid>

      <Grid
        item
        xs={6}
        sx={{
          p: 0.5,
          height: (height - 124 - 96) / 3,
        }}
      >
        <Paper
          variant='elevation3'
          sx={{ width: '100%', height: '100%' }}
        ></Paper>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          p: 0.5,
          height: (height - 124 - 96) / 3,
        }}
      >
        <Paper
          variant='elevation3'
          sx={{ width: '100%', height: '100%' }}
        ></Paper>
      </Grid>

      <Grid
        item
        xs={6}
        sx={{
          p: 0.5,
          height: (height - 124 - 96) / 3,
        }}
      >
        <Paper
          variant='elevation3'
          sx={{ width: '100%', height: '100%' }}
        ></Paper>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          p: 0.5,
          height: (height - 124 - 96) / 3,
        }}
      >
        <Paper
          variant='elevation3'
          sx={{ width: '100%', height: '100%' }}
        ></Paper>
      </Grid>
    </Grid>
  );
};
