import {
  Grid,
} from '@mui/material';
import useWindowsDimension from 'functions/useWindowsDimension';
import React from 'react';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import { PageHeader } from 'components/ui/Global/PageHeader';
import { OrderHeaderButtonDetails } from './Components/OrderStyleConfig';
import { useTranslation } from 'react-i18next';



export const OrderDetail = () => {
const { t } = useTranslation();

const OrderHeaderLinks = OrderHeaderButtonDetails(null,null).map(button => ({
  ...button,
  name: t(button.name), // Localize the name here
}));

  // INITIALIZATION
  let navigate = useNavigate();
  const { height, width } = useWindowsDimension();

  // API

  // HANDLES

  // EFFECTS

  return (
    <Grid container direction='row' spacing={0}>
      <Grid item xs={12} sx={{ }}>
        <PageHeader
          Icon={ReceiptRoundedIcon}
          title={t("Orders.orderDetail.orders")}
          FormJSX={null}
          HeaderButtons={OrderHeaderLinks}
        />
      </Grid>
  
      <Grid item xs={12} sx={{}}>
        <Outlet />
      </Grid>
    </Grid>
  );
  
  
};
