import { useParams } from 'react-router-dom';
import { useGet } from 'http/useInnovit';
import { CompanyForm } from './CompanyForm';
import { rq } from 'http/apiRoutes';

import useWindowsDimension from 'functions/useWindowsDimension';
import {  useEffect, useState } from 'react';
import {
  EnumObjectType,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import _ from 'lodash';
import { ICompanyViewModel } from 'Interfaces/ICompaniesConfig';
import { IEmployeeViewModel } from 'Interfaces/IEmployeeConfig';
import { useGetObjectList } from 'functions/useGetObjectList';
import { useTranslation } from 'react-i18next';

export const CompanyDetails = () => {
  const { t } = useTranslation();

  /* INITIALIZATIONS */
  const { companyId } = useParams<string>();
  const { height } = useWindowsDimension();
  
  // STATES
  const [employees, setEmployees] = useState<IEmployeeViewModel[]>(
    null as unknown as IEmployeeViewModel[]
  );
  const [company, setCompany] = useState<ICompanyViewModel>(
    null as unknown as ICompanyViewModel
  );
  
  // api
  let isEnabled =
    (companyId !== undefined && companyId !== 'new') ? 
    true : false;
    const { data: employeeData } = useGet(
      rq.employees(companyId || '', isEnabled),
      isEnabled
    );

    const [isObjectList, setIsObjectList] = useState<boolean>(true);
  let employeeId = 'new';
  let departmentId = 'new';
  let resourceId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );

  // USEEFFECTS

  useEffect(() => {
    if (
      objectDrawerList[EnumObjectType.SelectedCompany].objectList.length > 0
    ) {
      let comp = objectDrawerList[EnumObjectType.SelectedCompany].objectList[0];
      setCompany(comp);
      setIsObjectList(false);
      let tmp = _.cloneDeep(employeeData?.allEmployees || []);
      setEmployees(tmp);
      objectDrawerList[EnumObjectType.CompanyEmployees].objectList = tmp;
      console.log('objectDrawerList !!', objectDrawerList);
    }
  }, [objectDrawerList, employeeData]);

  /* RENDER */
  if (company === null && isObjectList === true && companyId !== 'new') {
    return <div>loading...</div>;
  }
  return (
    <CompanyForm
      company={company}
      employees={employees}
      objectDrawerList={objectDrawerList}
      setIsObjectList={setIsObjectList}
    />
  );
};
