import React, { useEffect, useReducer, useState } from 'react';
import { Grid, List, Paper, ListItem, ListItemText, Button, TextField, Typography, Switch, Stack } from '@mui/material';
import useWindowsDimension from "functions/useWindowsDimension";
import { useGet, usePut, useDel, usePost } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import { SearchFilter } from 'components/ui/SearchFilter';
import EngineeringIcon from "@mui/icons-material/Engineering";
import { SingleBrandView } from 'components/ui/SingleBrandView';
import { IBrandViewModel, IEnumBrandState } from './setupConfig';
import { FormBoxElevated } from 'components/form';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { useTranslation } from 'react-i18next';

let _ = require("lodash");


type Action =
  | { type: 'SELECT_BRAND'; payload: IBrandViewModel }
  | { type: 'UPDATE_BRAND_NAME'; payload: string }
  | { type: 'SAVE_BRAND' }
  | { type: 'DELETE_BRAND' }
  | { type: 'RESET_BRAND' }
  | { type: 'TOGGLE_NOT_ACTIVE'; payload: boolean }
  | { type: 'TOGGLE_ACTIVE'; payload: boolean };


function stateReducer(
  state: IBrandViewModel,
  action: Action
): IBrandViewModel {
  switch (action.type) {
    case 'SELECT_BRAND':
      return {
        ...state,
        id: action.payload.id,
        brandName: action.payload.brandName,
        isActive: action.payload.isActive
      } 
      case 'UPDATE_BRAND_NAME':
        return {
          ...state,
          brandName: action.payload,
        };
        case 'TOGGLE_NOT_ACTIVE':
          return {
            ...state,
            isActive: action.payload,
          };
          case 'TOGGLE_ACTIVE':
          return {
            ...state,
            isActive: action.payload,
          };

    case 'SAVE_BRAND':
      return state;
    case 'DELETE_BRAND':
      return state;
    case 'RESET_BRAND':
      return initialBrandState; 
    default:
      return state;
  }
}

const initialBrandState: IBrandViewModel = {
    id: 'new',
    brandName: '',
    isActive: true,
  };
  interface OHjsxProps {
    isActive: boolean;
    dispatch: React.Dispatch<Action>;
  }

export const Brand = () => {
  const { t } = useTranslation();
  // Initializers
  const { height } = useWindowsDimension();
  const [allBrands, setAllBrands] = useState<IBrandViewModel[]>([]);
  const [state, dispatch] = useReducer(stateReducer, initialBrandState);
  const [isConfirmDelete, setConfirmDelete] = useState(false);
  const [selectedBrandState, setSelectedBrandState] = useState(1);
  const brandStateList = Object.keys(IEnumBrandState).filter(key => isNaN(Number(key))) // Filter out the numeric keys  
  .map(key => ({     id: IEnumBrandState[key as keyof typeof IEnumBrandState],name: key   }));
  // API
  const [isEnabled, setIsEnabled] = useState(true);
  const { data: brandData } = useGet(rq.getAllBrands(selectedBrandState, isEnabled), isEnabled);
  const putBrands = usePut(rqPost.brandPut(isEnabled), isEnabled);
  const deleteBrand = useDel(rqPost.brandPut(isEnabled), isEnabled);
  const postBrand = usePost(rqPost.brandPost(isEnabled), isEnabled)


  useEffect(() => {
    if (brandData) {
      setAllBrands(brandData.map((item: IBrandViewModel) => ({
        id: item.id,
        brandName: item.brandName,
        isActive: item.isActive,
      })));
      setIsEnabled(false);
    }
  }, [brandData]);
  



  const handleSaveBrand = (e: React.MouseEvent) => {
    e.preventDefault();
    if (state.id === 'new') {
      const newBrand = { brandName: state.brandName, isActive: true};
        postBrand.mutate(newBrand, {
            onSuccess: (response: IBrandViewModel) => {
            dispatch({ type: 'SELECT_BRAND', payload: initialBrandState });
            },
        });
    } else {
      setIsEnabled(true)
      putBrands.mutate(state, {
        onSuccess: (response: IBrandViewModel) => {
          dispatch({ type: 'SELECT_BRAND', payload: initialBrandState });
        },
      });
    }
  };

  const handleNewBrand = async (e: React.MouseEvent) => {
    e.preventDefault();
    setConfirmDelete(false);
    dispatch({
      type: 'SELECT_BRAND',
      payload: initialBrandState,
    });
  }
  

  const handleDeleteBrand = () => {
    if (state.id != "new") {
      setConfirmDelete(true);
    }
  };

  const handleConfirmDelete = () => {
    if (state.id) {
        dispatch({ type: 'SELECT_BRAND',
         payload: { 
         id: '', 
         brandName: '', 
         isActive: false, 
        }});
        let _state = _.cloneDeep(state)
        _state.isActive = false;
        putBrands.mutate(_state, {
          onSuccess: (response: IBrandViewModel) => {
            dispatch({ type: 'SELECT_BRAND', payload: initialBrandState });
          },
        });
    }
  };



  const handleFilterChange = (newBrandState: IEnumBrandState) => {
    setSelectedBrandState(newBrandState);
    setIsEnabled(true);
  };
  
  const OHjsx: React.FC<OHjsxProps> = ({isActive, dispatch }) => {
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsEnabled(true)
        dispatch({ type: 'TOGGLE_ACTIVE', payload: e.target.checked });
    };
    return (
      <Grid item sx={{ m: 0, p: 0 }}>
        <Stack direction='row'>
          <Typography
            sx={{
              m: 0,
              p: 2.2,
              pr: 0,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
              color: 'primary.text',
            }}
          >
            {isActive ? `${t("Settings.Brand.active")}` : `${t("Settings.Brand.notActive")}`}
          </Typography>
            <Switch
             color={isActive ? 'secondary' : 'primary'}
             sx={{
               mt: 1,
               mr: 1,
               '& .MuiSwitch-thumb': {
                 backgroundColor: isActive ? 'secondary.light' : 'primary.text', // Use any color you like for 'onHold' state
               },
               '& .MuiSwitch-track': {
                 backgroundColor: isActive
                   ? 'rgba(0, 0, 0, 0.7)'
                   : 'rgba(0, 0, 255, 0.3)', // This gives a lighter red shade for the track. Adjust as needed.
               },
             }}
              checked={state.isActive}
              onChange={handleToggle}
              name='isActive'
            />
        </Stack>
      </Grid>
    );
  };
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
        }}
      >
        {/* Left side - List of Brands */}
        <Grid
          item
          xs={4}
          sx={{
            p: 0,
            m: 0,
            pr: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
             <SearchFilter
            Icon={EngineeringIcon}
            filterValue={selectedBrandState}
            selectList={brandStateList} 
            Header={t("Settings.Brand.header")}
            handleFilterChange={handleFilterChange}
            handleNew={handleNewBrand}
          />
          <List
            sx={{
              height: Math.ceil(height / 2 - 148),
              backgroundColor: "primary.back",
              p: 1,
              overflow: "auto"
            }}
          >
            {allBrands.map((brand: IBrandViewModel, index: number) => (
              <Grid
                key={index}
                onClick={() => dispatch({ type: 'SELECT_BRAND', payload: brand }) }
              >
                
               <SingleBrandView brand={brand} />
              </Grid>
            ))}
          </List>
          {/* Left footer */}
          <Paper
            variant="elevation3"
            sx={{
              p: 2,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          ></Paper>
        </Grid>
        {/* Right side - Edit, Save, Delete */}
        <Grid
          item
          xs={2}
          sx={{
            p: 0,
            m: 0,
            pr: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SearchFilter
            Header={state.id === 'new' ? `${t("Settings.Brand.createBrand")}` : `${t("Settings.Brand.editBrand")}`}
          />
          <Paper
            variant="elevation3"
            sx={{
              p: 2,
              borderRadius: 0,
              height: Math.ceil(height / 2 - 148),
            }}
          >
            {state.id ? (
             <TextField
               label={t("Settings.Brand.brandName")}
               value={state.brandName}
               onChange={(e) => {
                 dispatch({
                   type: 'UPDATE_BRAND_NAME',
                   payload: e.target.value,
                 });
               }}
             />
           
            ) : (
              <Typography sx={{ color: "text.secondary" }}>
               {t("Settings.Brand.noBrandSelected")}
              </Typography>
            )}
             <FormBoxElevated
            Icon={ReportProblemRoundedIcon}
            title='isActive'
            FormJSX={() => <OHjsx isActive={state.isActive} dispatch={dispatch} />} // Pass it as a component
          />
          </Paper>
          {/* Right Footer */}
          <Paper
            variant="elevation3"
            sx={{
                p: 0,
                height: 64,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
            }}
            >
            {state.id !="new" ? (
                <Grid sx={{ display: 'flex', m:1 }}>
                <Button
                    variant="contained"
                    onClick={handleDeleteBrand}
                >
                  {t("Settings.Brand.delete")}

                </Button>
                {isConfirmDelete ? (
                    <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleConfirmDelete}
                    >
                     {t("Settings.Brand.confirm")}

                    </Button>
                ) : null}
                </Grid>
            ) : null}
            <Grid sx={{ flex: 1 }}></Grid>
            {state.id ? (
                <Button variant="contained" onClick={handleSaveBrand} sx={{m:1}}>
                {state.id === 'new' ? `${t("Settings.Brand.createBrand")}` : `${t("Settings.Brand.editBrand")}`}
                </Button>
            ) : null}
            </Paper>

        </Grid>
      </Grid>
    </>
  );
};
