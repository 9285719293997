import {
  EnumAddressType,
  EnumObjectType,
  EnumScheduleType,
  IAddressViewModel,
  IDeliveryAddressViewModel,
  IScheduleViewModel,
  MissingPhoto,
  addressListData,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { rq, rqPost } from 'http/apiRoutes';
import { useGet, usePost } from 'http/useInnovit';
import useWindowsDimension from 'functions/useWindowsDimension';
import {
  Button,
  Grid,
  List,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import { StorageList } from './StorageList';
import {
  IAssignStorageProductViewModel,
  IAssignStorageTicketViewModel,
  ISimpleStorageProductViewModel,
  IStorageProductViewModel,
} from 'Interfaces/IStorageConfig';
import { ICategoriesViewModel } from 'Interfaces/IGlobalTypeConfig';
import { ScheduleView } from 'components/ui/ScheduleView';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {
  IInterfaceViewModel,
  IStorageEmployeeViewModel,
} from 'Interfaces/IStorageConfig';
import { SearchFilter } from 'components/ui/SearchFilter';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import { FormBox } from 'components/form';
import { DeliveryAddressView } from 'components/ui/DeliveryAddressView';
import { SingleTicketContact } from 'components/ui/SingleTicketContact';
import ownerAtom from 'data/atoms/ownerAtom';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';
import dayjs from 'dayjs';
import { IBillingProductViewModel } from 'Interfaces/BillingConfig';
import { GenerateAddressList } from 'functions/Converters';
import { StorageFormStyle } from 'data/FontStyle';
import { useGetObjectList } from 'functions/useGetObjectList';
import { useTranslation } from 'react-i18next';
import { IEmployeeWithCompanyViewModel } from 'Interfaces/ITicketConfig';

let _ = require('lodash');

type Action =
  | { type: 'RESET_FORM' }
  | { type: 'EDIT_COMMENT'; payload: any }
  | { type: 'UPDATE_ESTIMATED_COST'; payload: number }
  | { type: 'UPDATE_ESTIMATED_VALUE'; payload: number }
  | { type: 'UPDATE_STORAGE_PRODUCT'; payload: IStorageProductViewModel }
  | { type: 'UPDATE_EMPLOYEES'; value: IStorageEmployeeViewModel[] }
  | { type: 'UPDATE_STORAGE_PRODUCTS'; value: IStorageProductViewModel[] }
  | { type: 'UPDATE_CATEGORIES'; value: ICategoriesViewModel[] }
  | { type: 'UPDATE_RETAIL_PRICE'; value: number }
  | { type: 'UPDATE_DISCOUNT'; value: number }
  | { type: 'UPDATE_RESOURCE_NAME'; value: string }
  | { type: 'UPDATE_EMPLOYEE'; value: IStorageEmployeeViewModel }
  | { type: 'UPDATE_ADDRESS_LIST'; payload: IDeliveryAddressViewModel[] }
  | { type: 'UPDATE_SCHEDULE_TIME'; payload: IScheduleViewModel }
  | { type: 'UPDATE_DELIVERY_ADDRESS'; payload: IDeliveryAddressViewModel }
  | { type: 'UPDATE_TICKET_ID'; payload: string }
  | {
      type: 'REMOVE_STORAGE_PRODUCTS';
      payload: ISimpleStorageProductViewModel;
    };

const _scheduleTime: IScheduleViewModel = {
  id: '0',
  scheduleType: EnumScheduleType.None,
  deliveryDate: dayjs(new Date()),
  startDate: dayjs(new Date()),
  estimatedTime: {
    hours: 0,
    minutes: 0,
  },
};
const _deliveryAddress: IDeliveryAddressViewModel = {
  id: '0',
  addressId: null,
  addressType: EnumAddressType.custom,
  street: '',
  postalCode: '',
  area: '',
  city: '',
  country: '',
  postBox: '',
  attention: '',
};
const _storageProduct: IStorageProductViewModel = {
  id: '',
  resourceName: '',
  serialNumber: null,
  product: {} as IBillingProductViewModel,
  purchaser: null,
  ticket: null,
  unitPrice: 0,
  discount: 0,
  retailPrice: 0,
  quantity: 0,
  FIFODate: dayjs(new Date()),
  reserved: null,
  url: null,
};

const initialState: IInterfaceViewModel = {
  scheduleTime: _scheduleTime,
  storageProduct: _storageProduct,
  deliveryAddress: _deliveryAddress,
  employee: null,
  company: null,
  employees: null as unknown as IStorageEmployeeViewModel[],
  categories: null as unknown as ICategoriesViewModel[],
  storageProducts: null as unknown as IStorageProductViewModel[],
  addressList: addressListData,
  ticketId: '',
  estimatedStorageCost: -1,
  estimatedStorageValue: -1,
  comment: '',
};

function stateReducer(
  state: IInterfaceViewModel,
  action: Action
): IInterfaceViewModel {
  switch (action.type) {
    case 'RESET_FORM':
      return {
        ...state,
        scheduleTime: _scheduleTime,
        storageProduct: _storageProduct,
        deliveryAddress: _deliveryAddress,
        employee: null,
        addressList: addressListData,
        ticketId: '',
      };
    case 'EDIT_COMMENT':
      return {
        ...state,
        comment: action.payload,
      };
    case 'UPDATE_ESTIMATED_COST':
      return { ...state, estimatedStorageCost: action.payload };
    case 'UPDATE_ESTIMATED_VALUE':
      return { ...state, estimatedStorageValue: action.payload };
    case 'UPDATE_STORAGE_PRODUCT':
      return { ...state, storageProduct: action.payload };
    case 'UPDATE_EMPLOYEES':
      return { ...state, employees: action.value };
    case 'UPDATE_STORAGE_PRODUCTS':
      return { ...state, storageProducts: action.value };
    case 'UPDATE_CATEGORIES':
      return { ...state, categories: action.value };
    case 'UPDATE_EMPLOYEE':
      return { ...state, employee: action.value };
    case 'UPDATE_RESOURCE_NAME':
      return {
        ...state,
        storageProduct: {
          ...state.storageProduct,
          resourceName: action.value,
        },
      };

    case 'UPDATE_DISCOUNT':
      if (state.storageProduct.product == null) return state;
      let newRetailPrice = Math.ceil(
        state.storageProduct.product.retailPrice -
          (state.storageProduct.product.retailPrice / 100) * action.value
      );
      return {
        ...state,
        storageProduct: {
          ...state.storageProduct,
          discount: action.value,
          retailPrice: newRetailPrice,
        },
      };
    case 'UPDATE_RETAIL_PRICE':
      if (state.storageProduct.product == null) return state;
      let newDiscount = Math.ceil(
        100 - action.value / (state.storageProduct.product.retailPrice / 100)
      );
      return {
        ...state,
        storageProduct: {
          ...state.storageProduct,
          retailPrice: action.value,
          discount: newDiscount,
        },
      };
    case 'UPDATE_ADDRESS_LIST':
      return {
        ...state,
        addressList: {
          ...action.payload,
        },
      };
    case 'UPDATE_SCHEDULE_TIME':
      return {
        ...state,
        scheduleTime: {
          ...action.payload,
        },
      };
    case 'UPDATE_DELIVERY_ADDRESS':
      return {
        ...state,
        deliveryAddress: {
          ...action.payload,
        },
      };
    case 'UPDATE_TICKET_ID':
      return {
        ...state,
        ticketId: action.payload,
      };
    case 'REMOVE_STORAGE_PRODUCTS':
      let tmp: IStorageProductViewModel[] = _.cloneDeep(state.storageProducts);
      tmp = tmp.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        storageProducts: tmp,
      };
    default:
      return state;
  }
}

export const StorageView = () => {
  const { t } = useTranslation();

  /* INITIALIZATION */
  let navigate = useNavigate();
  const { width, height } = useWindowsDimension();
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const [owner, setOwner] = useAtom(ownerAtom);
  const [user, setUser] = useAtom(userAtom);

  /* STATES */
  const [isStorageProducts, setIsStorageProducts] = useState<boolean>(true);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  /* API */

  // api
  const [isObjectList, setIsObjectList] = useState<boolean>(true);
  let companyId = 'new';
  let employeeId = 'new';
  let departmentId = 'new';
  let resourceId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );
  useEffect(() => {
    // setResource(objectDrawerList[Number(EnumObjectType.Resource)].objectList);
    setIsObjectList(false);
  }, [objectDrawerList]);

  const { data: storageProductData } = useGet(
    rq.storageProducts(isStorageProducts),
    isStorageProducts
  );
  let isEnabled = true;
  const { data: employeeData } = useGet(rq.allEmployees(isEnabled), isEnabled);
  const { data: categoryData } = useGet(rq.categories);
  const postAssignStorageProduct = usePost(
    rqPost.assignStorageProduct(isEnabled),
    isEnabled
  );

  /* FUNCTIONS */
  const isAssignButtonDisabled = () => {
    let isDisabled = true;
    if (
      !!state.storageProduct.id &&
      !!state.employee &&
      !!state.deliveryAddress?.street
    ) {
      isDisabled = false;
    }
    return isDisabled;
  };
  /* HANDLES */
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  const handleClick = (e: any, storageProduct: IStorageProductViewModel) => {
    e.preventDefault();

    dispatch({
      type: 'UPDATE_STORAGE_PRODUCT',
      payload: storageProduct,
    });
  };
  const handleChangeEmployee = (
    e: any,
    employee: IStorageEmployeeViewModel
  ) => {
    e.preventDefault();

    let tmp: IDeliveryAddressViewModel[] = GenerateAddressList(
      state.addressList,
      owner,
      employee
    );
    dispatch({
      type: 'UPDATE_ADDRESS_LIST',
      payload: tmp,
    });

    dispatch({
      type: 'UPDATE_EMPLOYEE',
      value: employee,
    });
  };
  const handleChangeDeliveryAddress = (
    deliveryAddress: IDeliveryAddressViewModel
  ) => {
    dispatch({
      type: 'UPDATE_DELIVERY_ADDRESS',
      payload: deliveryAddress,
    });
  };
  const handleChangeSchedule = (changeType: string, e: any) => {
    /*   dispatch({
      type: 'UPDATE_SCHEDULE_TIME',
      payload: scheduleTime,
    }); */
  };
  /* USE EFFECTS */

  useEffect(() => {
    if (!employeeData) return;
    let tmp: IStorageEmployeeViewModel[] = _.cloneDeep(
      employeeData?.allEmployees
    );
    dispatch({
      type: 'UPDATE_EMPLOYEES',
      value: tmp,
    });
  }, [employeeData]);

  useEffect(() => {
    if (!storageProductData) return;
    let tmp: IStorageProductViewModel[] = _.cloneDeep(
      storageProductData?.storageProducts
    );
    let cost = +storageProductData.totalUnitCost;
    dispatch({
      type: 'UPDATE_ESTIMATED_COST',
      payload: cost,
    });
    let value = +storageProductData.totalRetailCost;
    dispatch({
      type: 'UPDATE_ESTIMATED_VALUE',
      payload: value,
    });
    dispatch({
      type: 'UPDATE_STORAGE_PRODUCTS',
      value: tmp,
    });
  }, [storageProductData]);

  useEffect(() => {
    if (!categoryData) return;
    let tmp: ICategoriesViewModel[] = _.cloneDeep(
      categoryData?.productCategories
    );
    dispatch({
      type: 'UPDATE_CATEGORIES',
      value: tmp,
    });
  }, [categoryData]);

  useEffect(() => {}, [state.storageProducts]);
  const onSubmit = (e: any) => {
    e.preventDefault();
    let address: IAddressViewModel = {
      id: state.deliveryAddress?.addressId || null,
      street: state?.deliveryAddress?.street || '',
      postalCode: state?.deliveryAddress?.postalCode || '',
      area: state?.deliveryAddress?.area || '',
      city: state?.deliveryAddress?.city || '',
      country: state?.deliveryAddress?.country || '',
      postBox: state.deliveryAddress?.postBox || null,
    };

    let ticket: IAssignStorageTicketViewModel = {
      board: 1,
      priority: state?.employee?.company?.priority || 0,
      status: 0,
      companyId: state?.employee?.company?.id || '',
      contactId: state?.employee?.id || '',
      deliveryAddress: address,
      deliveryDate: state?.scheduleTime?.deliveryDate || dayjs(new Date()),
      scheduled: state?.scheduleTime?.startDate || dayjs(new Date()),
      ETCHours: state.scheduleTime?.estimatedTime?.hours || 0,
      ETCMinutes: state.scheduleTime?.estimatedTime?.minutes || 0,
      jobType: 0,
      comment: state.comment || '',
    };

    let storageProduct: ISimpleStorageProductViewModel = {
      id: state.storageProduct.id,
      serialNumber: state.storageProduct.serialNumber || '',
      productId: state.storageProduct?.product?.id || '',
      unitPrice: state.storageProduct.unitPrice,
      discount: state.storageProduct.discount || 0,
      retailPrice: state.storageProduct.retailPrice,
      ResourceName: state.storageProduct.resourceName || '',
    };
    let assignObject: IAssignStorageProductViewModel = {
      ticket: ticket,
      authorId: user.id,
      storageProduct: storageProduct,
    };
    postAssignStorageProduct.mutate(assignObject, {
      onSuccess: (res: any) => {
        dispatch({ type: 'RESET_FORM' });
        dispatch({
          type: 'REMOVE_STORAGE_PRODUCTS',
          payload: assignObject.storageProduct,
        });
        dispatch({
          type: 'EDIT_COMMENT',
          payload: ' ',
        });
      },
    });
  };
  return (
    <Grid container rowSpacing={0}>
      {/* LEFT SIDE */}
      <Grid item xs={6} sx={{ pr: 0.5, m: 0 }}>
        {state.categories != null &&
          state.storageProducts != null &&
          state.estimatedStorageCost > -1 &&
          state.estimatedStorageValue > -1 && (
            <StorageList
              categories={state.categories}
              storageProducts={state.storageProducts}
              cost={state.estimatedStorageCost}
              value={state.estimatedStorageValue}
              handleClick={handleClick}
            />
          )}
      </Grid>
      {/* RIGHT SIDE */}
      <Grid item xs={6} sx={{ pl: 0.5, m: 0 }}>
        <Paper
          variant='elevation3'
          sx={{
            m: 0,
            borderRadius: 3,
            backgroundColor: 'primary.main',
          }}
        >
          <SearchFilter
            Icon={AssignmentIndRoundedIcon}
            Header={t('Storage.StorageView.searchFilterHeader')}
          />
          <List
            sx={{
              height: Math.ceil(height - 238),
              width: '100%',
              mb: 0,
              p: 1,

              overflow: 'auto',
            }}
          >
            <FormBox
              Icon={DevicesOtherRoundedIcon}
              title={t('Storage.StorageView.selectedProduct')}
            />
            <Grid item xs={12} sx={{ p: 1, mt: -1, mb: 1 }}>
              <Grid container display={'flex'} flexDirection={'row'}>
                <Stack direction={'row'} flexGrow={1}>
                  <img
                    src={
                      state.storageProduct.product?.url ||
                      MissingPhoto.PRODUCT_URL
                    }
                    style={{
                      padding: 1,
                      width: 96,
                      height: 96,
                      borderRadius: 3,
                      objectFit: 'scale-down',
                    }}
                    alt={'previewUrl'}
                  />
                  <Grid
                    container
                    display={'flex'}
                    flexDirection={'row'}
                    sx={{ flexGrow: 1 }}
                  >
                    <Grid item xs={9} sx={{ m: 0, p: 0, pr: 0, pl: 2 }}>
                      <Typography
                        sx={{
                          ...StorageFormStyle.productName,
                        }}
                      >
                        {state.storageProduct?.product?.name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        m: 0,
                        p: 0,
                        pr: 2,
                        pl: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          ...StorageFormStyle.productBrand,
                        }}
                      >
                        {state.storageProduct?.product?.brand != null &&
                          `( ${
                            state.storageProduct?.product.brand.brandName ||
                            t('Storage.StorageView.noBrand')
                          } )`}
                      </Typography>
                    </Grid>

                    {state.storageProduct?.product?.features &&
                      state.storageProduct?.product?.features.map(
                        (_features: any, index: number) => {
                          return (
                            <Grid item key={index} xs={6}>
                              <Grid container direction={'row'}>
                                <Grid item xs={6} sx={{ pl: 2 }}>
                                  <Typography
                                    noWrap
                                    sx={{
                                      ...StorageFormStyle.featuresName,
                                    }}
                                  >
                                    {_features?.featureName}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    noWrap
                                    sx={{
                                      ...StorageFormStyle.featuresName,
                                    }}
                                  >
                                    {_features?.description}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                  </Grid>
                </Stack>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ p: 0, ml: 2, mr: 2, mb: 1 }}>
              <Grid
                container
                spacing={2}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                <Grid item xs={4} sx={{ m: 0, p: 0, pr: 0 }}>
                  <Stack direction={'column'} sx={{}}>
                    <Typography
                      sx={{
                        ...StorageFormStyle.textFieldHeader,
                      }}
                    >
                      {t('Storage.StorageView.serviceTag')}
                    </Typography>
                    <TextField
                      sx={{
                        '& .Mui-focused': {
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        },
                      }}
                      value={
                        state.storageProduct?.resourceName ||
                        `${t('Storage.StorageView.enterServiceTag')}`
                      }
                      onFocus={handleFocus}
                      onChange={(e) =>
                        dispatch({
                          type: 'UPDATE_RESOURCE_NAME',
                          value: e.target.value,
                        })
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item xs={2} sx={{ m: 0, p: 0, pr: 0 }}>
                  <Stack direction={'column'} sx={{}}>
                    <Typography
                      sx={{
                        ...StorageFormStyle.textFieldHeader,
                      }}
                    >
                      {t('Storage.StorageView.discount')} %
                    </Typography>
                    <TextField
                      sx={{
                        '& .Mui-focused': {
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        },
                      }}
                      inputProps={{
                        style: { textAlign: 'right' }, // Align text to the right
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>%</InputAdornment>
                        ),
                      }}
                      value={state.storageProduct?.discount || 0}
                      onFocus={handleFocus}
                      onChange={(e) =>
                        dispatch({
                          type: 'UPDATE_DISCOUNT',
                          value: +e.target.value,
                        })
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item xs={3} sx={{ m: 0, p: 0, pr: 0 }}>
                  <Stack direction={'column'} sx={{}}>
                    <Typography
                      sx={{
                        ...StorageFormStyle.textFieldHeader,
                      }}
                    >
                      {t('Storage.StorageView.unitPrice')}
                    </Typography>
                    <TextField
                      sx={{
                        '& .Mui-focused': {
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        },
                      }}
                      inputProps={{
                        style: { textAlign: 'right' }, // This aligns the text inside the input field to the right
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>.00</InputAdornment>
                        ),
                      }}
                      value={
                        state.storageProduct?.product?.unitPrice
                          ? state.storageProduct.product.unitPrice
                          : 0
                      }
                      disabled={true}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={3} sx={{ m: 0, p: 0, pr: 0 }}>
                  <Stack direction={'column'} sx={{}}>
                    <Typography
                      sx={{
                        ...StorageFormStyle.textFieldHeader,
                      }}
                    >
                      {t('Storage.StorageView.retailPrice')}
                    </Typography>
                    <TextField
                      sx={{
                        ...StorageFormStyle.value,
                        '& .Mui-focused': {
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        },
                      }}
                      inputProps={{
                        style: { textAlign: 'right' }, // This aligns the text inside the input field to the right
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>.00</InputAdornment>
                        ),
                      }}
                      value={
                        state.storageProduct?.retailPrice
                          ? state.storageProduct.retailPrice
                          : 0
                      }
                      onFocus={handleFocus}
                      onChange={(e) =>
                        dispatch({
                          type: 'UPDATE_RETAIL_PRICE',
                          value: +e.target.value,
                        })
                      }
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ p: 0, mt: 1 }}>
              <FormBox
                Icon={DevicesOtherRoundedIcon}
                title={t('Storage.StorageView.assignCustomerEmployee')}
                FormBTN={
                  <ObjectDrawer
                    buttonContext={t('Storage.StorageView.selectCustomerBttn')}
                    buttonIcon={
                      <ChangeCircleIcon sx={{ fontSize: 26, m: 1.5 }} />
                    }
                    objectList={!!objectDrawerList ? objectDrawerList : []}
                    objectType={[EnumObjectType.allEmployees]}
                    handleChange={handleChangeEmployee}
                    buttonHeight={30}
                    buttonWidth={30}
                  />
                }
              />
            </Grid>

            <Grid item xs={12} sx={{ p: 1 }}>
              {state.employee != null ? (
                <Stack direction='column'>
                  <SingleTicketContact
                    employee={state.employee}
                    company={state?.employee?.company}
                    priority={state.employee.company.priority || 2}
                  />
                </Stack>
              ) : (
                <ObjectDrawer
                  buttonContext={t('Storage.StorageView.selectCustomerBttn')}
                  buttonIcon={null}
                  objectList={!!objectDrawerList ? objectDrawerList : []}
                  objectType={[EnumObjectType.allEmployees]}
                  handleChange={handleChangeEmployee}
                  buttonHeight={82}
                  buttonWidth={'100%'}
                />
              )}
            </Grid>
            {/* DESCRIPTION */}
            <Grid item xs={12} sx={{ p: 0, pr: 1, mb: 1 }}>
              <FormBox
                Icon={DevicesOtherRoundedIcon}
                title={t('Storage.StorageView.internalTicketMessage')}
              />
            </Grid>
            {/* ISSUE DESCRIPTION */}
            <Grid item xs={12} sx={{ m: 0, p: 0, mb: 2 }}>
              <TextField
                sx={{ m: 0, p: 0, backgroundColor: 'primary.main' }}
                //value={state.comment}
                defaultValue={state.comment}
                label={t('Storage.StorageView.internalTicketMessage')}
                variant='filled'
                multiline={true}
                minRows={4}
                onChange={(e) => {
                  dispatch({
                    type: 'EDIT_COMMENT',
                    payload: e.target.value,
                  });
                }}
                onBlur={(e) => {
                  dispatch({
                    type: 'EDIT_COMMENT',
                    payload: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sx={{ p: 0, m: 0, mb: 1 }}>
              {state.scheduleTime && (
                <ScheduleView
                  scheduleTime={state.scheduleTime}
                  handleChange={handleChangeSchedule}
                />
              )}
            </Grid>

            <Grid item xs={12} sx={{ p: 0, m: 0, mb: 2 }}>
              <DeliveryAddressView
                addressList={state.addressList || []}
                handleChange={handleChangeDeliveryAddress}
              />
            </Grid>
          </List>
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              height: 52,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ m: 0, display: 'flex', flexDirection: 'row' }}
            >
              <Button
                sx={{ pb: 1, height: 30 }}
                variant='contained'
                onClick={() => setDeleteConfirm(!deleteConfirm ? true : false)}
              >
                {false
                  ? `${t('Storage.StorageView.deletingProduct')}`
                  : `${t('Storage.StorageView.deleteProduct')}`}
              </Button>
              {!!deleteConfirm === true && (
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{ pb: 1, height: 30 }}
                >
                  {t('Storage.StorageView.confirmDelete')}
                </Button>
              )}
              <Grid sx={{ flexGrow: 1 }}></Grid>
              <Button
                variant='contained'
                disabled={isAssignButtonDisabled()}
                onClick={(e: any) => onSubmit(e)}
                //  disabled={isProductChanged ? false : !isDirty ? true : false}
                sx={{ pb: 1, height: 30 }}
              >
                {false
                  ? `${t('Storage.StorageView.assigningProduct')}`
                  : `${t('Storage.StorageView.assignProduct')}`}
              </Button>
            </Grid>
          </Paper>
        </Paper>
      </Grid>
      {/* HEADER */}
    </Grid>
  );
};
