import {
  Button,
  Grid,
  List,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { SearchFilter } from 'components/ui/SearchFilter';
import React, { useEffect, useReducer, useState } from 'react';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import useWindowsDimension from 'functions/useWindowsDimension';
import {
  IOrderPostViewModel,
  IOrderUpdateViewModel,
  IOrderViewModel,
} from 'Interfaces/IOrderConfig';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import _ from 'lodash';
import { useGet, usePut } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import { EnumOrderStatus } from 'Interfaces/IGlobalTypeConfig';
import { SingleOrderView } from './OrderPost/SingleOrderView';
import { SingleOrderCompanyView } from 'components/ui/SingleOrderCompanyView';
import { SingleOrderPostProductView } from './OrderPost/SingleOrderPostProductView';
import dayjs from 'dayjs';
import { SingleReceivedOrderPostView } from './OrderPost/SingleReceivedOrderPostView';
import ownerAtom from 'data/atoms/ownerAtom';
import userAtom from 'data/atoms/userAtom';
import { useAtom } from 'jotai';

const initialState: any = {
  orderList: [] as IOrderViewModel[], // left side object
  selectedOrder: {
    id: null,
    orderNo: null,
    orderStatus: EnumOrderStatus.Ordered,
    orderDate: dayjs(new Date()),
    trackingNo: null,
    orderUnitCost: 0,
    orderRetailValue: 0,
    deliveryDate: null,
    estimatedDeliveryDate: null,
    vendorInvoiceNumber: null,
    vendorId: '',
    vendor: null,
    vendorContactId: '',
    vendorContact: null,
    shippingCost: 0,
    deliveryStreet: '',
    deliveryPostalCode: '',
    deliveryArea: '',
    deliveryCity: '',
    deliveryCountry: 'string',
    attention: null,
    orderPosts: null,
    responsibleId: '',
  } as IOrderViewModel, //right side object
};
type Action =
  | { type: 'RESET_FORM' }
  | { type: 'SET_ORDERS'; payload: IOrderViewModel[] }
  | { type: 'SET_SELECTED_ORDER'; payload: IOrderViewModel }
  | { type: 'CHANGE_ORDER_POST'; payload: IOrderPostViewModel };
function stateReducer(state: any, action: Action): any {
  let _orderList = _.cloneDeep(state.orderList);
  switch (action.type) {
    case 'RESET_FORM':
      return initialState;
    case 'SET_ORDERS':
      return { ...state, orderList: action.payload };
    case 'SET_SELECTED_ORDER':
      return {
        ...state,
        selectedOrder: action.payload,
      };
    case 'CHANGE_ORDER_POST':
      let _selectedOrder = _.cloneDeep(state.selectedOrder);
      let _orderPosts = _.cloneDeep(_selectedOrder.orderPosts);
      let _orderPostIndex = _orderPosts.findIndex(
        (obj: IOrderPostViewModel) => obj.id === action.payload.id
      );
      _orderPosts[_orderPostIndex] = action.payload;
      _selectedOrder.orderPosts = _orderPosts;
      return {
        ...state,
        selectedOrder: _selectedOrder,
      };
    default:
      return state;
  }
}

const isSaveOrderButtonDisabled = (orderPosts: IOrderPostViewModel[]) => {
  let isDisabled = true;
 // console.log('isSaveOrderButtonDisabled', orderPosts);
  if (orderPosts) {
    orderPosts.forEach((orderPost: IOrderPostViewModel) => {
      if (orderPost.serialNumbers && orderPost.serialNumbers?.length > 0) {
        isDisabled = false;
      }
    });
  }

  return isDisabled;
};

const OrderStatus = (orderPosts: IOrderPostViewModel[]) => {
  let status = EnumOrderStatus.InTransit;
  if (orderPosts) {
    orderPosts.forEach((orderPost: IOrderPostViewModel) => {
      if (
        orderPost.deliveredQuantity > 0 &&
        orderPost.deliveredQuantity < orderPost.quantity
      ) {
        status = EnumOrderStatus.PartialDelivered;
      //  console.log('PartialDelivered');
      }
    });
  }
  if (status === EnumOrderStatus.InTransit) {
    status = EnumOrderStatus.Delivered;
    orderPosts.forEach((orderPost: IOrderPostViewModel) => {
      if (
        orderPost.deliveredQuantity > 0 &&
        orderPost.deliveredQuantity < orderPost.quantity
      ) {
        status = EnumOrderStatus.PartialDelivered;
      //  console.log('Delivered');
      }
    });
  }
  if (status === EnumOrderStatus.Delivered) {
    orderPosts.forEach((orderPost: IOrderPostViewModel) => {
      if (
        orderPost.deliveredQuantity === 0 &&
        orderPost.deliveredQuantity < orderPost.quantity
      ) {
        status = EnumOrderStatus.InTransit;
      //  console.log('Delivered');
      }
    });
  }
  return status;
};

export const OrderReceived = () => {
  // INITIALIZATION
  const { height } = useWindowsDimension();
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const [activeHeaderIndex, setActiveHeaderIndex] = useState<number>(-1);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [isReloadOrdersData, setIsReloadOrdersData] = useState<boolean>(true);
  const [owner] = useAtom(ownerAtom);
  const [user] = useAtom(userAtom);

  // API
  const { data: ordersData } = useGet(
    rq.orders(EnumOrderStatus.All, isReloadOrdersData),
    isReloadOrdersData
  );

  let isEnabled: boolean = true;
  const putOrder = usePut(rqPost.order('0', isEnabled), isEnabled);

  // HANDLES
  const handleSelectedOrder = (e: any, obj: IOrderViewModel) => {
    e.preventDefault();

    dispatch({ type: 'SET_SELECTED_ORDER', payload: obj });
  };

  // BLURS
  const handleBlur = (obj: IOrderPostViewModel) => {
   // console.log('handleBlur', obj);
    dispatch({ type: 'CHANGE_ORDER_POST', payload: obj });
  };
  // EFFECTS
  useEffect(() => {
    if (state.selectedOrder.orderPosts) {
      setIsButtonDisabled(
        isSaveOrderButtonDisabled(state.selectedOrder.orderPosts)
      );
    }
  }, [state]);

  useEffect(() => {
    if (ordersData) {
      let _orderList = _.cloneDeep(ordersData.orders);
     // console.log('_orderList useEffect: ', _orderList);
      _orderList.forEach((order: IOrderViewModel) => {
        order.orderStatus = OrderStatus(order.orderPosts || []);
        order.orderPosts &&
          order.orderPosts.forEach((orderPost: IOrderPostViewModel) => {
            orderPost.serialNumbers = null;
          });
      });

    //  console.log('useEffect', _orderList);
      dispatch({
        type: 'SET_ORDERS',
        payload: _orderList,
      });
      setIsReloadOrdersData(false);
    }
  }, [ordersData]);

  const onSubmit = () => {
    let _order = _.cloneDeep(state.selectedOrder);
   // console.log('_order', _order);

    let newOrder: IOrderUpdateViewModel = {
      id: _order.id,
      orderNo: '',
      orderStatus: OrderStatus(_order.orderPosts),
      orderDate: _order.orderDate || null,
      trackingNo: _order.trackingNo || null,
      vendorInvoiceNumber: _order.vendorInvoiceNumber || '',
      vendorId: _order.vendor?.id || null,
      vendorContactId: _order.vendorContact?.id || null,
      responsibleId: user.id,
      shippingCost: _order.shippingCost,
      deliveryDate: _order.deliveryDate || null,
      estimatedDeliveryDate: _order.estimatedDeliveryDate,
      deliveryStreet: _order.deliveryStreet,
      deliveryPostalCode: _order.deliveryPostalCode,
      deliveryArea: _order.deliveryArea,
      deliveryCity: _order.deliveryCity,
      deliveryCountry: _order.deliveryCountry,
      attention: _order.attention || '',
      orderUnitCost: _order.orderUnitCost,
      orderRetailValue: _order.orderRetailValue,
      orderPosts: _order.orderPosts.map((obj: IOrderPostViewModel) => ({
        id: obj.id,
        status:
          obj.deliveredQuantity === obj.quantity
            ? EnumOrderStatus.Delivered
            : obj.deliveredQuantity === 0
            ? EnumOrderStatus.InTransit
            : EnumOrderStatus.PartialDelivered,
        deliverDate: obj.deliveryDate || null,
        companyId: obj.company?.id || owner.id,
        ProductId: obj.product?.id || null,
        ticketProductId: obj.ticketProduct?.id || null,
        unitPrice: obj.unitPrice,
        retailPrice: obj.retailPrice,
        quantity: obj.quantity,
        deliveredQuantity: obj.deliveredQuantity,
        serialNumber: null,
        serialNumbers: obj.serialNumbers,
      })),
    };
  //  console.log('newOrder', newOrder);

    putOrder.mutate(newOrder, {
      onSuccess: (res: any) => {
        dispatch({
          type: 'RESET_FORM',
        });
        setIsReloadOrdersData(true);
      },
    });

    /*  postTicket.mutate(newOrder, {
      onSuccess: (res: any) => {
        dispatch({
          type: 'REMOVE_RIGHT_SIDE_HEADER_ITEM',
          payload: 0,
          activeHeaderIndex: activeHeaderIndex,
        });
        let addresses = GenerateAddressList(
          owner,
          null,
          null,
          state.orderList[activeHeaderIndex].orderState.deliveryAddressList
        );
        dispatch({
          type: 'SET_DELIVERY_ADDRESSES',
          payload: addresses,
          activeHeaderIndex: activeHeaderIndex,
        });
      },
    }); */
  };

  return (
    <Grid container spacing={0} sx={{ display: 'flex', flexDirection: 'row' }}>
      {/* LEFT SIDE */}
      <Grid item xs={6} sx={{ pr: .5, m: 0 }}>
        <SearchFilter Icon={CreditCardIcon} Header='orders' />
        <List
          sx={{
            p: 2,
            height: Math.ceil(height - 242),
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {!!state.orderList &&
            state.orderList.map((item: IOrderViewModel, index: number) => (
              <Grid
                sx={{
                  backgroundColor: 'transparent',
                  border: 0,
                  boxShadow: 'none',
                }}
                key={index}
                onClick={(e) => {
                  handleSelectedOrder(e, item);
                }}
              >
                <SingleOrderView
                  order={item}
                  isDisabled={true}
                  handleBlur={null}
                />
              </Grid>
            ))}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>

      {/* RIGHT SIDE */}
      <Grid item xs={6} sx={{ pl: .5, m: 0 }}>
        <SearchFilter Icon={CreditCardIcon} Header='Selected order' />
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            p: 2,
            backgroundColor: 'primary.main',
          }}
        >
          <Grid item xs={6} sx={{}}>
            {!state.selectedOrder.Vendor && (
              <SingleOrderCompanyView company={state.selectedOrder?.vendor} />
            )}
          </Grid>
          <Grid item xs={2} sx={{}}></Grid>
          <Grid item xs={4} sx={{ m: 0, p: 0, pr: 1 }}>
            <Stack direction={'column'} sx={{}}>
              <Stack direction={'row'} sx={{}}>
                <Typography
                  sx={{ m: 1, pt: 0.5, fontWeight: 'bold', width: '60%' }}
                >
                  Total Cost:
                </Typography>
                <Typography
                  sx={{
                    m: 1,
                    pt: 0.5,
                    width: '40%',
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}
                >
                  {state.selectedOrder?.orderUnitCost || '0.00'}
                </Typography>
              </Stack>
              <Stack direction={'row'} sx={{}}>
                <Typography
                  sx={{ m: 1, pt: 0.5, fontWeight: 'bold', width: '60%' }}
                >
                  Shipping Cost:
                </Typography>
                <Typography
                  sx={{
                    m: 1,
                    pt: 0.5,
                    width: '40%',
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}
                >
                  {state.selectedOrder?.shippingCost || '0.00'}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <List
          sx={{
            p: 2,
            height: Math.ceil(height - 374),
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {state.selectedOrder.orderPosts &&
            state.selectedOrder.orderPosts?.map(
              (orderPost: IOrderPostViewModel, index: number) => (
                <Grid
                  sx={{
                    backgroundColor: 'transparent',
                    border: 0,
                    boxShadow: 'none',
                  }}
                  key={index}
                >
                  <SingleReceivedOrderPostView
                    orderPost={orderPost}
                    isDisabled={false}
                    handleBlur={handleBlur}
                  />
                </Grid>
              )
            )}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              p: 0,
              m: 0,
              mb: 2,
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <Button
              variant='contained'
              //type='submit'
              onClick={onSubmit}
              disabled={isButtonDisabled}
            >
              Update Order
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
