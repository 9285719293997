import * as yup from 'yup';
import { mainCompany } from 'data/variables';
import { SearchFilter } from 'components/ui/SearchFilter';
import EngineeringIcon from '@mui/icons-material/Engineering';
import useWindowsDimension from 'functions/useWindowsDimension';
import { useState, useEffect, useReducer } from 'react';
import {
  Grid,
  Paper,
  Typography,
  List,
  TextField,
  Button,
} from '@mui/material';
import { useGet, usePost, usePut } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import { IProductTypeViewModel } from 'Interfaces/BillingConfig';
import { IAutoAnswersViewModel } from 'pages/AutoAnswersConfig';
import { useTranslation } from 'react-i18next';

let _ = require('lodash');

const schema = yup
  .object()
  .shape({
    organizationNumber: yup.string().min(9).max(9).required(),
    name: yup.string().min(3).max(50).required(),
    mainAddress: yup.object().shape({
      street: yup.string().required(),
      postalCode: yup.string().min(4).max(4).required(),
      city: yup.string().min(2).max(50).required(),
    }),
    phone: yup.string().min(8).max(16).required(),
    email: yup.string().email().required(),
  })
  .required();

const autoAnswersReducer = (state: any, action: any) => {
  switch (action.type.toUpperCase()) {
    case 'SELECT_AUTO_ANSWER':
      return {
        ...state,
        // Update the state with the selected auto answer data
        id: action.payload.id,
        productTypeId: action.payload.productTypeId,
        local: action.payload.local,
        english: action.payload.english,
      };
    case 'RESET_AUTO_ANSWER':
      return initialState;
    default:
      return state;
  }
};

const initialState = {
  id: 'new',
  productTypeId: {} as IProductTypeViewModel,
  local: '',
  english: '',
};

export const AutoAnswers = (e: any) => {
  const { t } = useTranslation();
  /* INITIALIZATIONS */
  const company = mainCompany;
  const { height, width } = useWindowsDimension();
  const sideWidth = Math.ceil((width - 350) / 2);
  const [mobileAutoAnswers, setMobileAutoAnswers] = useState<
    IAutoAnswersViewModel[]
  >([]);
  const [productTypes, setProductTypes] = useState<IProductTypeViewModel[]>([]);
  const [selectedProductType, setSelectedProductType] = useState(
    '9bb092b4-8dea-4546-bfc2-3a09e202b22b'
  );
  const [state, dispatch] = useReducer(autoAnswersReducer, initialState);
  let isEnabled = true;
  const { data: productTypesData } = useGet(
    rq.productTypes(isEnabled),
    isEnabled
  );
  const postMobileAutoAnswer = usePost(
    rqPost.mobileAutoAnswers(selectedProductType, isEnabled),
    isEnabled
  );
  const putMobileAutoAnswers = usePut(
    rqPost.mobileAutoAnswers(selectedProductType, isEnabled),
    isEnabled
  );
  const { data: mobileAutoAnswersData } = useGet(
    rq.mobileAutoAnswers(selectedProductType, isEnabled),
    isEnabled
  );
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const handleNewAutoAnswer = async (e: React.MouseEvent) => {
    e.preventDefault();
    const newMobileAutoAnswer: IAutoAnswersViewModel = {
      id: 'new',
      productTypeId: {} as IProductTypeViewModel,
      local: '',
      english: '',
    };
    dispatch({
      type: 'SELECT_AUTO_ANSWER',
      payload: newMobileAutoAnswer,
    });
  };

  const handleUpdateAutoAnswer = (e: any) => {
    e.preventDefault();
    if (state.id === 'new') {
      let states = _.cloneDeep(state);
      states.productTypeId = selectedProductType;
      delete states.id;
    //  console.log('create auto-answer:', states);
      // Reset the form
      postMobileAutoAnswer.mutate(states, {
        onSuccess: (response: any) => {
          console.log('SUCCESS auto-answer');
          // Update the mobileAutoAnswers state with the new auto answer
          /*  setMobileAutoAnswers((prevMobileAutoAnswers) => [
          ...prevMobileAutoAnswers,
          response.data, // Assuming the response contains the created auto answer
        ]);
        dispatch({
          type: 'RESET_AUTO_ANSWER',
        });       */
        },
      });
    } else {
      putMobileAutoAnswers.mutate(state, {
        onSuccess: () => {
          // Reset the form
          dispatch({
            type: 'RESET_AUTO_ANSWER',
          });
        },
      });
    }
  };

  // Function to handle the "Delete" button
  const handleDeleteAutoAnswer = () => {
    // Implement the logic to delete the selected auto-answer
    // You can make an API call here if needed
    console.log('Deleting auto-answer:', state);
  };

  const handleFilterChange = (e: any) => {
    setSelectedProductType(e);
  };

  const handleSelectAutoAnswer = (autoAnswer: IAutoAnswersViewModel) => {
    dispatch({
      type: 'SELECT_AUTO_ANSWER',
      payload: {
        id: autoAnswer.id,
        productTypeId: autoAnswer.productTypeId,
        local: autoAnswer.local,
        english: autoAnswer.english,
      },
    });
  };

  useEffect(() => {
    if (productTypesData) {
      setProductTypes(productTypesData.productType);
    }
  }, [productTypesData]);

  useEffect(() => {
    if (mobileAutoAnswersData) {
      setMobileAutoAnswers(mobileAutoAnswersData.mobileAutoAnswers);
    }
  }, [mobileAutoAnswersData]);

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
        }}
      >
        {/* Left side */}
        <Grid
          item
          xs={6}
          sx={{
            p: 0,
            m: 0,
            pr: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <SearchFilter
            Icon={EngineeringIcon}
            Header={t("Settings.AutoAnswers.mobileAutoAnswers")}
            handleNew={handleNewAutoAnswer}
            selectList={productTypes}
            handleFilterChange={handleFilterChange}
          />
          <List
            sx={{
              height: Math.ceil(height - 150),
              backgroundColor: 'primary.back',
              p: 1,
              overflow: 'auto',
            }}
          >
            {mobileAutoAnswers.map((autoAnswer: any, index: any) => (
              <Paper
                variant='elevation3'
                key={index}
                sx={{ mb: 1, backgroundColor: 'primary.main' }}
                onClick={() => handleSelectAutoAnswer(autoAnswer)}
              >
                {/* Render the properties of each autoAnswer */}
                <Grid
                  container
                  display={'flex'}
                  flexDirection='row'
                  sx={{ m: 0, p: 1, height: 64 }}
                >
                  <Typography sx={{ width: '50%' }}>
                    {autoAnswer.local}
                  </Typography>
                  <Typography sx={{ width: '50%' }}>
                    {autoAnswer.english}
                  </Typography>
                </Grid>
              </Paper>
            ))}
          </List>
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          ></Paper>
        </Grid>
        {/* Right side */}

        <Grid
          item
          xs={6}
          sx={{
            p: 0,
            mr: 0,
            pl: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <SearchFilter Header={t("Settings.AutoAnswers.selectedAutoAnswer")} />
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              borderRadius: 0,
              height: Math.ceil(height - 150),
            }}
          >
            {state.id !== '' ? (
              <form>
                {/* Add form fields for editing auto answer */}
                <TextField
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                    pb: 2,
                  }}
                  label={t("Settings.AutoAnswers.local")}
                  value={state.local}
                  onChange={(e) =>
                    dispatch({
                      type: 'SELECT_AUTO_ANSWER',
                      payload: {
                        ...state,
                        local: e.target.value,
                      },
                    })
                  }
                />
                <TextField
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label={t("Settings.AutoAnswers.english")}
                  value={state.english}
                  onChange={(e) =>
                    dispatch({
                      type: 'SELECT_AUTO_ANSWER',
                      payload: {
                        ...state,
                        english: e.target.value,
                      },
                    })
                  }
                />
                {/* Add more fields as needed */}
              </form>
            ) : (
              <Typography sx={{ color: 'text.secondary' }}>
                {t("Settings.AutoAnswers.noAutoAnswerSelected")}
              </Typography>
            )}
          </Paper>
          <Paper
            variant='elevation3'
            sx={{
              p: 0,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            {/*   Submit Button */}
            <Grid
              item
              xs={12}
              sx={{ m: 2, display: 'flex', flexDirection: 'row' }}
            >
              <Button
                variant='contained'
                onClick={() => setDeleteConfirm(!deleteConfirm ? true : false)}
              >
               {t("Settings.AutoAnswers.deleteAutoAnswer")}
                {/*   {postEmployeeDeactivate.isLoading
                ? 'Deleting Employee...'
                : 'Delete Employee'} */}
              </Button>
              {!!deleteConfirm === true && (
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleDeleteAutoAnswer}
                >
                  {t("Settings.AutoAnswers.confirmDelete")}
                </Button>
              )}
              <Grid sx={{ flexGrow: 1 }}></Grid>{' '}
              {state.id !== 'new' && (
                <Button
                  variant='contained'
                  onClick={(e) => {
                    handleUpdateAutoAnswer(e);
                  }}
                >
                  {t("Settings.AutoAnswers.updateAutoAnswer")}
                  {/* {putEmployee.isLoading
                  ? 'Updating Employee...'
                  : 'Update Employee'} */}
                </Button>
              )}
              {state.id === 'new' && (
                <Button
                  variant='contained'
                  onClick={(e) => {
                    handleUpdateAutoAnswer(e);
                  }}
                >
                  {t("Settings.AutoAnswers.createAutoAnswer")}
                  {/* {postEmployee.isLoading
                  ? 'Creating Employee...'
                  : 'Create Employee'} */}
                </Button>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
