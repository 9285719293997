import * as React from 'react';
import { useNavigate } from 'react-router';
import { Grid, Typography, Paper, Stack, IconButton } from '@mui/material';
import GetPriorityColor from 'functions/GetPriorityColor';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import { useTranslation } from 'react-i18next';

export const SingleCompanyView = (props: any) => {
  const { t } = useTranslation();

  let navigate = useNavigate();
  const company = props.company;

  return (
    <Paper
      variant='elevation3'
      onClick={() => {
        // props.setCompany(company);
      }}
      sx={{
        mb: 1,
        borderLeft: 8,
        borderRight: 2,
        borderColor: GetPriorityColor(company?.priority || 0),
        height: 80,
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Stack direction={'row'} sx={{}}>
        <Grid
          item
          sx={{
            width: 160,
            height: 80,
            borderTopLeftRadius: '3px',
            borderBottomLeftRadius: '3px',
            backgroundColor: 'primary.text',
            display: 'flex',
          }}
        >
          <img
            src={company?.url || MissingPhoto.COMPANY_URL}
            style={{
              paddingRight: 2,
              width: '100%',
              height: '100%',
              borderTopLeftRadius: '10px',
              borderBottomLeftRadius: '10px',
              objectFit: 'scale-down',
            }}
            alt={'logo'}
          />
        </Grid>

        <Grid container flexGrow={1} direction={'column'} sx={{}}>
          <Grid item sx={{}}>
            <Stack direction={'row'} flexGrow={1}>
              <Grid flexGrow={1} sx={{}}>
                <Typography
                  sx={{
                    ml: 1,
                    letterSpacing: 1,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    fontSize: 16,
                  }}
                >
                  {company?.name}
                </Typography>
              </Grid>
              <Typography
                sx={{
                  ml: 1,
                  mt: 0.3,
                  pr: 1,
                  letterSpacing: 1,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
                ( {company?.organizationNumber} )
              </Typography>
              <Grid item sx={{ m: 0, p: 0 }}>
                {props?.detailNav && (
                  <IconButton
                    sx={{ mt: -1, mb: -1 }}
                    onClick={() => {
                      navigate(`/companies/${company.id}`);
                    }}
                  >
                    <LaunchRoundedIcon sx={{ fontSize: 26 }} />
                  </IconButton>
                )}
              </Grid>
            </Stack>
          </Grid>

          <Grid container flexGrow={1} sx={{ mt: -0.5 }}>
            <Grid
              item
              xs={8}
              sx={{
                pl: 1,
              }}
            >
              <Typography
                sx={{
                  m: 0,
                  pr: 0.3,
                  fontWeight: 'normal',
                  fontSize: 12,
                }}
              >
                {company?.email || ''}
              </Typography>
              <Typography
                sx={{
                  mb: 1,
                  pr: 0.3,
                  fontWeight: 'normal',
                  fontSize: 12,
                }}
              >
                {company?.webPage || ''}
              </Typography>
            </Grid>

            <Grid
              item
              xs={4}
              justifyContent='flex-end'
              alignItems='flex-end'
              sx={{
                mb: 1,
                pr: 0.5,
                display: 'flex',
              }}
            >
              <Typography
                sx={{
                  m: 0,
                  pr: 0.3,
                  color: 'secondary.text',
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
                {company?.phone || ''}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
};
