import {
  Paper,
  Grid,
  IconButton,
  Box,
  Typography,
  Stack,
  Tooltip,
  TextField,
} from '@mui/material';
import {
  EnumObjectType,
  EnumPlacement,
  MissingPhoto,
} from 'Interfaces/IGlobalTypeConfig';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GetColorByName from 'functions/GetColorByName';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded';
import LocationOffRoundedIcon from '@mui/icons-material/LocationOffRounded';
import { G } from '@react-pdf/renderer';
import { useEffect, useReducer, useState } from 'react';
import {
  SelectHeaderStyle,
  SelectStyle,
  TextFieldLabelStyle,
  TextFieldStyle,
  iconButtonAddIconStyle,
} from 'data/FontStyle';
import { useTranslation } from 'react-i18next';
type ProductCardViewProps = {
  companyProduct: ICompanyProductViewModel;
  objectType: EnumObjectType;
  location: EnumObjectType;
  handleRemoveProduct: (
    companyProduct: ICompanyProductViewModel,
    objectType: EnumObjectType
  ) => void; // Adjust the argument type as needed
  handleChange: (
    companyProduct: ICompanyProductViewModel,
    objectType: EnumObjectType
  ) => void;
  height?: number; // Adjust the argument type as needed
};
export interface ICardInterface {
  serviceTag: string;
  quantity: number;
  productQuantity: number;
}

const initialState: ICardInterface = {
  serviceTag: '',
  quantity: 0,
  productQuantity: 1,
};
type Action =
  | { type: 'CHANGE_SERVICETAG'; payload: string }
  | { type: 'CHANGE_QUANTITY'; payload: number }
  | { type: 'CHANGE_PRODUCT_QUANTITY'; payload: number };

const reducer = (state: ICardInterface, action: Action): ICardInterface => {
  switch (action.type) {
    case 'CHANGE_SERVICETAG':
      return {
        ...state,
        serviceTag: action.payload,
      };
    case 'CHANGE_QUANTITY':
      return {
        ...state,
        quantity: +action.payload,
      };
    case 'CHANGE_PRODUCT_QUANTITY':
      return {
        ...state,
        productQuantity: +action.payload,
      };
    default:
      return state;
  }
};

export const ProductFormStyle = {
  addIcon: iconButtonAddIconStyle,
  select: SelectStyle,
  textField: TextFieldStyle,
  textFieldLabel: TextFieldLabelStyle,
  SelectHeader: SelectHeaderStyle,
};
export const ProductCardView: React.FC<ProductCardViewProps> = ({
  companyProduct,
  handleRemoveProduct,
  objectType,
  location,
  height,
  handleChange,
}) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  let showServiceTagButton =
    companyProduct.id?.startsWith('new') ||
    objectType === EnumObjectType.Product ||
    objectType === EnumObjectType.StorageProduct ||
    objectType === EnumObjectType.unassignedCompanyProduct
      ? true
      : false;
  const dynamicFontSize = height ? `${height / 5}px` : '16px'; // Example calculation
  const dynamicImageSize = height ? `${height}px` : '80px';
  // handles
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'serviceTag') {
      dispatch({ type: 'CHANGE_SERVICETAG', payload: value });
    } else if (name === 'quantity') {
      dispatch({ type: 'CHANGE_QUANTITY', payload: +value });
    } else if (name === 'productQuantity') {
      dispatch({ type: 'CHANGE_PRODUCT_QUANTITY', payload: +value });
    }
    handleChange(
      {
        ...companyProduct,
        [name]: value,
      },
      objectType
    );
  };
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'serviceTag') {
      dispatch({ type: 'CHANGE_SERVICETAG', payload: value });
    } else if (name === 'quantity') {
      dispatch({ type: 'CHANGE_QUANTITY', payload: +value });
    }
    handleChange(
      {
        ...companyProduct,
        [name]: value,
      },
      objectType
    );
  };

  useEffect(() => {
    dispatch({
      type: 'CHANGE_SERVICETAG',
      payload: companyProduct.serviceTag,
    });
    dispatch({ type: 'CHANGE_QUANTITY', payload: +companyProduct.quantity });
  }, [companyProduct.serviceTag, companyProduct.quantity]);

  return (
    <Paper
      variant='elevation3'
      sx={{
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        borderColor: GetColorByName(
          `${companyProduct?.product?.productType?.name || 'no name detected'}`
        ),
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
        width: '100%',
        height: '100%',
        // maxHeight: !height ? 80 : height,
      }}
    >
      <Grid
        container
        display={'flex'}
        sx={{ flexDirection: 'row', width: '100%', m: 0, p: 0 }}
      >
        {/*  IMAGE  */}
        <Grid
          item
          sx={{
            width: dynamicImageSize,
            height: dynamicImageSize,
            backgroundColor: 'primary.light',
          }}
        >
          <img
            src={companyProduct.product?.url || MissingPhoto.PRODUCT_URL}
            alt='previewUrl'
            style={{
              width: dynamicImageSize,
              height: dynamicImageSize,
              borderRadius: 3,
              objectFit: 'contain',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
            }}
          />
        </Grid>
        {/*  NAME  */}
        <Grid
          item
          xs={6}
          sx={{
            flexGrow: 1,
            pl: 1,
          }}
        >
          <Stack direction={'column'}>
            <Typography
              sx={{
                letterSpacing: 2,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: dynamicFontSize,
              }}
            >
              {companyProduct?.product?.name}
            </Typography>
            <Typography
              sx={{
                height: 24,
                letterSpacing: 3,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: dynamicFontSize,
              }}
            >
              {companyProduct?.product?.productType?.name}
            </Typography>
            <Typography
              sx={{
                height: 24,
                letterSpacing: 3,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: dynamicFontSize,
              }}
            >
              {companyProduct?.serviceTag || 'no service tag detected'}
            </Typography>
          </Stack>
        </Grid>
        {/*  PRICE  */}
        <Grid
          item
          sx={{
            flexGrow: 1,
            pl: 1,
            pr: 1,
          }}
        >
          <Stack direction={'column'}>
            <Typography
              sx={{
                letterSpacing: 2,
                textAlign: 'right',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: dynamicFontSize,
              }}
            >
              {companyProduct?.product?.brand?.brandName || 'No Brand'}
            </Typography>
            <Typography
              sx={{
                height: 24,
                pt: 0.5,
                letterSpacing: 3,
                textAlign: 'right',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 12,
              }}
            >
              {companyProduct?.retailPrice?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
                ' * ' +
                companyProduct?.quantity}
            </Typography>
            <Typography
              sx={{
                height: 24,
                letterSpacing: 3,
                textAlign: 'right',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: dynamicFontSize,
                color: 'secondary.text',
              }}
            >
              {(
                companyProduct?.retailPrice * +companyProduct?.quantity
              )?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Stack>
        </Grid>

        <Grid
          item
          xs={1}
          sx={{
            flexGrow: 1,
            pl: 1,
          }}
        >
          <Tooltip
            title={
              companyProduct.id?.startsWith('new') ||
              objectType === EnumObjectType.Product ||
              objectType === EnumObjectType.StorageProduct ||
              objectType === EnumObjectType.unassignedCompanyProduct
                ? 'Delete Forever'
                : location === EnumObjectType.Company &&
                  companyProduct.placement === (3 as EnumPlacement)
                ? 'Disable Domain'
                : location === EnumObjectType.Company &&
                  companyProduct.placement !== (3 as EnumPlacement)
                ? 'Add Domain'
                : location === EnumObjectType.Resource &&
                  companyProduct.placement === (4 as EnumPlacement)
                ? 'Turn Off Location'
                : location === EnumObjectType.Resource &&
                  companyProduct.placement !== (4 as EnumPlacement)
                ? 'Add Location'
                : location === EnumObjectType.CompanyEmployees &&
                  companyProduct.ownerId
                ? 'Remove Person'
                : 'Add Person'
            }
            arrow
          >
            <IconButton
              aria-label='button-action'
              sx={{
                mt: 3,
                elevation: 2.8,
                width: 40,
                height: 40,
                mx: 'auto',
                backgroundColor: 'primary.back',
                ':hover': {
                  cursor: 'pointer',
                  filter: 'brightness(120%)',
                  color: 'white',
                  backgroundColor: 'primary.light',
                  transition: 'all 0.2s ease-in-out',
                },
                boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.50)',
              }}
              onClick={() => handleRemoveProduct(companyProduct, objectType)}
            >
              {companyProduct.id?.startsWith('new') ||
              objectType === EnumObjectType.Product ||
              objectType === EnumObjectType.StorageProduct ||
              objectType === EnumObjectType.unassignedCompanyProduct ? (
                <DeleteForeverIcon
                  sx={{ fontSize: 32, color: 'warning.light' }}
                />
              ) : location === EnumObjectType.Company &&
                companyProduct.placement === (3 as EnumPlacement) ? (
                <DomainDisabledIcon
                  sx={{ fontSize: 32, color: 'secondary.light' }}
                />
              ) : location === EnumObjectType.Company &&
                companyProduct.placement !== (3 as EnumPlacement) ? (
                <DomainAddIcon sx={{ fontSize: 32, color: 'info.light' }} />
              ) : location === EnumObjectType.Resource &&
                companyProduct.placement === (4 as EnumPlacement) ? (
                <LocationOffRoundedIcon
                  sx={{ fontSize: 32, color: 'secondary.light' }}
                />
              ) : location === EnumObjectType.Resource &&
                companyProduct.placement !== (4 as EnumPlacement) ? (
                <AddLocationAltRoundedIcon
                  sx={{ fontSize: 32, color: 'info.light' }}
                />
              ) : location === EnumObjectType.CompanyEmployees &&
                companyProduct.ownerId ? (
                <PersonOffRoundedIcon
                  sx={{ fontSize: 32, color: 'secondary.light' }}
                />
              ) : (
                <PersonAddRoundedIcon
                  sx={{ fontSize: 32, color: 'info.light' }}
                />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
            mt: 1,
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'left',
            borderRadius: 3,
            // outline: '1px solid orange',
          }}
        >
          {showServiceTagButton && (
            <>
              <Grid item xs={8} sx={{ height: 72, pr: 2, mb: -2 }}>
                <TextField
                  name='serviceTag'
                  onFocus={handleFocus}
                  sx={{ ...ProductFormStyle.textField }}
                  label={
                    <Typography sx={{ ...ProductFormStyle.textFieldLabel }}>
                      {t('Products.ProductForm.serviceTag')}
                    </Typography>
                  }
                  value={state.serviceTag || ''}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  //error={!!errors.name}
                  //helperText={errors.name}
                />
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  height: 72,
                  pr: 2,
                  mb: -2,
                  // outline: '1px solid red',
                  width: '100%',
                  flex: 1,

                  display: 'flex',
                  justifyContent: 'flex-start',
                  justifyItems: 'left',
                  alignItems: 'left',
                }}
              >
                <TextField
                  name='quantity'
                  onFocus={handleFocus}
                  sx={{ ...ProductFormStyle.textField, flex: 1, mr: 1 }}
                  label={
                    <Typography sx={{ ...ProductFormStyle.textFieldLabel }}>
                      {t('Products.ProductForm.quantity')}
                    </Typography>
                  }
                  value={+state.quantity || 0}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  //error={!!errors.name}
                  //helperText={errors.name}
                />
                <TextField
                  name='productQuantity'
                  onFocus={handleFocus}
                  sx={{ ...ProductFormStyle.textField, flex: 1 }}
                  label={
                    <Typography sx={{ ...ProductFormStyle.textFieldLabel }}>
                      {t('Products.ProductForm.productQuantity')}
                    </Typography>
                  }
                  value={+state.productQuantity || 1}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  //error={!!errors.name}
                  //helperText={errors.name}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
