import { Calculate } from '@mui/icons-material';
import {
  IMultiChartBar,
  ISingleChartBar,
  IYearData,
  IItemGroup,
  IMonthlyBudget,
  IMonthData,
} from 'Interfaces/IChartConfig';

export const typeArray = ['Investment', 'Agreement', 'Other', 'Support'];
export const monthArray = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const ConvertToMultiBarData = (
  yearData: IYearData,
  budgetValue: IMonthlyBudget
): IMultiChartBar => {
  let multiData: IMultiChartBar = {
    name: yearData.name,
    chartPeriod: 4,
    chartType: 0,
    totalValue: calculateTotalValue(yearData).totalValue,
    lastPeriodValue: calculateTotalLastPeriodValue(yearData).totalValue,
    percentage: 0,
    budgetValue: calculateBudget(budgetValue, '').budgetValue,
    itemGroups: CalculateItemGroups(yearData, budgetValue),
  };

  return multiData;
};
const CalculateItemGroups = (
  yearData: IYearData,
  budgetValue: IMonthlyBudget
): ISingleChartBar[] => {
  let itemGroups: ISingleChartBar[] = [] as ISingleChartBar[];
  monthArray.forEach((month) => {
    let singleChartBar: ISingleChartBar = {
      name: month,
      totalValue: calculateMonthValue(yearData, month, true).totalValue,
      percentage: 0,
      budgetValue: calculateBudget(budgetValue, month).monthValue,
      lastPeriodValue: calculateMonthValue(yearData, month, false).totalValue,
      itemGroups: [] as IItemGroup[],
    };

    typeArray.forEach((type) => {
      
      let test = calculateMonthValue(yearData, type, true).totalValue
      console.log('test => test', test, type);
      singleChartBar.itemGroups.push({
        name: type,
        budgetValue: calculateBudget(budgetValue, type).monthValue,
        currentValue: calculateMonthValue(yearData, type, true).totalValue,
        currentPercentage: 0,
        lastPeriodValue: calculateLastPeriod(yearData, type),
        lastPeriodPercentage: 0,
      } as IItemGroup);
     
    });

    singleChartBar.totalValue = singleChartBar.itemGroups.reduce(
      (acc, item) => acc + item.currentValue,
      0
    );
    singleChartBar.lastPeriodValue = singleChartBar.itemGroups.reduce(
      (acc, item) => acc + item.lastPeriodValue,
      0
    );
    singleChartBar.budgetValue = singleChartBar.itemGroups.reduce(
      (acc, item) => acc + item.budgetValue,
      0
    );
    singleChartBar.percentage =
      (singleChartBar.totalValue / singleChartBar.budgetValue) * 100;

    itemGroups.push(singleChartBar);
  });
  return itemGroups;
};

const calculateLastPeriod = (yearData: IYearData, key: string): number => {
  switch (key) {
    case 'jan':
      if (yearData.months[0]) {
        return (
          yearData.months[0].totalPreviousAgreementTurnOver +
          yearData.months[0].totalPreviousInvestmentTurnOver +
          yearData.months[0].totalPreviousSupportTurnOver +
          yearData.months[0].totalPreviousOtherTurnOver
        );
      }
      return 0;
    case 'feb':
      if (yearData.months[1]) {
        return (
          yearData.months[1].totalPreviousAgreementTurnOver +
          yearData.months[1].totalPreviousInvestmentTurnOver +
          yearData.months[1].totalPreviousSupportTurnOver +
          yearData.months[1].totalPreviousOtherTurnOver
        );
      }
      return 0;
    case 'mar':
      if (yearData.months[2]) {
        return (
          yearData.months[2].totalPreviousAgreementTurnOver +
          yearData.months[2].totalPreviousInvestmentTurnOver +
          yearData.months[2].totalPreviousSupportTurnOver +
          yearData.months[2].totalPreviousOtherTurnOver
        );
      }
      return 0;
    case 'apr':
      if (yearData.months[3]) {
        return (
          yearData.months[3].totalPreviousAgreementTurnOver +
          yearData.months[3].totalPreviousInvestmentTurnOver +
          yearData.months[3].totalPreviousSupportTurnOver +
          yearData.months[3].totalPreviousOtherTurnOver
        );
      }
      return 0;
    case 'may':
      if (yearData.months[4]) {
        return (
          yearData.months[4].totalPreviousAgreementTurnOver +
          yearData.months[4].totalPreviousInvestmentTurnOver +
          yearData.months[4].totalPreviousSupportTurnOver +
          yearData.months[4].totalPreviousOtherTurnOver
        );
      }
      return 0;
    case 'jun':
      if (yearData.months[5]) {
        return (
          yearData.months[5].totalPreviousAgreementTurnOver +
          yearData.months[5].totalPreviousInvestmentTurnOver +
          yearData.months[5].totalPreviousSupportTurnOver +
          yearData.months[5].totalPreviousOtherTurnOver
        );
      }
      return 0;
    case 'jul':
      if (yearData.months[6]) {
        return (
          yearData.months[6].totalPreviousAgreementTurnOver +
          yearData.months[6].totalPreviousInvestmentTurnOver +
          yearData.months[6].totalPreviousSupportTurnOver +
          yearData.months[6].totalPreviousOtherTurnOver
        );
      }
      return 0;
    case 'aug':
      if (yearData.months[7]) {
        return (
          yearData.months[7].totalPreviousAgreementTurnOver +
          yearData.months[7].totalPreviousInvestmentTurnOver +
          yearData.months[7].totalPreviousSupportTurnOver +
          yearData.months[7].totalPreviousOtherTurnOver
        );
      }
      return 0;
    case 'sep':
      if (yearData.months[8]) {
        return (
          yearData.months[8].totalPreviousAgreementTurnOver +
          yearData.months[8].totalPreviousInvestmentTurnOver +
          yearData.months[8].totalPreviousSupportTurnOver +
          yearData.months[8].totalPreviousOtherTurnOver
        );
      }
      return 0;
    case 'oct':
      if (yearData.months[9]) {
        return (
          yearData.months[9].totalPreviousAgreementTurnOver +
          yearData.months[9].totalPreviousInvestmentTurnOver +
          yearData.months[9].totalPreviousSupportTurnOver +
          yearData.months[9].totalPreviousOtherTurnOver
        );
      }
      return 0;
    case 'nov':
      if (yearData.months[10]) {
        return (
          yearData.months[10].totalPreviousAgreementTurnOver +
          yearData.months[10].totalPreviousInvestmentTurnOver +
          yearData.months[10].totalPreviousSupportTurnOver +
          yearData.months[10].totalPreviousOtherTurnOver
        );
      }
      return 0;
    case 'dec':
      if (yearData.months[11]) {
        return (
          yearData.months[11].totalPreviousAgreementTurnOver +
          yearData.months[11].totalPreviousInvestmentTurnOver +
          yearData.months[11].totalPreviousSupportTurnOver +
          yearData.months[11].totalPreviousOtherTurnOver
        );
      }
      return 0;
    default:
      return 0;
  }
};

interface BudgetSummary {
  budgetValue: number;
  investment: number;
  agreement: number;
  other: number;
  support: number;
  monthValue: number;
}

const calculateBudget = (
  budgetValue: IMonthlyBudget,
  key: string
): BudgetSummary => {
  let investment =
    budgetValue.janInv +
    budgetValue.febInv +
    budgetValue.marInv +
    budgetValue.aprInv +
    budgetValue.mayInv +
    budgetValue.junInv +
    budgetValue.julInv +
    budgetValue.augInv +
    budgetValue.sepInv +
    budgetValue.octInv +
    budgetValue.novInv +
    budgetValue.decInv;
  let agreement =
    budgetValue.janAgr +
    budgetValue.febAgr +
    budgetValue.marAgr +
    budgetValue.aprAgr +
    budgetValue.mayAgr +
    budgetValue.junAgr +
    budgetValue.julAgr +
    budgetValue.augAgr +
    budgetValue.sepAgr +
    budgetValue.octAgr +
    budgetValue.novAgr +
    budgetValue.decAgr;
  let other =
    budgetValue.janOth +
    budgetValue.febOth +
    budgetValue.marOth +
    budgetValue.aprOth +
    budgetValue.mayOth +
    budgetValue.junOth +
    budgetValue.julOth +
    budgetValue.augOth +
    budgetValue.sepOth +
    budgetValue.octOth +
    budgetValue.novOth +
    budgetValue.decOth;
  let support =
    budgetValue.janSup +
    budgetValue.febSup +
    budgetValue.marSup +
    budgetValue.aprSup +
    budgetValue.maySup +
    budgetValue.junSup +
    budgetValue.julSup +
    budgetValue.augSup +
    budgetValue.sepSup +
    budgetValue.octSup +
    budgetValue.novSup +
    budgetValue.decSup;
  let budget = investment + agreement + other + support;
  let monthValue = 0;

  switch (key.toLowerCase()) {
    case 'jan':
      monthValue =
        budgetValue.janInv +
        budgetValue.janAgr +
        budgetValue.janOth +
        budgetValue.janSup;
      console.log('key => key', key, monthValue);

      break;
    case 'feb':
      monthValue =
        budgetValue.febInv +
        budgetValue.febAgr +
        budgetValue.febOth +
        budgetValue.febSup;
      break;
    case 'mar':
      monthValue =
        budgetValue.marInv +
        budgetValue.marAgr +
        budgetValue.marOth +
        budgetValue.marSup;
      break;
    case 'apr':
      monthValue =
        budgetValue.aprInv +
        budgetValue.aprAgr +
        budgetValue.aprOth +
        budgetValue.aprSup;
      break;
    case 'may':
      monthValue =
        budgetValue.mayInv +
        budgetValue.mayAgr +
        budgetValue.mayOth +
        budgetValue.maySup;
      break;
    case 'jun':
      monthValue =
        budgetValue.junInv +
        budgetValue.junAgr +
        budgetValue.junOth +
        budgetValue.junSup;
      break;
    case 'jul':
      monthValue =
        budgetValue.julInv +
        budgetValue.julAgr +
        budgetValue.julOth +
        budgetValue.julSup;
      break;
    case 'aug':
      monthValue =
        budgetValue.augInv +
        budgetValue.augAgr +
        budgetValue.augOth +
        budgetValue.augSup;
      break;
    case 'sep':
      monthValue =
        budgetValue.sepInv +
        budgetValue.sepAgr +
        budgetValue.sepOth +
        budgetValue.sepSup;
      break;
    case 'oct':
      monthValue =
        budgetValue.octInv +
        budgetValue.octAgr +
        budgetValue.octOth +
        budgetValue.octSup;
      break;
    case 'nov':
      monthValue =
        budgetValue.novInv +
        budgetValue.novAgr +
        budgetValue.novOth +
        budgetValue.novSup;
      break;
    case 'dec':
      monthValue =
        budgetValue.decInv +
        budgetValue.decAgr +
        budgetValue.decOth +
        budgetValue.decSup;
      break;
    default:
      monthValue = 0;
  }
  console.log('key => key', key, monthValue);

  return {
    budgetValue: budget,
    investment: investment,
    agreement: agreement,
    other: other,
    support: support,
    monthValue: monthValue,
  };
};

interface TotalSummary {
  totalValue: number;
  investment: number;
  agreement: number;
  other: number;
  support: number;
}
const calculateMonthValue = (
  yearData: IYearData,
  key: string,
  isCurrent: boolean
): TotalSummary => {
  let investment = 1110;

  let month = monthArray.indexOf(key);
  if (yearData.months[month]) {
    let monthData = calculateMonthTotalValue(yearData.months[month], isCurrent);
    console.log('month => month', key, isCurrent, monthData);

    return monthData;
  }
  return {
    totalValue: 0,
    investment: investment,
    agreement: 0,
    other: 0,
    support: 0,
  };
};

const calculateMonthTotalValue = (
  monthData: IMonthData,
  isCurrent: boolean
): TotalSummary => {
  let totalValue =
    monthData.totalAgreementTurnOver +
    monthData.totalInvestmentTurnOver +
    monthData.totalSupportTurnOver +
    monthData.totalOtherTurnOver;
  let investment = monthData.totalInvestmentTurnOver;
  let agreement = monthData.totalAgreementTurnOver;
  let other = monthData.totalOtherTurnOver;
  let support = monthData.totalSupportTurnOver;
  if (!isCurrent) {
    totalValue =
      monthData.totalPreviousAgreementTurnOver +
      monthData.totalPreviousInvestmentTurnOver +
      monthData.totalPreviousSupportTurnOver +
      monthData.totalPreviousOtherTurnOver;
    investment = monthData.totalPreviousInvestmentTurnOver;
    agreement = monthData.totalPreviousAgreementTurnOver;
    other = monthData.totalPreviousOtherTurnOver;
    support = monthData.totalPreviousSupportTurnOver;
  }
  return {
    totalValue: totalValue,
    investment: investment,
    agreement: agreement,
    other: other,
    support: support,
  };
};
const calculateTotalValue = (yearData: IYearData): TotalSummary => {
  let totalValue =
    yearData.totalAgreementTurnOver +
    yearData.totalInvestmentTurnOver +
    yearData.totalSupportTurnOver +
    yearData.totalOtherTurnOver;
  let investment = yearData.totalInvestmentTurnOver;
  let agreement = yearData.totalAgreementTurnOver;
  let other = yearData.totalOtherTurnOver;
  let support = yearData.totalSupportTurnOver;
  return {
    totalValue: totalValue,
    investment: investment,
    agreement: agreement,
    other: other,
    support: support,
  };
};
const calculateTotalLastPeriodValue = (yearData: IYearData): TotalSummary => {
  let totalValue =
    yearData.totalPreviousAgreementTurnOver +
    yearData.totalPreviousInvestmentTurnOver +
    yearData.totalPreviousSupportTurnOver +
    yearData.totalPreviousOtherTurnOver;
  let investment = yearData.totalPreviousInvestmentTurnOver;
  let agreement = yearData.totalPreviousAgreementTurnOver;
  let other = yearData.totalPreviousOtherTurnOver;
  let support = yearData.totalPreviousSupportTurnOver;
  return {
    totalValue: totalValue,
    investment: investment,
    agreement: agreement,
    other: other,
    support: support,
  };
};
