import { IWorkRoleViewModel } from 'data/atoms/atomConfig';

export const GetWorkRole = (
  companyWorkRole: IWorkRoleViewModel | null,
  userWorkRole: IWorkRoleViewModel | null,
  stateWorkRole: IWorkRoleViewModel | null
) => {

  console.log('GetWorkRole c', companyWorkRole);
  console.log('GetWorkRole u', userWorkRole);
  console.log('GetWorkRole s', stateWorkRole);

  // Helper function to check if the object is not null and not an empty object
  const isValidWorkRole = (workRole: IWorkRoleViewModel | null) => {
    return workRole && Object.keys(workRole).length > 0;
  };
  
  // sjekke om firma har en workrole
  if (isValidWorkRole(companyWorkRole)) {
    return companyWorkRole;
  }
  // om det allerede er en i sate
  if (isValidWorkRole(stateWorkRole)) {
    return stateWorkRole;
  }
  
  //returnere user workrole
  return userWorkRole; 
};
