import { ICompanyAddViewModel } from 'Interfaces/ICompaniesConfig';
import { EnumCompanyType, EnumPriority } from 'Interfaces/IGlobalTypeConfig';

export const ToCompanies = (data: any): ICompanyAddViewModel[] => {
  if (!data.results) return [];
  console.log('NEW : companies: før', data);
  let newList: ICompanyAddViewModel[] = [];
  data.results.forEach((company: any) => {
    let newListItem: ICompanyAddViewModel = {
      organizationNumber: '',
      name: company.name,
      mainAddress: {
        street: company.address.streetName,
        postalCode: company.address.postalCode,
        area: 'Bergen',
        city: company.address.city,
        country: 'Norway',
        postBox: '',
      },
      deliveryAddress: null,
      invoiceAddress: null,
      phone: company.phone,
      email: company.email,
      supportEmail: '',
      companyType: EnumCompanyType.CLIENT,
      webPage: '',
      fileModel: null,
      productBilling: false,
      onHold: false,
      isSupport: false,
      onHoldNote: '',
      priority: EnumPriority.NORMAL,
      fixedHourlyRate: 0,
      workRoleId: null,
      contactId: null,
      companyProducts: [],
      productCatalog: [],
      storageProducts: [],
      unassignedCompanyProducts: [],
      legacyId: company.legacyId,
      thirdPartyId: company.id,
      thirdParty: company.CloudFactory,
    };
    newList.push(newListItem);
  });
  console.log('NEW : companies: etter', newList);
  return newList;
};
