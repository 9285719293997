import { Button, Grid, IconButton, List, Paper } from '@mui/material';
// @ts-ignore
import { EmployeeDrawer } from 'pages/Companies/EmployeeDrawer';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { memo, useEffect, useReducer, useState } from 'react';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import useWindowsDimension from 'functions/useWindowsDimension';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { usePost, usePut } from 'http/useInnovit';
import { rqPost } from 'http/apiRoutes';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';
import ownerAtom from 'data/atoms/ownerAtom';
import { IUserViewModel } from 'data/atoms/atomConfig';
import { SearchFilter } from '../SearchFilter';
import { SingleEmployeeView } from '../SingleEmployeeView';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { useTranslation } from 'react-i18next';
import {
  ISimpleTechnicianViewModel,
  InitialAccountState,
  ResponsibleViewModel,
} from './AccountConfig';

// ACTIONS
type Action =
  | { type: 'ADD_RESPONSIBLE_FOR'; payload: ISimpleTechnicianViewModel }
  | { type: 'REMOVE_RESPONSIBLE_FOR'; payload: ISimpleTechnicianViewModel }
  | { type: 'REMOVE_ALL_RESPONSIBLE'; payload: null };

// REDUCER
const reducer = (
  state: InitialAccountState,
  action: Action
): InitialAccountState => {
  switch (action.type) {
    case 'ADD_RESPONSIBLE_FOR':
      return {
        ...state,
        technicians: [...state.technicians, action.payload],
        technicianIds: [...state.technicianIds, action.payload.id as string],
      };
    case 'REMOVE_RESPONSIBLE_FOR':
      return {
        ...state,
        technicians: state.technicians.filter(
          (technician) => technician.id !== action.payload.id
        ),
        technicianIds: state.technicianIds.filter(
          (technicianId) => technicianId !== action.payload.id
        ),
      };
    case 'REMOVE_ALL_RESPONSIBLE':
      return {
        ...state,
        technicians: [],
        technicianIds: [],
      };

    default:
      return state;
  }
};

const initialState: InitialAccountState = {
  employeeId: '',
  technicianIds: [] as string[],
  technicians: [] as ISimpleTechnicianViewModel[],
};

export const ResponsibleList = () => {
  // TRANSLATIONS
  const { t } = useTranslation();
  // INITIALIZATIONS
  const [user, setUser] = useAtom(userAtom);
  const [owner, setOwner] = useAtom(ownerAtom);
  const { height } = useWindowsDimension();
  // STATE
  const [state, dispatch] = useReducer(reducer, initialState);
  let isEnable = user?.id && owner?.id ? true : false;
  let _ownerId = owner?.id ? owner.id : '';
  let _userId = user?.id ? user.id : '';
  const putUser = usePost(rqPost.responsibleFor(isEnable), isEnable);
  console.log('user', user);
  const ConvertToSimpleEmployee = (technician: IUserViewModel) => {
    let _technician = {} as ISimpleTechnicianViewModel;
    _technician.id = technician.id;
    _technician.name = technician.name;
    _technician.email = technician.email;
    _technician.phone = technician.phone;
    _technician.title = technician.title;
    _technician.role = technician.role;
    _technician.primaryContact = technician.primaryContact;
    _technician.globalAdministrator = technician.globalAdministrator;
    _technician.url = technician.url ?? '';
    return _technician;
  };

  // HANDLERS
  //console.log("state",state);
  const handleRemoveResponsibleSubmit = (
    e: any,
    technician: IUserViewModel
  ) => {
    e.preventDefault();
    let technicianData = ConvertToSimpleEmployee(technician as IUserViewModel);
    dispatch({ type: 'REMOVE_RESPONSIBLE_FOR', payload: technicianData });
  };

  const handleResponsibleSubmit = (e: any, technician: IUserViewModel) => {
    e.preventDefault();
    let technicianData = ConvertToSimpleEmployee(technician as any);
    dispatch({ type: 'ADD_RESPONSIBLE_FOR', payload: technicianData });
  };

  const ConvertResponsibleFor = (state: any) => {
    console.log('updateData state', state);

    const _responsibleForViewModel = {} as ResponsibleViewModel;
    _responsibleForViewModel.employeeId = _userId;
    _responsibleForViewModel.technicianIds = state.technicianIds;
    return _responsibleForViewModel;
  };
  useEffect(() => {
    if (user) {
      dispatch({ type: 'REMOVE_ALL_RESPONSIBLE', payload: null });
      if (user.responsibleFor) {
        user.responsibleFor.forEach((item: any) => {
          let technicianData = ConvertToSimpleEmployee(item.technician as any);
          dispatch({ type: 'ADD_RESPONSIBLE_FOR', payload: technicianData });
        });
      }
    }
  }, [user]);

  const handleUpdate = () => {
    if (!user) return;
    // convert to updateResonsilbeViewModel
    let updateData = ConvertResponsibleFor(state);
    console.log('updateData', updateData);
    putUser.mutate(updateData);
    setUser(user);
  };

  // MODULES

  return (
    <Grid container direction={'row'}>
      <Grid item xs={12}>
        <Grid sx={{}}>
          <SearchFilter
            Icon={PersonOutlineRoundedIcon}
            Header={t('myPage.workRole')}
            gfx={
              owner.id && (
                <EmployeeDrawer
                  buttonIcon={<ChangeCircleIcon sx={{ fontSize: 40 }} />}
                  companyId={owner.id}
                  handleEmployeeSubmit={handleResponsibleSubmit}
                />
              )
            }
          />

          <List
            sx={{
              height: Math.ceil(height - 834),
              overflow: 'auto',
              backgroundColor: 'primary.back',
              p: 2,
            }}
          >
            {state?.technicians.length > 0 &&
              state.technicians?.map((responsible: any, index: number) => (
                <SingleEmployeeView
                  key={index}
                  employee={responsible}
                  action={PersonRemoveRoundedIcon}
                  gfx={
                    <IconButton
                      sx={{ fontSize: 26, p: 0, m: 0 }}
                      onClick={(e) =>
                        handleRemoveResponsibleSubmit(e, responsible)
                      }
                    >
                      <DeleteForeverRoundedIcon />
                    </IconButton>
                  }
                />
              ))}
          </List>
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Grid container direction={'row'}>
              <Grid flexGrow={1}></Grid>
              <Button
                size='small'
                variant='contained'
                onClick={() => handleUpdate()}
              >
                {t('button.update')}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
