import { IEmployeeViewModel } from 'Interfaces/ITicketConfig';
import { IUserViewModel } from 'data/atoms/atomConfig';

export const ConvertToEmployee = (user: IUserViewModel) => {
  let employee: IEmployeeViewModel = {
    id: user?.id ?? null,
    name: user.name,
    department: '',
    phone: user.phone,
    email: user.email,
    budget: user.budget,
    title: user.title,
    workRole: user.workRole,
    url: user.url || '',
  };
  return employee;
};
